import React from 'react';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';

import ContentPopup from './common/ContentPopup';
import StripeCardForm from './common/StripeCardForm';

import Storage from '../helpers/Storage';

import '../sass/components/ManageCardView.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY || '');
const FONT_LINK = 'https://fonts.googleapis.com/css?family=Montserrat:400,500&display=swap';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    businessProfile: store.data.businessProfile,
    webpSupported: store.setup.webpSupported,
  }
};

class ManageCardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addedCard: null,
      cardComplete: false,
      showCardForm: false,
      webpSupported: true,
    }
    this.getToken = null;
  }

  componentDidMount = () => {
    if (this.props.card !== undefined) {
      this.setState({addedCard: this.props.card || {}});
    } else if (this.props.user) {
      this.requestBankCards();
    }
    if (typeof window !== 'undefined') {
      this.getSetting();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.businessProfile !== this.props.businessProfile) {
      this.requestBankCards();
    }
  }

  getSetting = () => {
    this.setState({ webpSupported: Storage.getSetup('webpSupported') });
  }

  formatAmount = (number, currency) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(number);
  }

  requestBankCards = () => {
    sendRequest({
      type: 'GET',
      method: 'bank_cards',
      data: {
        business_profile_id: this.props.businessProfile?.id,
      },
      success: (data) => {
        this.setState({addedCard: data || {}});
      },
      error: (data) => {
      }
    });
  }

  onAddPress = () => {
    if (!this.getToken) {
      return;
    }
    this.getToken(token => {
      if (!token || !token.id) {
        return;
      }
      if (this.props.onCardChange) {
        this.props.onCardChange(token.id);
      } else {
        sendRequest({
          type: 'POST',
          method: 'bank_cards',
          data: {
            business_profile_id: this.props.businessProfile?.id,
            card_token: token.id,
          },
          success: (data) => {
            if (this.props.businessProfile) {
              Storage.setData('businessProfile', {
                ...Storage.getData('businessProfile'),
                has_card: true,
              });
            } else {
              Storage.setData('user', {
                ...Storage.getData('user'),
                has_card: true,
              });
            }
            if (this.props.embedded) {
              this.props.onPay();
            } else {
              this.setState({
                addedCard: data,
                showCardForm: false,
              });
            }
          },
          error: (data) => {
            if (data?.errors?.card_token) {
              triggerEvent('showSnackbar', [{text: data.errors.card_token, type: 'error'}]);
            }
          }
        });
      }
    });
  }

  renderAddForm = () => {
    const card = this.state.addedCard;
    let buttonText = card.id ? 'Update' : 'Add';
    let buttonDisabled = !this.state.cardComplete;
    const price = this.props.price && this.formatAmount(this.props.price.unit_amount / 100, this.props.price.currency);
    if (this.props.embedded && this.props.onPay) {
      buttonText = `Pay ${price}`;
    }
    if (this.props.buttonText) {
      buttonText = this.props.buttonText;
    }
    return (
      <>
        <Elements
          stripe={stripePromise}
          options={{fonts: [{cssSrc: FONT_LINK}]}}
        >
          <StripeCardForm
            onReady={(getToken) => {
              this.getToken = getToken;
            }}
            onChange={(changeObject) => {
              this.setState({cardComplete: changeObject.complete});
            }}
            embedded={this.props.embedded}
          />
        </Elements>
        <button
          disabled={buttonDisabled}
          onClick={() => {
            if(this.state.cardComplete) {
              this.onAddPress();
            }
          }}
        >{buttonText}</button>
        {this.props.embedded
          ? <div className={classnames('creds', {'webp': this.state.webpSupported})} />
          : null}
      </>
    )
  }

  onCardAdd = () => {
    this.setState({showCardForm: true});
  }

  onCardRemove = () => {
    triggerEvent('showConfirmation', [{
      title: 'Are you sure you would like to delete this Payment Method?',
      confirmText: 'Delete',
      callback: confirm => {
        if (confirm) {
          sendRequest({
            type: 'DELETE',
            method: 'bank_cards',
            data: {
              business_profile_id: this.props.businessProfile?.id,
            },
            success: (data) => {
              if (this.props.businessProfile) {
                Storage.setData('businessProfile', {
                  ...Storage.getData('businessProfile'),
                  has_card: false,
                });
              } else {
                Storage.setData('user', {
                  ...Storage.getData('user'),
                  has_card: false,
                });
              }
              this.setState({addedCard: {}});
            },
            error: (data) => {
            }
          });
        }
      }
    }]);
  }

  renderEmbedded = () => {
    const card = this.props.card || this.state.addedCard;
    const price = this.props.price && this.formatAmount(this.props.price.unit_amount / 100, this.props.price.currency);
    return (
      <div className='manageCardView embedded'>
        {this.props.card || card.id
          ? <div className='cardContainer'>
              <div className={`cardInfo ${card.brand} selected`}>
                **** **** **** {card.last4}&nbsp;&nbsp;&nbsp;{card.expiry}
              </div>
              <div className='cardButtons'>
                <div
                  className='cardButton'
                  onClick={() => {
                    if (this.props.onCardChange) {
                      this.props.onCardChange(null);
                    } else {
                      this.setState({addedCard: {}});
                    }
                  }}
                >Update</div>
              </div>
              {this.props.onPay ?
                <button
                  onClick={this.props.onPay}
                >Pay {price}</button>
              : null}
            </div>
          : this.renderAddForm()
        }
      </div>
    )
  }

  render = () => {
    const card = this.state.addedCard;
    if (!card) {
      return null;
    }
    if (this.props.embedded) {
      return this.renderEmbedded();
    }
    return (
      <div className='manageCardView'>
        {card.id
          ? <div className='cardContainer'>
              <div className={`cardInfo ${card.brand} selected`}>
                **** **** **** {card.last4}&nbsp;&nbsp;&nbsp;{card.expiry}
              </div>
              <div className='cardButtons'>
                <div
                  className='cardButton faint'
                  onClick={this.onCardRemove}
                >Delete</div>
                <div
                  className='cardButton'
                  onClick={this.onCardAdd}
                >Update</div>
              </div>
            </div>
          : <div className='cardContainer'>
              <div className='cardInfo'>
                <div className='cardLabel'>No card added</div>
              </div>
              <div className='cardButtons'>
                <div
                  className='cardButton'
                  onClick={this.onCardAdd}
                >Add Card</div>
              </div>
            </div>
        }
        <ContentPopup
          show={this.state.showCardForm}
          title={`${this.state.addedCard.id ? 'Update' : 'Add'} Card`}
          content={this.renderAddForm()}
          callback={() => this.setState({showCardForm: false})}
        />
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(ManageCardView));
