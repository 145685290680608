import { FieldArray, Form, Formik, Field } from 'formik';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { Select } from 'semantic-ui-react';

import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';
import '../sass/components/EditExpertDetailsView.scss';
import AddButton from './buttons/AddButton';
import ExpertWorkExperienceInputSection from './input/ExpertWorkExperienceInputSection';
import ExpertBusinessExperienceInputSection from './input/ExpertBusinessExperienceInputSection';
///
const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

const EditExpertDetailsView = ({ wizard, onSave }) => {
  const [initialValues, setIntialValues] = useState({});
  const fetchUserExperience = () => {
    sendRequest({
      method: `user/experience`,
      node: true,
      type: 'GET',
      success: (data) => {
        if (data) {
          const { workExperience, businessExperience, topicTags } = data;
          let tagValues = [];
          let tagOptions = [];
          if (topicTags) {
            tagValues = topicTags.tags.split(',');
            tagOptions = tagValues.map((value) => ({
              key: value,
              value,
              text: value,
            }));
          }
          setIntialValues({
            workHistory:
              workExperience.length > 0
                ? workExperience.map((obj) => ({
                    ...obj,
                    present: !obj.end_date,
                  }))
                : [
                    {
                      role: '',
                      company: '',
                      start_date: '',
                      end_date: '',
                      present: false,
                      details: '',
                    },
                  ],
            businessExperience:
              businessExperience.length > 0
                ? businessExperience
                : [
                    {
                      business_expertise: '',
                      company: '',
                      countries_regions: '',
                      details: '',
                    },
                  ],
            topicTagsOptions: tagOptions,
            topicTags: tagValues,
          });
        }
      },
      error: (data) => {
        if (data.errors && data.errors.name) {
          triggerEvent('showSnackbar', [
            { text: data.errors.name, type: 'error' },
          ]);
        } else if (data.errors && data.errors.description) {
          triggerEvent('showSnackbar', [
            { text: data.errors.description, type: 'error' },
          ]);
        } else if (data.errors) {
          triggerEvent('showSnackbar', [{ text: data.errors, type: 'error' }]);
        }
      },
    });
  };
  ///
  useEffect(() => {
    fetchUserExperience();
  }, []);
  ///
  const WorkExperienceValidationSchema = yup.object().shape({
    role: yup.string().required('Role is required'),
    company: yup.string().required('Company is required'),
    present: yup.boolean(),
    start_date: yup.string().required('Start date is required'),
    end_date: yup
      .string()
      .nullable()
      .when('present', {
        is: false,
        then: yup.string().nullable().required('End date is required'),
      }),
    // Rest of your amenities object properties
  });
  const BusinessExperienceValidationSchema = yup.object().shape({
    business_expertise: yup.string().required('Business Expertise is required'),
    company: yup.string().required('Companies or Organizations is required'),
    countries_regions: yup
      .string()
      .required('Countries or Regions is required'),
    // end_date: yup.string().required('End date is required'),
    // Rest of your amenities object properties
  });
  const ValidationSchema = yup.object().shape({
    workHistory: yup.array().of(WorkExperienceValidationSchema),
    businessExperience: yup.array().of(BusinessExperienceValidationSchema),
  });

  const handleSubmit = async (values) => {
    sendRequest({
      method: 'user/add-experience',
      node: true,
      type: 'POST',
      data: {
        workExperience: [...values.workHistory].map((obj) => ({
          ...obj,
          end_date: obj.present ? '' : obj.end_date,
        })),
        businessExperience: values.businessExperience,
        topicTags: {
          tags: values.topicTags.join(','),
        },
      },
      success: (data) => {
        if (data) {
          onSave();
        }
      },

      error: (data) => {
        if (data.errors && data.errors.name) {
          triggerEvent('showSnackbar', [
            { text: data.errors.name, type: 'error' },
          ]);
        } else if (data.errors && data.errors.description) {
          triggerEvent('showSnackbar', [
            { text: data.errors.description, type: 'error' },
          ]);
        } else if (data.errors) {
          triggerEvent('showSnackbar', [{ text: data.errors, type: 'error' }]);
        }
      },
    });
  };

  /// Handle the delete call
  const handleDelete = (value) => {
    if (!value.id) {
      return;
    }
    sendRequest({
      method: `user/experience/${value.id}`,
      node: true,
      type: 'DELETE',
      success: (data) => {
        if (data) {
          fetchUserExperience();
        }
      },
      error: (data) => {
        triggerEvent('showSnackbar', [
          { text: 'Could not delete experience', type: 'error' },
        ]);
      },
    });
  };

  ///
  return (
    <Formik
      enableReinitialize
      initialValues={{
        workHistory: initialValues?.workHistory ?? [
          {
            role: '',
            company: '',
            start_date: '',
            end_date: '',
            present: false,
            details: '',
          },
        ],
        businessExperience: initialValues?.businessExperience ?? [
          {
            business_expertise: '',
            company: '',
            countries_regions: '',
            details: '',
          },
        ],
        topicTagsOptions: initialValues?.topicTagsOptions ?? [],
        topicTags: initialValues?.topicTags ?? [],
      }}
      validateOnChange={false}
      validationSchema={ValidationSchema}
      onSubmit={(e, b) => {
        handleSubmit(e);
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form id="form">
          <label className="input-label">Work History</label>
          <FieldArray
            name={'workHistory'}
            render={({ push, remove }) =>
              values.workHistory.map((e, i) => (
                <div className="w-full" key={i}>
                  <ExpertWorkExperienceInputSection
                    index={i}
                    id={`workHistory[${i}]`}
                    name={`workHistory[${i}]`}
                    value={values.workHistory[i]}
                    onDelete={() => {
                      values.workHistory.length !== 1 ? remove(i) : null;
                      handleDelete(values.workHistory[i]);
                    }}
                  />
                  {i === values.workHistory.length - 1 && (
                    <AddButton
                      onClick={() => {
                        push({
                          role: '',
                          company: '',
                          start_date: '',
                          end_date: '',
                          present: false,
                          details: '',
                        });
                      }}
                    />
                  )}
                </div>
              ))
            }
          />

          <br />
          <label className="input-label">Business Experience</label>
          <FieldArray
            name={'businessExperience'}
            render={({ push, remove }) => {
              return values.businessExperience.map((e, i) => (
                <div className="w-full" key={i}>
                  <ExpertBusinessExperienceInputSection
                    name={`businessExperience[${i}]`}
                    id={`businessExperience[${i}]`}
                    value={values.businessExperience[i]}
                    index={i}
                    onDelete={() => {
                      values.businessExperience.length !== 1 ? remove(i) : null;
                      handleDelete(values.businessExperience[i]);
                    }}
                  />

                  {i === values.businessExperience.length - 1 && (
                    <AddButton
                      onClick={() => {
                        push({
                          business_expertise: '',
                          company: '',
                          countries_regions: '',
                          details: '',
                        });
                      }}
                    />
                  )}
                </div>
              ));
            }}
          />
          <br />
          <label className="input-label">Topic Tags</label>
          <Field
            name="topicTags"
            as={Select}
            search
            selection
            fluid
            multiple
            allowAdditions
            value={values.topicTags}
            options={values.topicTagsOptions}
            onAddItem={(e, { value }) => {
              setFieldValue('topicTagsOptions', [
                ...values.topicTagsOptions,
                {
                  key: value,
                  value,
                  text: value,
                },
              ]);
            }}
            onChange={(e, { value }) => {
              setFieldValue('topicTags', value);
            }}
          />
          <div className="submit-section">
            <div className="button-block">
              <button
                type="submit"
                className="continue-btn"
                // disabled={isSubmitting}
              >
                {wizard ? 'Continue' : 'Save'}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default connect(mapStoreToProps)(withRouter(EditExpertDetailsView));
