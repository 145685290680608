import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import '../sass/components/FooterView.scss';
import { ReactComponent as LogoGraySVG } from '../images/logo_gray.svg';
import { ReactComponent as TwitterSVG } from '../images/icons/twitter.svg';
import { ReactComponent as FacebookSVG } from '../images/icons/facebook.svg';
import { ReactComponent as InstagramSVG } from '../images/icons/instagram.svg';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

const FooterView = ({ user }) => {
  return (
    <>
      <div className="section-footer-start">
        <div className="section-content">
          <div className="left-content">
            <div className="content-header">It starts right here</div>
            <p>
              Get started by creating your organisation, individual or investor
              profile.
            </p>
            <Link to={user ? '/profile' : '/register'} className="button">
              Join the Ecosystem
            </Link>
          </div>
        </div>
      </div>
      <div className="section-footer">
        <div className="section-content">
          <div className="left-content">
            <Link
              onClick={(event) => (window.location.href = '/')}
              className="footer-link footer-logo"
            >
              <LogoGraySVG />
            </Link>
            <a
              href="https://twitter.com/ecobusinesscom"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link social-link"
            >
              <TwitterSVG />
            </a>
            <a
              href="https://www.facebook.com/EcoBusinessMedia"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link social-link"
            >
              <FacebookSVG />
            </a>
            <a
              href="https://instagram.com/ecobusinesscom"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link social-link"
            >
              <InstagramSVG />
            </a>
          </div>

          <div className="center-content">
            &#169; Eco-Business 2009 - {new Date().getFullYear()}. All rights
            reserved.
          </div>

          <div className="right-content">
            <a
              href="https://faq.thesdg.co/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              FAQ
            </a>
            <Link to="/market-maps" className="footer-link">
              Market Maps
            </Link>
            <Link to="/privacy" className="footer-link">
              Privacy Policy
            </Link>
            <Link to="/terms" className="footer-link">
              Terms of Use
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStoreToProps)(withRouter(FooterView));
