import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import dayjs from 'dayjs';

import ContentPlaceholder from '../common/ContentPlaceholder';
import ContentContainer from '../common/ContentContainer';
import Icon, { IconSet } from '../common/Icon';
import Avatar from '../common/Avatar';
import PostPreview from '../common/PostPreview';
import Comment from '../common/Comment';
import Textarea from '../input/Textarea';

import { triggerEvent } from '../../helpers/global.js';
import { sendRequest } from '../../helpers/RequestDispatcher.js';

import '../../sass/components/feed/Post.scss';

const DEFAULT_COMMENTS_TO_SHOW = 2;
const COMMENTS_PER_LOAD = 10;

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

class Post extends React.Component {
  static defaultProps = {
    isLiked: false,
  };

  state = {
    isLiked: false,
    comment: '',
    file: null,
  };

  commentFormRef = null;

  constructor(props) {
    super(props);

    this.state = {
      isLiked: props.isLiked,
      loadedComments: [],
      visibleComments: [],
      file: null,
      loadNumber: 0,
      noMoreComments: false,
      likeCount: props.likeCount,
      commentsCount: props.post?.comments_count || 0,
      post: props.post,
      loaded: false,
      showActionsNav: false,
    };
    this.fileInput = null;
  }

  componentDidMount = () => {
    sendRequest({
      type: 'GET',
      method: 'comments',
      data: {
        post_id: this.state.post.id,
        limit: COMMENTS_PER_LOAD + DEFAULT_COMMENTS_TO_SHOW,
      },
      success: (data) => {
        if (data) {
          const visibleComments = data.slice(0, DEFAULT_COMMENTS_TO_SHOW);
          const loadedComments = data.slice(DEFAULT_COMMENTS_TO_SHOW);
          this.setState({
            loadedComments: loadedComments,
            visibleComments: visibleComments,
            loaded: true,
            noMoreComments: data.length < DEFAULT_COMMENTS_TO_SHOW,
          });
        }
      },
      error: (data) => {},
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.likeCount !== this.props.likeCount) {
      this.setState({ likeCount: this.props.likeCount });
    }
    if (prevProps.post !== this.props.post) {
      this.setState({ post: this.props.post });
    }
  };

  replaceURLs = (text) => {
    if (!text) return;

    const urlRegex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    return text.replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
    });
  };

  handleLike = () => {
    if (!this.props.user) {
      triggerEvent('showSnackbar', [
        { text: 'Please, sign up or log in first.' },
      ]);
      return;
    }

    const { isLiked } = this.state;
    const type = isLiked ? 'DELETE' : 'POST';
    const likeModifier = isLiked ? -1 : 1;
    sendRequest({
      type: type,
      method: `posts/${this.state.post.id}/like`,
      success: (data) => {
        if (data) {
          this.setState((prevState) => ({
            isLiked: !isLiked,
            likeCount: prevState.likeCount + likeModifier,
          }));
        }
      },
      error: (data) => {},
    });
  };

  handleFocusComment = () => {
    if (!this.props.user) {
      triggerEvent('showSnackbar', [
        { text: 'Please, sign up or log in first.' },
      ]);
      return;
    }
    if (this.commentFormRef) {
      this.commentFormRef.focus();
    }
  };

  handleSubmitComment = () => {
    const { businessProfile } = this.props;
    const { comment, file } = this.state;
    if (!this.props.user) {
      triggerEvent('showSnackbar', [
        { text: 'Please, sign up or log in first.' },
      ]);
      return;
    }

    if (!comment && !file) {
      triggerEvent('showSnackbar', [
        { text: 'Please enter Comment or upload Image', type: 'error' },
      ]);
      return;
    }
    let formData = new FormData();
    if (comment) {
      formData.append('content', comment);
    } else {
      formData.append('image', file);
    }
    if (this.props.businessProfile) {
      formData.append('business_profile_id', businessProfile.id);
    }
    formData.append('post_id', this.state.post.id);
    sendRequest({
      type: 'POST',
      method: 'comments',
      formData,
      success: (data) => {
        this.setState((prevState) => ({
          loadedComments: [...prevState.loadedComments, data],
          visibleComments: [...prevState.visibleComments, data],
          commentsCount: prevState.commentsCount + 1,
          comment: '',
          file: null,
        }));
      },
      error: (data) => {
        if (data.errors && data.errors.image) {
          triggerEvent('showSnackbar', [
            { text: data.errors.image, type: 'error' },
          ]);
        }
      },
    });
  };

  handleShowMoreComments = () => {
    const { loadNumber, loadedComments, visibleComments } = this.state;
    const defaultOffset = COMMENTS_PER_LOAD + DEFAULT_COMMENTS_TO_SHOW;
    const offset =
      loadNumber === 0
        ? defaultOffset
        : loadNumber * COMMENTS_PER_LOAD + defaultOffset;

    sendRequest({
      type: 'GET',
      method: 'comments',
      data: {
        post_id: this.state.post.id,
        offset: offset,
        limit: COMMENTS_PER_LOAD,
      },
      success: (data) => {
        const newVisibleComments = visibleComments.concat(
          loadedComments.concat(data).slice(0, COMMENTS_PER_LOAD)
        );
        const newLoadedComments = loadedComments
          .concat(data)
          .slice(COMMENTS_PER_LOAD);
        this.setState((prevState) => ({
          loadedComments: newLoadedComments,
          visibleComments: newVisibleComments,
          loadNumber: prevState.loadNumber + 1,
          noMoreComments: data.length === 0,
        }));
      },
      error: (data) => {},
    });
  };

  handleCommentChange = (e) => {
    const value = e.target.value;
    this.setState({ comment: value });
  };

  attachFile = () => {
    if (this.state.file) {
      this.setState({ file: null });
      this.fileInput.value = '';
    }
    this.fileInput.click();
  };

  handlePostAuthorClick = (identifier) => {
    if (this.props.author.account_type === 'business_profile') {
      this.props.history.push(`/business/${identifier}`);
    } else {
      this.props.history.push(`/user/${identifier}`);
    }
  };

  handleActionsClick = () => {
    // check if current profile is the author
    if (this.props.businessProfile) {
      if (this.props.author.account_type !== 'business_profile') return;
      if (this.props.businessProfile?.id !== this.props.author.id) return;
    } else {
      if (this.props.user.id !== this.props.author.id) return;
    }
    this.setState((prevState) => ({
      showActionsNav: !prevState.showActionsNav,
    }));
  };

  handleEditClick = () => {
    if (this.props.onEditClick) {
      this.setState({ showActionsNav: false });
      this.props.onEditClick();
    }
  };

  handleDeleteClick = () => {
    if (this.props.onDeleteClick) {
      this.setState({ showActionsNav: false });
      this.props.onDeleteClick();
    }
  };

  renderAuthor = () => {
    const {
      author: { id, slug, name, image_url, subscribed },
      publishDateTime,
    } = this.props;

    return (
      <div className="postAuthor">
        <Avatar
          imageUrl={image_url}
          username={name}
          className="postAvatar"
          small
          onClick={() => this.handlePostAuthorClick(slug || id)}
        />
        <div className="postInfo">
          <div
            className="postAuthorName"
            onClick={() => this.handlePostAuthorClick(slug || id)}
          >
            {name}
            {/* {subscribed ? <div className="subscribed" /> : null} */}
          </div>
          <div className="postPublishTime">
            {dayjs.unix(publishDateTime).fromNow()}
          </div>
        </div>
      </div>
    );
  };

  renderPost = () => {
    const { article } = this.props;

    const { link, image_url, title, content } = this.state.post;

    return (
      <>
        <article
          className="postContent"
          dangerouslySetInnerHTML={{ __html: this.replaceURLs(article) }}
        />
        <PostPreview
          className="postPreview"
          url={link}
          cover={image_url}
          title={title}
          description={content}
        />
      </>
    );
  };

  renderDetails = () => {
    const { likeCount } = this.state;

    return (
      <div className="postStats">
        <span>
          {likeCount} Like{likeCount > 1 ? 's' : ''}
        </span>
        <span>
          {this.state.commentsCount}
          <Icon icon={IconSet.CommentLarge} className="postStatsCommentIcon" />
        </span>
      </div>
    );
  };

  renderActionButton = ({ icon, label, onClick, className }) => (
    <span className={classnames('actionButton', className)} onClick={onClick}>
      <Icon icon={icon} className="actionButtonIcon" />

      {label}
    </span>
  );

  renderActions = () => {
    const { isLiked } = this.state;

    return (
      <div className="postActions">
        {this.renderActionButton({
          icon: IconSet.Like,
          label: 'Like',
          className: classnames(
            'postActionButton',
            isLiked && 'postActionButtonActive'
          ),
          onClick: this.handleLike,
        })}

        {this.renderActionButton({
          icon: IconSet.CommentLarge,
          label: 'Comment',
          className: classnames('postActionButton'),
          onClick: this.handleFocusComment,
        })}
      </div>
    );
  };

  renderComments = () => {
    const { visibleComments, noMoreComments, loadedComments } = this.state;

    return (
      <div className="postComments">
        {noMoreComments ? null : (
          <span
            className="postCommentPrevious"
            onClick={this.handleShowMoreComments}
          >
            View {loadedComments.length} more comments
          </span>
        )}

        {visibleComments.map(
          ({
            id,
            author,
            dateTime,
            comment,
            content,
            created_at,
            image_url,
          }) => (
            <Comment
              key={id}
              author={author}
              dateTime={created_at}
              comment={content}
              image={image_url}
            />
          )
        )}
      </div>
    );
  };

  renderCommentForm = () => {
    let {
      user: { image, name },
    } = this.props;
    if (this.props.businessProfile) {
      image = this.props.businessProfile.image;
      name = this.props.businessProfile.name;
    }

    return (
      <div className="postCommentForm">
        <Avatar imageUrl={image} username={name} className="postAvatar" small />
        <div className="postAddComment">
          {this.state.file ? (
            <div className="fileInfo">
              {this.state.file.name}
              <div
                className="removeFile"
                onClick={() => this.setState({ file: null })}
              />
            </div>
          ) : (
            <Textarea
              innerRef={(ref) => {
                this.commentFormRef = ref;
              }}
              placeholder="Add a comment"
              value={this.state.comment}
              onChange={this.handleCommentChange}
              growable
            />
          )}
        </div>
        <button className="outline" onClick={this.handleSubmitComment}>
          Send
        </button>
      </div>
    );
  };

  renderPostMenu = () => {
    const { id } = this.state.post;
    return (
      <>
        <span className="postOptions" onClick={this.handleActionsClick}>
          <Icon icon={IconSet.Options} />
        </span>
        {this.state.showActionsNav ? (
          <div className="actionsNav">
            <div className="actionsNavItem" onClick={this.handleEditClick}>
              Edit
            </div>
            <div
              className="actionsNavItem"
              onClick={() => this.handleDeleteClick(id)}
            >
              Delete
            </div>
          </div>
        ) : null}
      </>
    );
  };

  render = () => {
    return (
      <ContentContainer className="post">
        {this.props.user && this.state.post.author.id === this.props.user.id
          ? this.renderPostMenu()
          : null}
        {this.renderAuthor()}
        {this.renderPost()}
        {this.renderDetails()}
        {this.renderActions()}
        {this.state.loaded
          ? this.renderComments()
          : [...Array(2)].map((e, i) => (
              <ContentPlaceholder
                type="comment"
                key={i}
                className="commentPlaceholder"
              />
            ))}
        {this.props.user ? this.renderCommentForm() : null}
      </ContentContainer>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(Post));
