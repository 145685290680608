import React from 'react';
import classnames from 'classnames';

import '../../sass/components/common/GoalCard.scss';

export default class GoalCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleClick = (item) => {
    if (this.props.onClick) {
      this.props.onClick(item);
    }
  }

  renderFollowers = (value) => {
    const num = Number(value);
    return num > 1 ? `${value} Followers` : `${value} Follower`;
  }

  render = () => {
    const item = this.props.item;
    const selected = this.props.selected;
    return (
      <div
        className={classnames('goalCard', {
          'selected': selected,
          'active': this.props.active,
          'selectable': this.props.selectable && !selected,
        })}
        style={{
          color: item.color,
          borderColor: item.color,
        }}
        onClick={() => this.handleClick(item)}
      >
        <div className={`goalCardContent ${item.key}`}>
          <label>{item.name}</label>
          <div className={`goalImage ${item.key}`}/>
          {this.props.showFollowers
            ? <div className='followers'>{this.renderFollowers(item.followers_count)}</div>
            : null}
          {selected ?
            <div className='selectedIndicator' style={{backgroundColor: item.color}}>
              <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <line x1='1.06066' y1='5.93934' x2='5.06066' y2='9.93934' stroke='white' strokeWidth='3'/>
                <line x1='16.0607' y1='1.06066' x2='5.06066' y2='12.0607' stroke='white' strokeWidth='3'/>
              </svg>
            </div>
          : null}
        </div>
      </div>
    )
  }

}
