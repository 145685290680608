const AddButton = ({ onClick }) => {
  return (
    <button type="button" className={'add-box-btn'} onClick={onClick}>
      <svg
        width="46"
        height="30"
        viewBox="0 0 46 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="1.14355"
          width="45"
          height="28"
          rx="2.5"
          fill="white"
          stroke="#F26E26"
        />
        <path
          d="M23.5233 9.64355H22.4767C22.3837 9.64355 22.3372 9.688 22.3372 9.77689V15.0102H17.1395C17.0465 15.0102 17 15.0547 17 15.1436V16.1436C17 16.2324 17.0465 16.2769 17.1395 16.2769H22.3372V21.5102C22.3372 21.5991 22.3837 21.6436 22.4767 21.6436H23.5233C23.6163 21.6436 23.6628 21.5991 23.6628 21.5102V16.2769H28.8605C28.9535 16.2769 29 16.2324 29 16.1436V15.1436C29 15.0547 28.9535 15.0102 28.8605 15.0102H23.6628V9.77689C23.6628 9.688 23.6163 9.64355 23.5233 9.64355Z"
          fill="#F26E26"
        />
      </svg>
    </button>
  );
};
export default AddButton;
