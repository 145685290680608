import React from 'react';
import ActionCable from 'actioncable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Storage from '../helpers/Storage.js';
import { WSUrl } from './global.js';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
  }
};

class UpdateNotificationsController extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.cable = null;
    this.subscription = null;
    if (props.user) {
      this.connectChannel(props.user);
    }
  }

  componentDidMount = () => {
    if (window.Notification) {
      window.Notification.requestPermission();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const userId = (this.props.user || {}).id;
    const prevUserId = (prevProps.user || {}).id;
    if (userId !== prevUserId) {
      if (userId) {
        this.connectChannel(this.props.user);
      } else {
        this.disconnectChannel();
      }
    }
  }

  disconnectChannel = () => {
    if (this.cable) {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
      this.cable.disconnect();
      this.cable = null;
    }
  }

  connectChannel = (user) => {
    this.disconnectChannel();
    if (user) {
      this.cable = ActionCable.createConsumer(WSUrl());
      this.subscription = this.cable.subscriptions.create({
        channel: 'UpdatesChannel'
      }, {
        connected: () => {
          console.log(`Connected to updates as User #${user.id}`);
        },
        disconnected: () => {},
        received: (data) => {
          Storage.setData('user', {
            ...Storage.getData('user'),
            updates_indicators: data,
          })
        },
      });
    }
  }
  
  /*showNotification = (id, title, text) => {
    triggerEvent('showNotification', [{title, text}]);
    if (window.Notification) {
      window.Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          let notification = new window.Notification(title, {
            tag: id,
            body: text,
            icon: './logo.png',
            lang: '',
            dir: 'auto',
          });
          notification.onclick = () => {
            this.props.history.push('/chats');
          }
        }
      });
    }
  }*/

  render = () => {
    return null;
  }

}

export default connect(mapStoreToProps)(withRouter(UpdateNotificationsController));
