import React, { useState } from 'react';

import HeaderMeta from './common/HeaderMeta';
import { triggerEvent } from '../helpers/global';
import { sendRequest } from '../helpers/RequestDispatcher.js';
import '../sass/components/BookExpertView.scss';

const BookExpertView = ({ user }) => {
  const [applied, setApplied] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);

  const showApplyExpert = () => {
    if (!user) return;

    triggerEvent('showConfirmation', [
      {
        title: 'Are you sure to apply as an Expert?',
        overlayBlock: true,
        callback: (action) => {
          if (action) {
            setLoadingApply(true);
            triggerEvent('addLoad');
            sendRequest({
              method: 'user/make-expert',
              type: 'POST',
              noLoad: true,
              node: true,
              success: async (data) => {
                setApplied(true);
                setLoadingApply(false);
                triggerEvent('showSnackbar', [
                  { text: 'Applied as an Expert', type: 'success' },
                ]);
                triggerEvent('removeLoad');
              },
              error: (data) => {
                setLoadingApply(false);
                triggerEvent('showSnackbar', [
                  {
                    text: 'Failure applying as an Expert',
                    type: 'error',
                  },
                ]);
                triggerEvent('removeLoad');
              },
            });
          }
        },
      },
    ]);
  };

  return (
    <>
      <HeaderMeta />
      <div className="section-book-expert-hero">
        <div className="section-content">
          <div className="left-content">
            <div className="content-header">Book an Expert</div>
            <p>
              The SDG Co is pleased to partner with leading
              Knowledge-as-a-Service provider Lynk to power expert consultation
              bookings, exclusively for members of our community.
            </p>
            <p>
              If you are looking for a subject matter or tech expert to help you
              do due diligence or a general consultation, you can browse and
              book experts in our community.
            </p>
            <p>
              If you are an expert in your field and would like to offer your
              services as an expert consult, you can use this feature to let
              people know you are available for these services and manage your
              bookings.
            </p>
            <p>
              <strong>
                <em>
                  Please note that you need a Lynk account to browse/ book an
                  expert or sign up as an expert. If you do not have a Lynk
                  account, please click the button below to receive a
                  personalized invitation link to The SDG Co experts circle on
                  Lynk.
                </em>
              </strong>
            </p>
          </div>
          <div className="right-content"></div>
        </div>
      </div>
      <div className="section-expert-form">
        <div className="section-content">
          {(!user || (user && user.expert_status !== 'approved')) && (
            <div className="submit-button-container">
              {(!user ||
                (user &&
                  ['unapplied', 'rejected'].includes(user.expert_status))) &&
                !applied && (
                  <button
                    type="submit"
                    disabled={!user || loadingApply}
                    onClick={() => showApplyExpert()}
                  >
                    I want to join The SDG Co experts circle
                  </button>
                )}
              {user && (user.expert_status === 'pending' || applied) && (
                <p>
                  Your The SDG Co expert application is still pending for
                  approval.
                </p>
              )}
            </div>
          )}
          <p className="form-footer">
            If you already have a Lynk account, please{' '}
            <a
              href="https://ask.lynk.global/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              log in here.
            </a>
          </p>
          <div className="powered-by">
            <span>Powered by</span>
            <div className="lynk-logo"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookExpertView;
