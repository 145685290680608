import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Tooltip from '../components/common/Tooltip';
import TextInput from '../components/input/TextInput';
import { signIn } from '../helpers/amplify';
import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';
import Storage from '../helpers/Storage';
import '../sass/components/RegisterView.scss';

class RegisterView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'individual-form', // select, expert-form, individual-form, expert-disclaimer
      type: 'individual',
      data: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      errors: {},
    };
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   const { step } = this.state;
  //   const { step: prev_step } = prevState;

  //   if (step !== prev_step && step === 'expert-success') {
  //     this.loadMe();
  //   }
  // };

  validateData = () => {
    const { first_name, last_name, email, password, confirmPassword } =
      this.state.data;
    let errors = {};
    if (!first_name) {
      errors.first_name = 'Please enter First Name';
    }
    if (!last_name) {
      errors.last_name = 'Please enter Last Name';
    }
    if (!email) {
      errors.email = 'Please enter Email';
    }
    if (!password) {
      errors.password = 'Please enter Password';
    }
    if (!confirmPassword) {
      errors.confirmPassword = 'Please confirm Password';
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = "Password doesn't match";
    }
    if (password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }
    this.setState({ errors });
    return errors;
  };

  saveUser = (type) => {
    const errors = this.validateData();
    if (Object.keys(errors).length > 0) {
      return errors;
    }
    const { first_name, last_name, email, password, confirmPassword } =
      this.state.data;
    sendRequest({
      method: 'auth/register',
      type: 'POST',
      data: {
        first_name,
        last_name,
        email,
        password,
        password_confirmation: confirmPassword,
        name: 'test',
      },
      noLoad: true,
      success: async (data) => {
        if (data.access_token) {
          const amplifySignIn = async () => {
            const response = await signIn(email, password);
            if (response) {
              this.loginAction(response);
              if (this.state.type === 'expert') {
                const expertRequest = await new Promise((resolve) => {
                  sendRequest({
                    method: `user/make-expert`,
                    type: 'POST',
                    noLoad: true,
                    node: true,
                    success: async (data) => {
                      resolve(true);
                      triggerEvent('removeLoad');
                      this.setState({ step: 'expert-success' });
                    },
                    error: (data) => {
                      resolve(false);
                      triggerEvent('removeLoad');
                    },
                  });
                });
              } else {
                triggerEvent('removeLoad');
                this.props.history.push(`/wizard/${type}`);
              }
            }
          };
          amplifySignIn();
        } else {
          triggerEvent('removeLoad');
        }
      },
      error: (data) => {
        triggerEvent('removeLoad');
        if (data.errors && data.errors.email) {
          triggerEvent('showSnackbar', [
            { text: data.errors.email, type: 'error' },
          ]);
        } else if (data.errors) {
          triggerEvent('showSnackbar', [{ text: data.errors, type: 'error' }]);
        }
      },
    });
    triggerEvent('addLoad');
  };

  loginAction = (user) => {
    localStorage.setItem(
      'thesdg-client:user_attributes',
      JSON.stringify(user.attributes)
    );

    if (this.state.type !== 'expert') {
      this.loadMe();
    }
  };

  loadMe = () => {
    sendRequest({
      method: 'me',
      type: 'GET',
      success: (data) => {
        Storage.setData('user', data);
      },
      error: (data) => {},
    });
  };

  renderTypeSelect = () => {
    return (
      <div className="registerContainer">
        <div className="registerContent">
          <div className="registerTitle">
            Join the World’s First Ecosystem for Sustainability x Innovation
          </div>
          <div className="registerSubtitle">
            Are you joining as an Individual or as an Expert?
          </div>
          <div className="registerSelect">
            <div className="registerSelectType">
              <div
                className={`registerType expert ${
                  this.state.type === 'expert' ? 'selected' : ''
                }`}
                onClick={() => this.setState({ type: 'expert' })}
              >
                <div className="registerTypeHeader">
                  <div className="typeIcon"></div>
                  <div className="checkSelectedIcon"></div>
                </div>
                <div className="registerTypeContent">
                  <div className="titleText">
                    I’m applying as an{' '}
                    <span className="expertText">Expert</span>.
                  </div>
                  <ul className="typeList">
                    <li>
                      Must have domain expertise in a subject matter within
                      sustainability innovation.
                    </li>
                    <li>
                      Comfortable speaking with consulting clients (corporates
                      and investors) to share their industry insights.
                    </li>
                  </ul>
                </div>
                <div className="registerTypeFooter">
                  <Tooltip
                    direction="right"
                    content={
                      <>
                        <p>
                          An expert is someone with domain expertise in a
                          subject
                          <br />
                          matter within sustainability innovation, for which
                          they
                          <br />
                          are comfortable to speak with consulting clients
                          <br />
                          (corporates and investors) to share their industry
                          <br />
                          insights.
                          <br />
                        </p>
                      </>
                    }
                  >
                    <div className="infoIcon"></div>
                  </Tooltip>
                  <div className="descText">
                    <strong>1-3 business days</strong> verification process
                  </div>
                </div>
              </div>
              <div
                className={`registerType individual ${
                  this.state.type === 'individual' ? 'selected' : ''
                }`}
                onClick={() => this.setState({ type: 'individual' })}
              >
                <div className="registerTypeHeader">
                  <div className="typeIcon"></div>
                  <div className="checkSelectedIcon"></div>
                </div>
                <div className="registerTypeContent">
                  <div className="titleText">I want to join the community.</div>
                  <ul className="typeList">
                    <li>Join the community as an individual.</li>
                    <li>No obligations on paid services.</li>
                    <li>No commitments.</li>
                  </ul>
                </div>
                <div className="registerTypeFooter">
                  <Tooltip
                    direction="right"
                    content={
                      <>
                        <p>
                          Anyone who just wants to join the community without
                          <br />
                          obligation for further paid services or commitments.
                        </p>
                      </>
                    }
                  >
                    <div className="infoIcon"></div>
                  </Tooltip>
                  <div className="descText">
                    <strong>Get verified immediately!</strong>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={() =>
                this.setState({
                  step:
                    this.state.type === 'individual'
                      ? 'individual-form'
                      : 'expert-disclaimer',
                })
              }
            >
              {this.state.type === 'individual'
                ? 'Join as an Individual'
                : 'Apply as an Expert'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderInput = (key, type = 'text', placeholder = '') => {
    return (
      <TextInput
        type={type}
        value={this.state.data[key]}
        placeholder={placeholder}
        error={this.state.errors[key]}
        onChange={(value) =>
          this.setState({
            data: {
              ...this.state.data,
              [key]: value,
            },
          })
        }
      />
    );
  };

  renderIndividualForm = () => {
    return (
      <div className="registerForm">
        {/* TODO: uncomment if Apply as an Expert is needed */}
        {/* <div
          className="backButton"
          onClick={() => this.setState({ step: 'select' })}
        >
          Back
        </div> */}
        <div className="formContent">
          <div className="formTitle">Create your Account</div>
          <label>First Name</label>
          {this.renderInput('first_name', 'text')}
          <label>Last Name</label>
          {this.renderInput('last_name', 'text')}
          <label>Your Personal Email</label>
          {this.renderInput('email', 'email', 'yourname@email.com')}
          <label>Create Password (min. 8 characters)</label>
          {this.renderInput('password', 'password')}
          <label>Confirm Password</label>
          {this.renderInput('confirmPassword', 'password')}
          <button onClick={() => this.saveUser(this.state.type)}>
            Continue
          </button>
          <p>
            By Continuing, I agree to the{' '}
            <span onClick={() => window.open('/privacy', '_blank')}>
              Privacy Policy
            </span>{' '}
            and{' '}
            <span onClick={() => window.open('/terms', '_blank')}>
              Terms of Use
            </span>{' '}
            of The SDG Co.
          </p>
        </div>
      </div>
    );
  };

  renderExpertForm = () => {
    return (
      <div className="registerForm">
        <div
          className="backButton"
          onClick={() => this.setState({ step: 'expert-disclaimer' })}
        >
          Back
        </div>
        <div className="formContent">
          <div className="formTitle">Create an Account</div>
          <label>First Name</label>
          {this.renderInput('first_name', 'text')}
          <label>Last Name</label>
          {this.renderInput('last_name', 'text')}
          <label>Your Personal Email</label>
          {this.renderInput('email', 'email', 'yourname@email.com')}
          <label>Create Password (min. 8 characters)</label>
          {this.renderInput('password', 'password')}
          <label>Confirm Password</label>
          {this.renderInput('confirmPassword', 'password')}
          <button onClick={() => this.saveUser(this.state.type)}>
            Continue
          </button>
          <p>
            By Continuing, I agree to the{' '}
            <span onClick={() => window.open('/privacy', '_blank')}>
              Privacy Policy
            </span>{' '}
            and{' '}
            <span onClick={() => window.open('/terms', '_blank')}>
              Terms of Use
            </span>{' '}
            of The SDG Co.
          </p>
        </div>
      </div>
    );
  };

  renderExpertDisclaimerForm = () => {
    return (
      <div className="registerForm">
        <div className="disclaimer-header">
          <div className="main-logo" />
          <div className="spacer" />
          <div className="lynk-logo" />
        </div>
        <div
          className="backButton"
          onClick={() => this.setState({ step: 'select' })}
        >
          Back
        </div>

        <div className="formContent">
          <div className="disclaimer-form-body">
            <p>
              As an expert, you will be able to act as a paid consultant to
              companies or individuals on this platform on how they can better
              achieve their Sustainable Development Goals.
            </p>
            <p>
              The SDG Co partners with LYNK in reviewing your credibility as an
              Expert, before approving/rejecting the application.
            </p>
            <p>
              By signing up as an Expert on The SDG Co, a LYNK account will also
              be created on your behalf.
            </p>
          </div>
          <button
            className="disclaimer-button"
            onClick={() => this.setState({ step: 'expert-form' })}
          >
            Continue
          </button>
          <p>
            By Continuing, I agree to the{' '}
            <span
              onClick={() =>
                window.open(
                  'https://lynk.global/legal/privacy-policy',
                  '_blank'
                )
              }
            >
              Privacy Policy
            </span>{' '}
            and{' '}
            <span
              onClick={() =>
                window.open(
                  'https://lynk.global/legal/platform-terms-of-service',
                  '_blank'
                )
              }
            >
              Terms of Use
            </span>{' '}
            of LYNK.
          </p>
        </div>
      </div>
    );
  };

  renderExpertSignUpSuccess = () => {
    return (
      <div className="registerForm">
        <div className="disclaimer-header">
          <div className="main-logo" />
          <div className="spacer" />
          <div className="lynk-logo" />
        </div>
        <div className="formContent">
          <div className="disclaimer-form-body">
            <p style={{ textAlign: 'justify' }}>
              The SDG Co has partnered with Lynk - a global leader in
              expert-provision services - to boost your global profile. We will
              email you a personalised link to sign up as an expert on Lynk to
              manage your bookings and payments. The signup process for Lynk
              will take approximately 5 to 10 minutes.
            </p>
            <p style={{ textAlign: 'justify' }}>
              We look forward to having you in The SDG Co!
            </p>
          </div>
          <button className="disclaimer-button" onClick={() => this.loadMe()}>
            Continue
          </button>
          <p>
            By Continuing, I agree to the{' '}
            <span
              onClick={() =>
                window.open(
                  'https://lynk.global/legal/privacy-policy',
                  '_blank'
                )
              }
            >
              Privacy Policy
            </span>{' '}
            and{' '}
            <span
              onClick={() =>
                window.open(
                  'https://lynk.global/legal/platform-terms-of-service',
                  '_blank'
                )
              }
            >
              Terms of Use
            </span>{' '}
            of LYNK.
          </p>
        </div>
      </div>
    );
  };

  render = () => {
    const { step } = this.state;

    return (
      <div className="registerView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/logo.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/logo.png" />
        </Helmet>
        <div
          className="registerLogo"
          onClick={() => this.props.history.push('/')}
        />
        {step === 'select' && this.renderTypeSelect()}
        {step === 'expert-disclaimer' && this.renderExpertDisclaimerForm()}
        {step === 'expert-form' && this.renderExpertForm()}
        {step === 'individual-form' && this.renderIndividualForm()}
        {step === 'expert-success' && this.renderExpertSignUpSuccess()}
      </div>
    );
  };
}

export default withRouter(RegisterView);
