import React from 'react';

import TargetActivityCard from '../common/TargetActivityCard';

import '../../sass/components/input/MulticheckInput.scss';

export default class MulticheckInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  handleSelectGoal = (item) => {
    const value = this.props.value || [];
    const selected = value.includes(item.id);
    this.onChange(selected ? value.filter(i => i !== item.id) : [...value, item.id]);
  }

  render = () => {
    const items = this.props.items || [];
    const value = this.props.value || [];
    return (
      <div className='multicheckInput'>
        {items.map(item => <TargetActivityCard key={item.id} item={item} selected={value.includes(item.id)} onClick={this.handleSelectGoal} selectable />)}
      </div>
    )
  }

}
