export const arrayItemMoveFromTo = (array, from, to) =>
  from < to
    ? [
        ...array.slice(0, from),
        ...array.slice(from + 1, to + 1),
        array[from],
        ...array.slice(to + 1),
      ]
    : [
        ...array.slice(0, to),
        array[from],
        ...array.slice(to, from),
        ...array.slice(from + 1),
      ];
