import React from 'react';
import styled from 'styled-components';

const SubheaderText = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 26px;

  @media all and (min-width: 768px) {
    font-size: 24px;
  }
`;

function Subheader({ title }) {
  return <SubheaderText>{title}</SubheaderText>;
}

export default Subheader;
