import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import TagsSelect from '../input/TagsSelect';
import TextInput from '../input/TextInput';
import Avatar from '../common/Avatar';

import { eventOn, eventOff, triggerEvent, httpToUrl } from '../../helpers/global';
import { sendRequest } from '../../helpers/RequestDispatcher.js';

import '../../sass/components/common/PostFormPopup.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

const WAIT_INTERVAL = 1000;

class PostFormPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: !props.show,
      show: false,
      windowStyle: null,
      contentStyle: null,
      callback: null,
      file: props.file,
      fileUrl: '',
      tmpFileId: null,
      data: {
        goal_ids: props.goalIds,
        author_title: props.authorTitle,
        content: props.content,
        title: props.title,
        link: props.link,
      },
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.file !== this.props.file) {
      this.setData();
    }
    if (prevProps.goalIds !== this.props.goalIds) {
      this.setData();
    }
    if (prevProps.authorTitle !== this.props.authorTitle) {
      this.setData();
    }
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showPostFormPopup', this.showPostFormPopup);
      eventOn('hidePostFormPopup', this.hidePostFormPopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showPostFormPopup', this.showPostFormPopup);
      eventOff('hidePostFormPopup', this.hidePostFormPopup);
    }
  }

  hostname = (url) => {
    let hostname;

    if (url && url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    } else if (url) {
      hostname = url.split('/')[0];
    } else {
      return null;
    }

    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];

    return hostname;
  }

  setData = () => {
    this.setState({
      data: {
        goal_ids: this.props.goalIds,
        author_title: this.props.authorTitle,
        content: this.props.content,
        title: this.props.title,
        link: this.props.link,
      },
      file: this.props.file,
    });
  }

  handleDataChange = (key, value) => {
    this.setState({ [key]: value });
    if (this.props.handleDataChange){
      this.props.handleDataChange(key, value);
    }
  }

  handleFileChange = (file) => {
    this.setState({ file: file, fileUrl: URL.createObjectURL(file), tmpFileId: null });
  }

  showPostFormPopup = (e) => {
    const { windowStyle, contentStyle, callback, file } = e.detail[0];
    this.setState({
      show: true,
      hidden: false,
      windowStyle,
      contentStyle,
      callback,
      file,
    });
  }

  hidePostFormPopup = () => {
    this.setState({show: false});
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.onClose(false);
    }
  }

  onClose = (res) => {
    this.setState({fileUrl: '', tmpFileId: null});
    if (this.props.global) {
      const ret = this.state.callback && this.state.callback(res);
      if (ret && ret.constructor && ret.constructor.name === 'Promise') {
        ret.then(() => {
          this.setState({show: false});
        }).catch(() => {});
      } else {
        this.setState({show: false});
      }
    } else if (this.props.callback) {
      this.props.callback(res);
    }
  }

  attachFile = () => {
    if (this.state.file) {
      this.setState({ file: null, tmpFileId: null });
      this.fileInput.value = '';
    }
    this.fileInput.click();
  }

  submitPost = () => {
    const { file, tmpFileId } = this.state;
    const postId = this.props.postId || '';
    let method = `posts/${postId}`;
    let author_id = this.props.user?.id;
    if (this.props.businessProfile) {
      author_id = this.props.businessProfile.id;
      method = `business_profiles_managing/${author_id}/posts/${postId}`;
    }
    let formData = new FormData();
    Object.keys(this.state.data).forEach(key => {
      const value = this.state.data[key];
      if (value && typeof value === 'string') {
        formData.append(`${key}`, value);
      } else if (value) {
        formData.append(`${key}`, JSON.stringify(value));
      }
    });
    if (tmpFileId) {
      formData.append('tmp_file_id', tmpFileId);
    }
    if (typeof file !== 'string' && file) {
      formData.append('image', file);
    }
    const type = this.props.postId ? 'PUT' : 'POST';
    const action = this.props.postId ? 'updated' : 'created';
    sendRequest({
      type: type,
      method: method,
      formData,
      success: (data) => {
        triggerEvent('showSnackbar', [{text: `Post ${action} successfully`, type: 'success'}]);
        this.setState({
          data: {
            ...this.state.data,
            author_title: null,
            goal_ids: [Number(this.props.goalIds)],
          },
          file: null,
          fileUrl: '',
          tmpFileId: null,
        });
        this.onClose(data);
      },
      error: (data) => {
        if (data.errors && data.errors.goals) {
          triggerEvent('showSnackbar', [{text: `Goals ${data.errors.goals}`, type: 'error'}]);
        } else if (data.errors && data.errors.author_title) {
          triggerEvent('showSnackbar', [{text: `Title ${data.errors.author_title}`, type: 'error'}]);
        } else if (data.error_message) {
          triggerEvent('showSnackbar', [{text: data.error_message, type: 'error'}]);
        }
      }
    });
  }

  handleInputChange = (value) => {
    clearTimeout(this.timer);
    this.setState(
      prevState => {
        return { data: {...prevState.data, author_title: value} };
    })
    if (!value) return;

    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const match_uri = value.match(urlRegex);
    if (match_uri) {
      const uri = match_uri[0];
      this.timer = setTimeout(() => this.triggerSearchImage(uri), WAIT_INTERVAL);
    }
  }

  triggerSearchImage = (uri) => {
    sendRequest({
      method: 'meta/card',
      data: {
        uri: uri,
      },
      type: 'GET',
      success: async (data) => {
        if (data) {
          let fileUrl = '';
          let tmpFileId = null;
          if (data.image) {
            fileUrl = data.image.file_url;
            tmpFileId = data.image.id
          }
          this.setState((prevState) => {
            return {
              tmpFileId: tmpFileId,
              fileUrl: fileUrl,
              file: null,
              data: {
                ...this.state.data,
                content: data.description,
                title: data.title,
                link: uri,
                author_title: prevState.data.author_title.replace(uri, ''),
              }
            }
          });
        } else {
          this.setState({ file: null, fileUrl: '', tmpFileId: null, });
        }
      },
      error: (data) => {
      }
    });
  }

  renderPostFormPopup = () => {
    const { user, businessProfile } = this.props;
    const { data, fileUrl, file, tmpFileId } = this.state;
    const { link, content, title, author_title } = data;
    let author = user;
    if (businessProfile) {
      author = businessProfile;
    }
    const avatarUrl = author
      ? author.image_url ? author.image_url : author.image
      : null;

    return (
      <div className='postForm postFormPopupFeed'>
        <div className='formTitle'>{this.props.postId ? 'Update Post' : 'Create Post'}</div>
        <div className='authorContainer'>
          <Avatar
            imageUrl={avatarUrl}
            username={author.name}
            className='commentAvatar'
            small
          />
          <div className='postContent'>
            <TextInput
              type='textarea'
              placeholder='What do you want to talk about?'
              value={author_title}
              onChange={this.handleInputChange}
              growable
            />
            {((fileUrl && typeof file !== 'string') || typeof file === 'string')
              ? <div
                className='imagePreview'
                style={{backgroundImage: `url(${fileUrl || file})`}}
              />
              : null
            }
            {title || content || link
              ? <div className='postPreviewContent'>
                  <div className='postPreviewTitle'>{title}</div>
                  <div className='postPreviewDescription'>
                    {content && (content.slice(0, 300) + (content.length > 300 ? '...' : ''))}
                  </div>
                  <div
                    className='postPreviewUrl'
                    onClick={() => window.open(httpToUrl(link), '_blank')}
                  >
                    {this.hostname(link)}
                  </div>
                  <div
                    className='closeButton'
                    onClick={() => this.setState({data: {...data, content: null, link: null, title: null}})}
                  />
                </div>
              : null
            }
          </div>
        </div>
        <div className='dataBlock'>
          <div className='dataItem'>
            <div
              className='uploadContainer'
              onClick={this.attachFile}
            >
              <input
                type='file'
                onChange={e => this.handleFileChange(e.target.files[0])}
                ref={input => this.fileInput = input}
              />
              <span
                className='postAddCommentImage'
                onClick={this.attachFile}
              />
              {file || tmpFileId
                ? <div className='fileInfo'>
                    <span>{tmpFileId ? fileUrl.substring(fileUrl.lastIndexOf('/') + 1)
                      : typeof file === 'string' ? file : file.name}</span>
                  </div>
                : <span
                    className='uploadPhoto'
                  >
                    Upload Photo
                  </span>
              }
            </div>
            { file || tmpFileId
              ? <div className='closeButton' onClick={() => this.setState({tmpFileId: null, file: null, fileUrl: ''})} />
              : null
            }
          </div>
        </div>
        <div className='topics'>
          <div className='subtitle'>Related Topics</div>
          <TagsSelect
            request='common_data/goals'
            value={data.goal_ids}
            onChange={val => this.setState({ data: {...data, 'goal_ids': val} })}
            inline
          />
        </div>
        <button
          onClick={this.submitPost}
          className='submitPostBtn'
        >
          {this.props.postId ? 'Update' : 'Post'}
        </button>
      </div>
    )
  }

  render = () => {
    const { show, windowStyle, contentStyle } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'postFormPopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow' style={windowStyle}>
              <div className='popupContent' style={contentStyle}>
                <div className='closeButton' onClick={() => this.onClose(false)}/>
                {this.renderPostFormPopup()}
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(PostFormPopup);
