import React from 'react';
import classnames from 'classnames';

import '../../sass/components/common/ContentContainer.scss';

const ContentContainer = ({ className, children }) => (
  <section className={classnames('contentContainer', className)}>
    {children}
  </section>
)

export default ContentContainer;