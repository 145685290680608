import React from 'react';
import SidebarSection from './SidebarSection';
import dayjs from 'dayjs';

import ContentPlaceholder from '../common/ContentPlaceholder';

import { sendRequest } from '../../helpers/RequestDispatcher.js';

import '../../sass/components/feed/Sidebar.scss';

const INIT_DATA = [
  { url: 'rss/news', object: 'news', data: { limit: 5 } },
  { url: 'rss/events', object: 'events', data: { limit: 5, request_start_date: true } },
];

class Sidebar extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      news: [],
      events: [],
      loaded: {
        news: false,
        events: false,
        adsTag: false,
      },
    }
  }

  componentDidMount = () => {
    INIT_DATA.forEach(item => this.getData(item.url, item.object, item.data));
    this.loadGPTag(this.initGPTag());
  }

  getData = (url, object, data) => {
    sendRequest({
      method: url,
      type: 'GET',
      data,
      success: (data) => {
        this.setState({[object]: data, loaded: { ...this.state.loaded, [object]: true }});
      },
      error: (data) => {
      }
    });
  }

  loadGPTag = (callback) => {
    const existingScript = document.getElementById('gpt');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.id = 'gpt';
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  }

  initGPTag = () => {
    const existingScript = document.getElementById('gptInit');
    if (!existingScript) {
      const script = document.createElement('script');
      script.id = 'gptInit';
      script.text = `
        window.googletag = window.googletag || {cmd: []};
        let slot1, slot2, slot3, slot4, slot5, slot6;
        googletag.cmd.push(function() {
            slot1 = googletag.defineSlot('/16222413/SCO-L1', [728, 90], 'div-gpt-ad-1626942035534-0').addService(googletag.pubads());
            slot2 = googletag.defineSlot('/16222413/SCO-L1-MOBILE', [320, 50], 'div-gpt-ad-1626942200946-0').addService(googletag.pubads());
            slot3 = googletag.defineSlot('/16222413/SCO-L2', [728, 90], 'div-gpt-ad-1626951971479-0').addService(googletag.pubads());
            slot4 = googletag.defineSlot('/16222413/SCO-L2-MOBILE', [320, 50], 'div-gpt-ad-1626952078095-0').addService(googletag.pubads());
            slot5 = googletag.defineSlot('/16222413/SCO-R1', [300, 250], 'div-gpt-ad-1626942351037-0').addService(googletag.pubads());
            slot6 = googletag.defineSlot('/16222413/SCO-R2', [300, 250], 'div-gpt-ad-1626942431201-0').addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();
            googletag.pubads().refresh([slot1, slot2, slot3, slot4, slot5, slot6]);
        });
      `;
      document.body.appendChild(script);
      this.setState({loaded: { ...this.state.loaded, adsTag: true }});
    } else {
      const script = document.createElement('script');
      script.id = 'gptRefresh';
      script.text = `
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(function() {
          googletag.pubads().refresh([slot5, slot6]);
        });
      `;
      document.body.appendChild(script);
      this.setState({loaded: { ...this.state.loaded, adsTag: true }});
    }
  }

  renderLatestNewsItem = (item, index) => (
    <a className='latestNewsItem' href={item.link} key={index} target='_blank' rel='noopener noreferrer'>
      <img
        src={item.image_url}
        alt='News'
        className='latestNewsItemCover'
      />

      <div>
        <div className='latestNewsItemTheme'>{item.theme}</div>
        <div className='latestNewsItemTitle'>{item.title}</div>
      </div>
    </a>
  )

  renderLatestNews = () => {
    const { news } = this.state;
    return (
      <SidebarSection
        withEcoBusiness
        title='Latest News'
      >
        { this.state.loaded.news
          ? news.map((item, index) => this.renderLatestNewsItem(item, index))
          : [...Array(5)].map((e, i) => (
              <ContentPlaceholder type='news' key={i} className='newsPlaceholder' />
            ))
        }
      </SidebarSection>
    );
  }

  renderAgendaItem = (item, index) => (
    <div
      className='agendaItem'
      key={index}
      onClick={() => window.open(item.link, '_blank')}
    >
      {this.renderCalendarDay(item.start_date)}
      <div>
        <div className='agendaItemCountry'>{item.country}</div>
        <div>{item.title}</div>
      </div>
    </div>
  )

  renderCalendarDay = (date) => (
    <div className='calendarDay'>
      <div className='calendarDayMonth'>
        {date
          ? dayjs(date).format("MMM")
          : null
        }
      </div>
      <div className='calendarDayDate agendaItemCalendarDay'>
        {date
          ? dayjs(date).format('D')
          : null
        }
      </div>
    </div>
  )

  renderAgenda = () => {
    const { events } = this.state;
    return (
      <SidebarSection
        withEcoBusiness
        title='Featured Events'
        className='withoutDivider'
      >
      { this.state.loaded.events
        ? events.map((item, index) => this.renderAgendaItem(item, index))
        : [...Array(5)].map((e, i) => (
            <ContentPlaceholder type='events' key={i} className='newsPlaceholder' />
          ))
      }
      </SidebarSection>
    );
  }

  render = () => (
    <aside className='sidebar'>
      {this.state.loaded.adsTag ?
      <SidebarSection>
        <div className='banner'>
          <div id='div-gpt-ad-1626942351037-0' style={{ minWidth: '300px', minHeight: '250px' }}>
            <script dangerouslySetInnerHTML={{ __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626942351037-0'); });` }} />
          </div>
        </div>
      </SidebarSection>
      : null}

      {this.renderLatestNews()}
      {this.renderAgenda()}

      {this.state.loaded.adsTag ?
      <SidebarSection>
        <div className='banner'>
          <div id='div-gpt-ad-1626942431201-0' style={{ minWidth: '300px', minHeight: '250px' }}>
            <script dangerouslySetInnerHTML={{ __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626942431201-0'); });` }} />
          </div>
        </div>
      </SidebarSection>
      : null}
    </aside>
  )
}

export default Sidebar;
