import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Post from './feed/Post';
import Sidebar from './feed/Sidebar';

import ContentPlaceholder from './common/ContentPlaceholder';

import { sendRequest } from '../helpers/RequestDispatcher.js';

import '../sass/components/PostView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class PostView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      post: [],
      loaded: false,
    };
  }

  componentDidMount = () => {
    const { id } = this.props.match.params;
    sendRequest({
      type: 'GET',
      method: `posts/${id}`,
      success: (data) => {
        if (data) {
          this.setState({
            post: data,
            loaded: true,
          });
        }
      },
      error: (data) => {},
    });
  };

  renderPost = () => {
    const post = this.state.post;
    return (
      post && (
        <Post
          key={post.id}
          post={post}
          user={this.props.user}
          author={post.author}
          likeCount={post.likes_count}
          publishDateTime={post.created_at}
          article={post.author_title}
          isLiked={post.liked}
        />
      )
    );
  };

  renderTimeline = () => (
    <div className="feedTimeline">
      {this.state.loaded
        ? this.renderPost()
        : [...Array(1)].map((element, index) => (
            <div key={index} className="postContentPlaceholder">
              <ContentPlaceholder type="post" />
            </div>
          ))}
    </div>
  );

  render = () => (
    <>
      <Helmet>
        <title>The SDG Co</title>
        <meta name="description" content="The SDG Co" />
        <meta name="keywords" content="social" />
        <meta property="og:url" content="https://thesdg.co" />
        <meta property="og:site_name" content="The SDG Co" />
        <meta property="og:title" content="The SDG Co" />
        <meta property="og:image" content="https://thesdg.co/thesdg.png" />
        <meta property="og:description" content="The SDG Co" />
        <meta property="og:type" content="website" />
        <meta name="image" content="https://thesdg.co/thesdg.png" />
      </Helmet>
      <div className="feedView">
        <div className="feed">
          {this.renderTimeline()}
          <Sidebar />
        </div>
      </div>
    </>
  );
}

export default connect(mapStoreToProps)(withRouter(PostView));
