import React from 'react';
import { Link } from 'react-router-dom';

import HeaderMeta from './common/HeaderMeta';
import '../sass/components/NotFound.scss';

const NotFound = () => {
  return (
    <>
      <HeaderMeta />
      <div className="section-404-not-found">
        <div className="section-content">
          <div className="content-header">404 Page not found</div>
          <Link to="/">Go back to home page</Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
