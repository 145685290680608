import React from 'react';
import classnames from 'classnames';

import Avatar from '../common/Avatar';

import '../../sass/components/input/AutocompleteInput.scss';

export default class AutocompleteInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      focus: false,
    };
    this.input = null;
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  handleSelect = (item) => {
    if (this.props.onSelect) {
      this.props.onSelect(item);
    }
  }

  componentDidMount = () => {
  }

  renderTextInput = () => {
    let value = this.props.value;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    return (
      <input
        type='text'
        value={value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        onFocus={() => {
          if (this.props.onFocus) this.props.onFocus();
          this.setState({focus: true});
        }}
        onBlur={() => {
          if (this.props.onBlur) this.props.onBlur();
          this.setState({focus: false});
        }}
        className={classnames('form-control', {'active': this.state.focus && this.props.options})}
        placeholder={this.props.placeholder}
        ref={input => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
        disabled={this.props.disabled}
      />
    )
  }

  renderOptions = () => {
    const options = this.props.options;
    return (
      <div className='autocompleteOptions'>
        {options.length > 0
          ? options.map(item => this.renderOption(item))
          : <div className='autocompleteOption noResults'>No results found</div>
        }
      </div>
    )
  }

  renderOption = (item) => {
    return (
      <div
        key={item.id}
        className='autocompleteOption'
        onClick={() => this.handleSelect(item)}
      >
        {item.image
          ? <Avatar imageUrl={item.image} name={item.name} className='autocompleteImage' small />
          : null}
        {item.name}
      </div>
    )
  }

  render = () => {
    const error = this.props.error || null;
    return (
      <div
        className={classnames({
          'autocompleteInput': true,
          'simplified': this.props.simplified,
          'focused': this.props.options && this.state.focus,
        })}
      >
        {this.renderTextInput()}
        {this.props.options ? this.renderOptions() : null}
        {error && <div className='inputError'>{error}</div>}
      </div>
    )
  }
}
