import React from 'react';
import classnames from 'classnames';
import Storage from '../../helpers/Storage';
import { triggerEvent } from '../../helpers/global';
import { sendRequest } from '../../helpers/RequestDispatcher.js';
import {
  signIn,
  forgotPassword,
  forgotPasswordSubmit,
} from '../../helpers/amplify';

import { IoIosArrowBack } from 'react-icons/io';
import styles from '../../sass/components/Auth.module.scss';

class AuthPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'login',
      data: {
        email: '',
        password: '',
        code: '',
      },
      errors: {},
      token: '',
    };
  }

  componentDidMount = () => {};

  handleLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { email, password } = this.state.data;
    let errors = {};
    if (!password) {
      errors.password = 'Please enter Password';
    }
    this.setState({ errors });
    if (Object.keys(errors).length > 0) {
      return;
    }

    const amplifySignIn = async () => {
      triggerEvent('addLoad');

      const response = await signIn(email, password);

      if (response) {
        this.loginAction(response);
        triggerEvent('hideContentPopup');
        triggerEvent('showSnackbar', [
          { text: 'Success Login', type: 'success' },
        ]);
      } else {
        triggerEvent('showSnackbar', [
          {
            text: 'Email/password is incorrect',
            type: 'error',
          },
        ]);
      }

      triggerEvent('removeLoad');
    };
    amplifySignIn();
  };

  handleForgot = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { email } = this.state.data;

    const amplifyForgotPassword = async () => {
      triggerEvent('addLoad');

      const response = await forgotPassword(email);

      if (response) {
        this.setState({ mode: 'verify' });
        triggerEvent('showSnackbar', [
          {
            text: 'An email was sent with the verification code',
            type: 'success',
          },
        ]);
      } else {
        triggerEvent('showSnackbar', [
          {
            text: 'Error in sending verification code. Please try again later.',
            type: 'error',
          },
        ]);
      }

      triggerEvent('removeLoad');
    };
    amplifyForgotPassword();
  };

  handleVerifyCode = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { email, password, code } = this.state.data;

    const amplifyForgotPasswordSubmit = async () => {
      triggerEvent('addLoad');

      const response = await forgotPasswordSubmit(email, code, password);

      if (response) {
        this.setState({ mode: 'login' });
        triggerEvent('showSnackbar', [
          { text: 'Success updating password', type: 'success' },
        ]);
      } else {
        triggerEvent('showSnackbar', [
          {
            text: 'Error in updating password. Please try again later.',
            type: 'error',
          },
        ]);
      }

      triggerEvent('removeLoad');
    };
    amplifyForgotPasswordSubmit();
  };

  loginAction = (user) => {
    localStorage.setItem(
      'thesdg-client:user_attributes',
      JSON.stringify(user.attributes)
    );
    sendRequest({
      method: 'me',
      type: 'GET',
      success: (data) => {
        Storage.setData('user', data);
      },
      error: (data) => {},
    });
  };

  renderInput = (
    key,
    type = 'text',
    label,
    required = false,
    disabled = false
  ) => {
    const { data, errors } = this.state;
    return (
      <div>
        <span className={styles.authInputLabel}>{label}</span>
        <div
          key={key}
          className={classnames(styles.authInput, {
            [styles.authInputError]: errors[key],
          })}
        >
          <input
            type={type}
            autoComplete={key}
            value={errors[key] ? '' : data[key]}
            onChange={(e) =>
              this.setState({ data: { ...data, [key]: e.target.value } })
            }
            onFocus={(e) =>
              this.setState({ errors: { ...errors, [key]: null } })
            }
            required={required}
            disabled={disabled}
          />
        </div>
      </div>
    );
  };

  renderLoginForm = () => {
    return (
      <form onSubmit={this.handleLogin}>
        {this.renderInput('email', 'email', 'Your Email', true)}
        {this.renderInput('password', 'password', 'Password', true)}
        <div
          className={styles.authForgotPass}
          onClick={() => this.setState({ mode: 'forgot' })}
        >
          Forgot password?
        </div>
        <button className={styles.authBtn}>Log In</button>
      </form>
    );
  };

  renderForgotForm = () => {
    const { mode } = this.state;
    const handler =
      mode !== 'verify' ? this.handleForgot : this.handleVerifyCode;

    return (
      <form onSubmit={handler}>
        {this.renderInput(
          'email',
          'email',
          'Your Email',
          true,
          mode === 'verify'
        )}
        {mode === 'verify' && (
          <>
            <div className={styles.authTitle}>
              Enter verification code and password
            </div>
            {this.renderInput('code', 'text', 'Verification code', true)}
            {this.renderInput('password', 'password', 'Password', true)}
          </>
        )}
        <button className={styles.authBtn}>
          {mode !== 'verify' ? 'Reset password' : 'Submit'}
        </button>
      </form>
    );
  };

  render = () => {
    const title =
      this.state.mode === 'login'
        ? 'Log In to your Account'
        : 'Send reset password instruction';
    return (
      <div className={styles.authView}>
        {['forgot', 'verify'].includes(this.state.mode) ? (
          <div
            className={styles.authBack}
            onClick={() => this.setState({ mode: 'login' })}
          >
            <IoIosArrowBack className={styles.authBackArrow} />
            Back
          </div>
        ) : null}
        <div className={styles.authTitle}>{title}</div>
        {this.state.mode === 'login'
          ? this.renderLoginForm()
          : this.renderForgotForm()}
      </div>
    );
  };
}

export default AuthPopup;
