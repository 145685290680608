import React from 'react';
import { ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import classnames from 'classnames';

import { triggerEvent } from '../../helpers/global';

import '../../sass/components/common/StripeCardForm.scss';

const CardElementStyle = {
  base: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 500,
    color: '#262626',
    textTransform: 'capitalize',
    '::placeholder': {
      color: '#BFBFBF',
    },
  },
}

class InnerCardForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
    }
    this.elements = {};
    this.elementsComplete = {};
  }

  getToken = (handler) => {
    this.props.stripe
      .createToken(this.elements['numberElement'], {
        name: this.state.name,
        currency: 'usd',
      })
      .then(payload => {
        if (payload.error) {
          triggerEvent('showSnackbar', [{text: payload.error.message, type: 'error'}]);
          handler();
        } else if (handler) {
          handler(payload.token);
        }
      });
  }

  onElementReady = (element, type) => {
    this.elements[type] = element;
    const { numberElement, expiryElement, cvcElement } = this.elements;
    if (numberElement && expiryElement && cvcElement) {
      this.props.onReady(this.getToken);
    }
  }

  onElementChange = (change, type) => {
    this.elementsComplete[type] = change.complete;
    const { numberElement, expiryElement, cvcElement } = this.elementsComplete;
    if (numberElement && expiryElement && cvcElement) {
      this.props.onChange({complete: true});
    } else {
      this.props.onChange({complete: false});
    }
  }

  render = () => {
    let numberOptions = { style: CardElementStyle };
    let cvcOptions = { style: CardElementStyle };
    if (this.props.embedded) {
      numberOptions.placeholder = 'Credit Card Number';
      cvcOptions.placeholder = 'CVV';
    }
    return (
      <div
        className={classnames({
          'stripeCardForm': true,
          'embedded': this.props.embedded,
        })}
      >
        {this.props.embedded ? null : <label>Credit Card Number</label>}
        <CardNumberElement
          onReady={stripeElement => this.onElementReady(stripeElement, 'numberElement')}
          onChange={changeObject => this.onElementChange(changeObject, 'numberElement')}
          options={numberOptions}
        />
        {this.props.embedded ? null : <label>Name on Card</label>}
        <input
          value={this.state.name}
          placeholder='Enter name on card'
          type='text'
          onChange={e => this.setState({name: e.target.value})}
        />
        <div className='row'>
          {this.props.embedded
            ? <div className='column expiryLabel'>Expiry</div>
            : null}
          <div className='column'>
            {this.props.embedded ? null : <label>Expiry Date</label>}
            <CardExpiryElement
              onReady={stripeElement => this.onElementReady(stripeElement, 'expiryElement')}
              onChange={changeObject => this.onElementChange(changeObject, 'expiryElement')}
              options={{ style: CardElementStyle }}
            />
          </div>
          <div className='column'>
          {this.props.embedded ? null : <label>CVV/CVC</label>}
          <CardCvcElement
            onReady={stripeElement => this.onElementReady(stripeElement, 'cvcElement')}
            onChange={changeObject => this.onElementChange(changeObject, 'cvcElement')}
            options={cvcOptions}
          />
          </div>
        </div>
      </div>
    );
  }
}

export default (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <InnerCardForm stripe={stripe} elements={elements} {...props}/>
    )}
  </ElementsConsumer>
);
