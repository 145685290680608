export const SINGLE_MAX_LENGTH = 3;
export const KILO_MAX_LENGTH = 6;
export const MEGA_MAX_LENGTH = 9;

export const KILO_VALUE = 1000;
export const MEGA_VALUE = 1000000;

export const KILO_SUFFIX = 'k';
export const MEGA_SUFFIX = 'm';

export const formatWithSuffix = value => {
  const suffixDictionary = [
    [1, SINGLE_MAX_LENGTH, ''],
    [KILO_VALUE, KILO_MAX_LENGTH, KILO_SUFFIX],
    [MEGA_VALUE, MEGA_MAX_LENGTH, MEGA_SUFFIX]
  ];

  let result = value;

  for (let i = 0; i < suffixDictionary.length; i++) {
    const [denominator, max, suffix] = suffixDictionary[i];

    if (value.toString().length <= max) {
      result = `${value/denominator}${suffix}`

      break;
    }
  }

  return result;
}