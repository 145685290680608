import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';
import { changePassword } from '../helpers/amplify';
import TextInput from './input/TextInput.js';
import HeaderMeta from './common/HeaderMeta.js';

import '../sass/components/ChangePasswordView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class ChangePasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: '',
      password: '',
      password_confirmation: '',
      errors: {},
    };
  }

  submit = () => {
    let errors = {};
    const { password, password_confirmation, old_password } = this.state;
    if (!password) {
      errors['password'] = 'Password cannot be blank';
    } else if (password !== password_confirmation) {
      errors['password_confirmation'] =
        "Password confirmation doesn't match password";
    }
    if (password === old_password) {
      errors['password'] = 'New password cannot be current';
    }
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return;
    }

    const amplifyChangePassword = async () => {
      triggerEvent('addLoad');

      const response = await changePassword(
        old_password,
        password_confirmation
      );

      if (response) {
        triggerEvent('showSnackbar', [
          { text: 'Password updated', type: 'success' },
        ]);
      } else {
        triggerEvent('showSnackbar', [
          {
            text: 'Password is not updated',
            type: 'error',
          },
        ]);
      }

      triggerEvent('removeLoad');
    };
    amplifyChangePassword();

    // sendRequest({
    //   method: 'me/password',
    //   type: 'PUT',
    //   data: {
    //     password,
    //     old_password,
    //     password_confirmation,
    //   },
    //   success: (data) => {
    //     triggerEvent('showSnackbar', [
    //       { text: 'Password updated', type: 'success' },
    //     ]);
    //     this.props.history.push('/change_password');
    //   },
    //   error: (data) => {
    //     if (data.errors) {
    //       this.setState({ errors: data.errors });
    //     }
    //   },
    // });
  };

  render = () => {
    const user = this.props.user;

    return (
      <div
        className={classnames('changePasswordView', {
          embedded: this.props.embedded,
        })}
      >
        <HeaderMeta />
        <div className="row">
          <div className="col-50">
            <div className="title">Account</div>
            <div>
              <span className="inputLabel">Your Email</span>
              <TextInput
                type={'text'}
                disabled
                value={user.email}
                error={this.state.errors['email']}
              />
            </div>
          </div>
          <div className="col-50">
            <div className="title">Change Password</div>
            <div>
              <span className="inputLabel">Current Password</span>
              <TextInput
                type={'password'}
                value={this.state.old_password}
                onChange={(val) => this.setState({ old_password: val })}
                error={this.state.errors['old_password']}
              />
            </div>
            <div>
              <span className="inputLabel">
                New Password (min. 8 characters)
              </span>
              <TextInput
                type={'password'}
                value={this.state.password}
                onChange={(val) => this.setState({ password: val })}
                error={this.state.errors['password']}
              />
            </div>
            <div>
              <span className="inputLabel">Confirm Password</span>
              <TextInput
                type={'password'}
                value={this.state.password_confirmation}
                onChange={(val) =>
                  this.setState({ password_confirmation: val })
                }
                error={this.state.errors['password_confirmation']}
              />
            </div>
          </div>
        </div>
        <div className="separationLine" />
        <div className="row">
          <div className="col-50">
            <button className="actionBtn" onClick={this.submit}>
              Save Changes
            </button>
          </div>
        </div>
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(ChangePasswordView));
