import React from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import Avatar from './Avatar';
import dayjs from 'dayjs';

import '../../sass/components/common/Comment.scss';

class Comment extends React.Component {

  handlePostAuthorClick = (identifier) => {
    if (this.props.author.account_type === 'business_profile') {
      this.props.history.push(`/business/${identifier}`);
    } else {
      this.props.history.push(`/user/${identifier}`);
    }
  }

  render = () => {
    const {
      author,
      dateTime,
      comment,
      className,
      image
    } = this.props;

    return (
      <div className={classnames('commentContainer', className)}>
        <Avatar
          imageUrl={author.image_url}
          username={author.name}
          className='commentAvatar'
          small
          onClick={() => this.handlePostAuthorClick(author.slug || author.id)}
        />
        <div className='comment'>
          <div className='commentMeta'>
            <div className='commentUser' onClick={() => this.handlePostAuthorClick(author.slug || author.id)}>
              {author.name}
              {author.subscribed ? <div className='subscribed' /> : null}
            </div>
            <div className='commentDate'>{dayjs.unix(dateTime).fromNow()}</div>
          </div>
          <div className='commentContent'>
            {image
              ? <picture>
                  <img src={image} alt='Comment' />
                </picture>
              : comment
            }
          </div>
        </div>
      </div>
    )
  }

}

export default withRouter(Comment);
