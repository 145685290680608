import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/CheckboxInput.scss';

export default class CheckboxInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render = () => {
    const { value, label, disabled, error, small } = this.props;
    return (
      <div className={classnames({
          'checkboxInput': true,
          'small': small,
          'disabled': disabled
        })}
      >
        <div className='checkboxContainer'>
          <div
            className={`checkbox ${value ? 'checked' : ''}`}
            onClick={disabled ? null : () => this.handleChange(!value)}
          />
          {label ?
            <div
              className='label'
              onClick={disabled ? null : () => this.handleChange(!value)}
            >{label}</div>
          : null}
        </div>
        {error ?
          <div className='inputError'>{error}</div>
        : null}
      </div>
    )
  }

}
