import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sendRequest } from '../helpers/RequestDispatcher.js';
import '../sass/components/WizardView.scss';
import ChangePasswordView from './ChangePasswordView';
import StepperView from './common/StepperView';
import EditExpertDetailsView from './EditExpertDetailsView';
import EditInterestsView from './EditInterestsView';
import EditProfileView from './EditProfileView';
import EditStaffView from './EditStaffView';
import ManageCardView from './ManageCardView';
import ExistingOrganizationView from './ExistingOrganizationView';
import ListOrganizationsView from './ListOrganizationsView';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

class WizardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      steps: [],
      step: null,
      organizationId: null,
    };
  }

  ownProfile = () => {
    const { businessProfile, user } = this.props;
    return businessProfile ? businessProfile : user;
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const steps = this.initSteps();
      this.setState({
        steps: this.getFilteredSteps(steps),
        step: steps[0]?.key,
        organizationId: this.paramOrganizationId(),
      });
    }

    const { user: currentUserState } = this.state;
    const { user: prevUserState } = prevState;
    if (currentUserState !== null && prevUserState === null) {
      const steps = this.initSteps();
      this.setState({
        steps: this.getFilteredSteps(steps),
        step: steps[0]?.key,
        organizationId: this.paramOrganizationId(),
      });
    }
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    sendRequest({
      type: 'GET',
      method: 'me',
      node: true,
      success: (data) => {
        if (data) {
          const steps = this.initSteps();
          this.setState({
            user: { ...data },
            steps: this.getFilteredSteps(steps),
            step: steps[0]?.key,
            organizationId: this.paramOrganizationId(),
          });
        }
      },
      error: (data) => {},
    });
  };

  initSteps = () => {
    const subscriptionEnabled = !!this.ownProfile()
      ? this.ownProfile().subscription_enabled
      : true;
    const role = this.paramRole();

    if (role === 'individual') {
      return [
        { key: 'profile', title: 'About You' },
        { key: 'business_profile', title: 'Business Profile' },
        { key: 'interests', title: 'UN SDGs & Interests' },
        {
          key: 'payment',
          title: 'Payment Setting',
          hiddenCondition: !subscriptionEnabled,
          editOnly: true,
        },
        {
          key: 'account_settings',
          title: 'Account Setting',
          hiddenCondition: false,
          editOnly: true,
        },
        { key: 'complete', title: 'Complete!', wizard: true },
      ];
    } else if (role === 'expert') {
      return [
        { key: 'profile', title: 'About You' },
        { key: 'business_profile', title: 'Business Profile' },
        { key: 'interests', title: 'UN SDGs & Interests' },
        { key: 'expert_details', title: 'Expert Details' },
        {
          key: 'payment',
          title: 'Payment Setting',
          hiddenCondition: !subscriptionEnabled,
          editOnly: true,
        },
        {
          key: 'account_settings',
          title: 'Account Setting',
          hiddenCondition: false,
          editOnly: true,
        },
        { key: 'complete', title: 'Complete!', wizard: true },
      ];
    } else if (role === 'organization') {
      return [
        { key: 'profile', title: 'About You' },
        { key: 'interests', title: 'UN SDGs & Interests' },
        // { key: 'staff', title: 'Staff', editOnly: true },
        {
          key: 'payment',
          title: 'Payment Setting',
          editOnly: true,
          hiddenCondition: !!subscriptionEnabled
            ? this.props.businessProfile?.id !== this.props?.user?.id
            : true,
        },
        {
          key: 'account_settings',
          title: 'Account Setting',
          hiddenCondition: false,
          editOnly: true,
        },
        { key: 'complete', title: 'Complete!', wizard: true },
      ];
    } else {
      return [];
    }
  };

  getFilteredSteps = (steps) => {
    if (!this.isWizard()) {
      steps = steps.filter((step) => !step.wizard && !step.hiddenCondition);
    } else {
      steps = steps.filter((step) => !step.editOnly);
    }
    return steps;
  };

  handleOrganization = (value) => {
    this.setState({ organizationId: value });
  };

  isWizard = () => {
    return this.props.match.path.includes('wizard');
  };

  paramOrganizationId = () => {
    if (this.props.match.params.business_id) {
      return this.props.match.params.business_id;
    }
  };

  paramRole = () => {
    const { params } = this.props.match;
    if (params.role) {
      return params.role;
    } else if (params.user_identifier) {
      if (this.state && this.state.user) {
        const { user } = this.state;
        if (['approved', 'pending'].includes(user.expert_status)) {
          return 'expert';
        }
      }
      return 'individual';
    } else if (params.business_id) {
      return 'organization';
    }
  };

  onSave = () => {
    if (!this.isWizard()) {
      triggerEvent('showSnackbar', [
        { text: 'Profile has been updated successfully.', type: 'success' },
      ]);
      return;
    }
    const index = this.state.steps.findIndex((i) => i.key === this.state.step);
    const nextStep = this.state.steps[index + 1];
    if (nextStep) {
      this.setState({ step: nextStep.key });
    }
  };

  openProfilePage = () => {
    this.props.history.push('/profile');
    // const userId = this.state.user?.slug ?? this.state.user.id;
    // if (userId) {
    //   return this.paramRole() === 'individual'
    //     ? this.props.history.push(`/user/${userId}`)
    //     : this.props.history.push(`/user/${userId}`);
    // }
  };

  renderCompleteStep = () => {
    return (
      <div className="completeStep">
        <div className="completeImage" />
        <div className="completeTitle">
          {this.paramRole() === 'individual' &&
            'Awesome! Your Profile is ready.'}
          {this.paramRole() === 'expert' &&
            'Awesome! You have registered to be an Expert on TheSDGCo!'}
        </div>
        {this.paramRole() === 'individual' && (
          <div className="completeSubtitle">
            Check out your profile and you can change these information anytime
            later.
          </div>
        )}
        {this.paramRole() === 'expert' && (
          <>
            <p>
              We will now verify your experiences and business histories. It
              will take up to X business days for your account to be verified.
            </p>
            <br />
            <p>
              You will receive an email on the progress of the verification. If
              you have received no emails within 24 hours please contact
            </p>
            <br />
            <a href="mailto:admin@thesdg.co">admin@thesdg.co</a>
            <br />
          </>
        )}

        <button onClick={this.openProfilePage}>
          {this.paramRole() === 'individual' && 'See your Profile'}
          {this.paramRole() === 'expert' && 'See Expert Profile'}
        </button>
      </div>
    );
  };

  renderStepContent = () => {
    if (this.state.step === 'profile') {
      return (
        <EditProfileView
          wizard={this.isWizard()}
          onSave={this.onSave}
          role={this.paramRole()}
          organizationId={this.state.organizationId}
          handleOrganization={this.handleOrganization}
        />
      );
    } else if (this.state.step === 'interests') {
      return (
        <EditInterestsView
          wizard={this.isWizard()}
          onSave={this.onSave}
          role={this.paramRole()}
          organizationId={this.state.organizationId}
        />
      );
    } else if (this.state.step === 'business_profile') {
      return (
        <>
          {/* <div className='existing-new-organisation-div'>
          <ExistingOrganizationView
          wizard={this.isWizard()}
          onSave={this.onSave}
          role={this.paramRole()}
          organizationId={this.state.organizationId}
        />
        </div>
        <div className='add-new-organisation-div'>Add a New Organisation</div> */}
          <EditProfileView
            wizard={this.isWizard()}
            onSave={this.onSave}
            role={''}
            organizationId={this.state.organizationId}
          />
        </>
      );
    } else if (this.state.step === 'expert_details') {
      return (
        <EditExpertDetailsView wizard={this.isWizard()} onSave={this.onSave} />
        // <EditSolutionsView
        //   wizard={this.isWizard()}
        //   onSave={this.onSave}
        //   organizationId={this.state.organizationId}
        // />
      );
    } else if (this.state.step === 'staff') {
      return (
        <EditStaffView
          onSave={this.onSave}
          organizationId={this.state.organizationId}
        />
      );
    } else if (this.state.step === 'complete') {
      return this.renderCompleteStep();
    } else if (this.state.step === 'payment') {
      return <ManageCardView />;
    } else if (this.state.step === 'account_settings') {
      return <ChangePasswordView embedded />;
    }
  };

  render = () => {
    return (
      <div className="wizardView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/logo.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/logo.png" />
        </Helmet>
        <StepperView
          steps={this.state.steps}
          step={this.state.step}
          onStepChange={(step) => this.setState({ step })}
          centered={['individual', 'expert'].includes(this.paramRole())}
        />
        {this.renderStepContent()}
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(WizardView));
