import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/Textarea.scss';

class Textarea extends React.Component {
  
  handleKeyDown = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  render = () => {
    const {
      innerRef,
      className,
      placeholder,
      value,
      onClick,
      onChange,
      onFocus,
      onBlur,
      growable
    } = this.props;

    return (
      <textarea
        ref={innerRef}
        className={classnames('messangerTextarea', className)}
        placeholder={placeholder}
        value={value}
        onClick={onClick}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={growable && this.handleKeyDown}
      />
    )
  }
}

export default Textarea;
