import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HeaderMeta from './common/HeaderMeta';

import Storage from '../helpers/Storage';

import '../sass/components/AboutView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  webpSupported: store.setup.webpSupported,
  setup: store.setup,
});

class AboutView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webpSupported: true,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.setState({ webpSupported: Storage.getSetup('webpSupported') });
    }
  };

  render = () => {
    return (
      <div
        className={classnames('aboutView background', {
          webp: this.state.webpSupported,
        })}
      >
        <HeaderMeta />
        <div className="content">
          <div className="row-1 section">
            <div className="column-1 sub-section">
              <div className="content-header">About The SDG Co</div>
              <p>
                <span className="p-bold">The SDG Co</span> is the world’s
                leading sustainability innovation marketplace that connects
                start-ups, entrepreneurs, technology providers and investors
                working on the United Nations’ Sustainable Development Goals
                (SDGs).
              </p>
              <p>
                It draws inspiration from the SDGs, adopted by all United
                Nations Member States in 2015 as a universal call to action to
                end poverty, protect people and the planet. The ‘Co’ in our name
                represents our common humanity and collaboration.
              </p>
              <p>
                The SDG Co is an ecosystem that supports sustainability
                innovations in reaching global markets and achieving scale. This
                is increasingly recognized as key to achieving national and
                global targets to address complex challenges facing humanity
                arising from issues such as climate change, resource scarcity,
                pollution, rising inequality, and urbanization.
              </p>
            </div>
            <div className="column-2 sub-section">
              <div className="sub-section-image-1 background"></div>
            </div>
          </div>

          <div className="row-1  section">
            <div className="column-2 sub-section">
              <div className="sub-section-image-2 background"></div>
            </div>
            <div className="column-1 sub-section">
              <p>
                While sustainability efforts are growing across the world, there
                is a need to consolidate efforts and strengthen the global
                sustainability innovation ecosystem.
              </p>
              <p>
                We are a technology-driven platform and community that will help
                drive these efforts and accelerate our ambition to be climate
                neutral by the second half of the century. 
              </p>
              <p>
                Our platform will also provide a shared community space where
                people and businesses can network, exchange ideas, and build
                professional and personal skills and capabilities.
              </p>
              <p>
                The SDG Co is an Eco-Business initiative, supported by partner
                organisations from across the globe.
              </p>
            </div>
          </div>
          <div className="section ">
            <div className="diveder-line"></div>
          </div>
          <div className="row-1 section">
            <div className="column-1 sub-section">
              <p className="content-header">IxSA</p>
              <p>
                The SDG Co is a founding member of the{' '}
                <span>Innovation x Sustainability Alliance (IxSA)</span>. This
                alliance has created the world’s first sustainability innovation
                manifesto which aims to provide a North Star for all
                sustainability start-ups.
              </p>
              <p>
                The manifesto — styled after the Agile Manifesto that guides
                software development — outlines six principles we believe play
                elevated roles in the realm of sustainability.
              </p>
              <p>
                The SDG Co believes this will trigger change in the
                sustainability ecosystem from which various frameworks can be
                built on, and which will guide sustainability start-ups to
                success.
              </p>
              <p>
                The SDG Co is an ecosystem that supports sustainability
                innovations in reaching global markets and achieving scale. This
                is increasingly recognized as key to achieving national and
                global targets to address complex challenges facing humanity
                arising from issues such as climate change, resource scarcity,
                pollution, rising inequality, and urbanization.
              </p>
            </div>
            <div className=" column-2 sub-section">
              <div className="sub-section-image-3 background"></div>
            </div>
          </div>
          <div className="row-1 section">
            <div className="column-3 sub-section">
              <p className="content-header">
                Innovation x Sustainability Manifesto
              </p>
              <p>
                We are a group of practitioners working in the intersection of
                innovation and sustainability. Through our work, we have come to
                realise that the prevailing methods for innovation aren’t well
                suited for sustainability.
              </p>
              <p>
                We found ourselves modifying our accelerators, incubators,
                joint-ventures, and investments in a quest for new ways to
                maximise sustainability impact.
              </p>
              <p>
                Rather than doing it separately, we have come together and
                forged a manifesto. The following mantras underpin our guiding
                principles:
              </p>
              <p>
                <a href="https://www.ixsa.earth/" target="_blank">
                  <span>Find More About the Manifesto Here</span>{' '}
                  <span className="span-ml"> {'  >'}</span>
                </a>
              </p>
            </div>
            <div className="column-4 sub-section">
              <p className="p-1">
                <span className="span-1">1</span> Intentionality validated by
                measurable and transparent accountability.
              </p>
              <p className="p-1">
                <span className="span-1">2</span> There is no one size fits all
                approach.
              </p>
              <p className="p-1">
                <span className="span-1">3</span> Diversity and inclusion
                extends beyond people.
              </p>
              <p className="p-1">
                <span className="span-1">4</span> Symbiotic network effects are
                exponential.
              </p>
              <p className="p-1">
                <span className="span-1">5</span> Mental wellness is the
                backbone for sustained impact.
              </p>
              <p className="p-1">
                <span className="span-1">6</span> Doing good must be rewarded
                better than doing bad.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(AboutView));
