import React from 'react';

import GoalCard from '../common/GoalCard';

import { sendRequest } from '../../helpers/RequestDispatcher.js';

import '../../sass/components/input/GoalsSelect.scss';

export default class GoalsSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      goals: []
    };
  }

  componentDidMount = () => {
    sendRequest({
      type: 'GET',
      method: 'common_data/goals',
      success: (data) => {
        if (data) {
          this.setState({goals: data});
        }
      },
      error: (data) => {
      }
    });
  }

  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  handleSelectGoal = (item) => {
    const value = this.props.value || [];
    const selected = value.includes(item.id);
    this.onChange(selected ? value.filter(i => i !== item.id) : [...value, item.id]);
  }

  render = () => {
    const value = this.props.value || [];
    return (
      <div className='goalsSelect'>
        <div className='goalsTable'>
          {this.state.goals.map(item => <GoalCard key={item.id} item={item} selected={value.includes(item.id)} onClick={this.handleSelectGoal} selectable />)}
        </div>
      </div>
    )
  }

}
