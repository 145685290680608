import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { VscOrganization } from 'react-icons/vsc';
import { AiOutlineUser } from 'react-icons/ai';

import Storage from '../helpers/Storage';
import { triggerEvent } from '../helpers/global';

import '../sass/components/SwitchProfileView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
  isMobile: store.setup.isMobile,
});

class SwitchProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [props.user].concat(props.user.business_profiles) || [],
      selected: props.businessProfile ? props.businessProfile.id : props.user.id,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.user && (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user))) {
      this.setState({
        items: [this.props.user].concat(this.props.user.business_profiles) || []
      });
    }
  }

  onSwitch = (item, isSelected = false) => {
    if (isSelected) return;
    this.setState({selected: item.id},
      () => {
        // hide switch account pop-up
        triggerEvent('hideContentPopup');
        // only business profiles has user_id
        if (item.user_id) {
          Storage.setData('businessProfile', item);
        } else {
          Storage.setData('businessProfile', null);
        }

        const { location, user, businessProfile } = this.props;
        if (location.pathname.indexOf('/chats') > -1) {
          this.props.history.push('/chats');
        } else if ([`/user/${user.id}/edit`, `/user/${user.slug}/edit`].includes(location.pathname) && item.user_id) {
          // from user edit page
          if (item.user_id !== user.id) {
            // user not owner for company account
            this.props.history.push('/');
          } else {
            // user is owner for company account
            this.props.history.push(`/business/${item.id}/edit`);
          }
        } else if (location.pathname === `/business/${businessProfile?.id}/edit`) {
          // from company account edit page
          if (item.user_id && item.user_id !== user.id) {
            // user not owner for company account
            this.props.history.push('/');
          } else if (item.user_id && item.user_id === user.id) {
            // user is owner for company account
            this.props.history.push(`/business/${item.id}/edit`);
          } else {
            // user edit page
            this.props.history.push(`/user/${item.slug || item.id}/edit`);
          }
        } else if (location.pathname === '/profile' && item.user_id) {
          // from user profile to company profile
          this.props.history.push(`/business/${item.id}`);
        } else if (location.pathname === `/business/${businessProfile?.id}`) {
          // from company profile
          if (item.user_id) {
            // to company profile
            this.props.history.push(`/business/${item.id}`);
          } else {
            // to user profile
            this.props.history.push('/profile');
          }
        }
    });
  }

  conditionType = (item) => {
    const { businessProfile, user } = this.props;
    return businessProfile ? businessProfile.id === item.id : user?.id === item.id;
  }

  renderItem = (item) => {
    const isSelected = this.conditionType(item);
    return (
      <div
        key={item.id}
        className='item'
        onClick={() => this.onSwitch(item, isSelected)}
      >
        {item.image
          ? <div className='avatar' style={{backgroundImage: `url(${item.image})`}}/>
          : <div className='avatar'>{item.user_id ? <VscOrganization size='40' /> : <AiOutlineUser size='40' />}</div>
        }
        <div>{item.name}</div>
        {item.subscribed ? <div className='subscribed' /> : null}
        {isSelected ? <div className='selected' /> : null}
      </div>
    )
  }

  render = () => {
    const items = this.state.items;
    return (
      <div className='switchContent'>
        <div className='itemsList'>
          {items.map(item => this.renderItem(item))}
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(SwitchProfileView));
