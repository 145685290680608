import classnames from 'classnames';
import React from 'react';
import '../../sass/components/input/TextInput.scss';

export default class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.type || 'text',
      toggleState: false,
    };
    this.input = null;
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  };

  handleKeyDown = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  componentDidMount = () => {};

  toggleVisibility = () => {
    this.setState((prevState, props) => {
      return {
        type: prevState.type === 'password' ? 'text' : 'password',
        toggleState: !prevState.toggleState,
      };
    });
  };

  renderTextInput = () => {
    let value = this.props.value;
    const { type, toggleState } = this.state;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    return (
      <>
        <input
          type={type}
          value={value}
          name={this.props.name}
          autoComplete="new-password"
          onChange={this.handleChange}
          readOnly={this.props.disabled}
          onFocus={() => {
            if (this.props.onFocus) this.props.onFocus();
            this.setState({ focus: true });
          }}
          onBlur={() => {
            if (this.props.onBlur) this.props.onBlur();
            this.setState({ focus: false });
          }}
          className="form-control"
          placeholder={this.props.placeholder}
          ref={(input) => {
            this.input = input;
            if (this.props.inputRef) {
              this.props.inputRef(this.input);
            }
          }}
          disabled={this.props.disabled}
        />
        {this.props.type === 'password' && (
          <span
            className={classnames({
              toggleVisibility: true,
              active: toggleState,
            })}
            onClick={this.toggleVisibility}
          />
        )}
      </>
    );
  };

  renderTextareaInput = () => {
    let value = this.props.value;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    return (
      <textarea
        value={value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        className="form-control"
        placeholder={this.props.placeholder}
        ref={(input) => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
        disabled={this.props.disabled}
        style={{
          height: this.props.height || null,
        }}
        onKeyDown={this.props.growable && this.handleKeyDown}
      />
    );
  };

  render = () => {
    const type = this.props.type || 'text';
    const error = this.props.error || null;
    return (
      <div
        className={classnames({
          textInput: true,
          simplified: this.props.simplified,
        })}
      >
        {['text', 'number', 'password', 'email', 'phone', 'tel'].indexOf(type) >
        -1
          ? this.renderTextInput()
          : null}
        {type === 'textarea' ? this.renderTextareaInput() : null}
        {error && <div className="inputError">{error}</div>}
      </div>
    );
  };
}
