import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import classnames from 'classnames';
import Carousel, { currentBreakPoint } from 'react-elastic-carousel';

import { sendRequest } from '../helpers/RequestDispatcher.js';
import { httpToUrl } from '../helpers/global';

import ProfileCard from './common/ProfileCard';
import TargetActivityCard from './common/TargetActivityCard';
import GoalCard from './common/GoalCard';
import Avatar from './common/Avatar';
import ContentPlaceholder from './common/ContentPlaceholder';
import HeaderMeta from './common/HeaderMeta.js';
import { ReactComponent as ChevronRightSVG } from './../images/icons/chevron-right.svg';

import '../sass/components/BrowseView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  isMobile: store.setup.isMobile,
});

const INIT_DATA = [
  {
    url: 'posts',
    object: 'posts',
    data: { trending: true },
    callback: (ctx, data) => ctx.afterPostsRequest(data),
  },
  {
    url: 'users',
    object: 'users',
    data: { limit: 4, order: 'trending' },
    callback: (ctx, data) => ctx.afterUsersRequest(data),
  },
  { url: 'common_data/goals', object: 'goals' },
  { url: 'common_data/objectives', object: 'objectives' },
];
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1, itemsToScroll: 1 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 3 },
];
class BrowseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      users: [],
      posts: [],
      goals: [],
      objectives: [],
      usersLoaded: false,
      postsLoaded: false,
      adsTagLoaded: false,
    };
  }

  componentDidMount = () => {
    INIT_DATA.forEach((item) =>
      this.getData(item.url, item.object, item.data, item.callback)
    );
    this.loadGPTag(this.initGPTag());
  };

  getData = (url, object, data = null, callback = null) => {
    sendRequest({
      method: url,
      data,
      type: 'GET',
      success: (data) => {
        this.setState({ [object]: data });
        if (callback) {
          callback(this, data);
        }
      },
      error: (data) => {},
    });
  };

  loadGPTag = (callback) => {
    const existingScript = document.getElementById('gpt');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.id = 'gpt';
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };

  initGPTag = () => {
    const existingScript = document.getElementById('gptInit');
    if (!existingScript) {
      const script = document.createElement('script');
      script.id = 'gptInit';
      script.text = `
        window.googletag = window.googletag || {cmd: []};
        let slot1, slot2, slot3, slot4, slot5, slot6;
        googletag.cmd.push(function() {
          slot1 = googletag.defineSlot('/16222413/SCO-L1', [728, 90], 'div-gpt-ad-1626942035534-0').addService(googletag.pubads());
          slot2 = googletag.defineSlot('/16222413/SCO-L1-MOBILE', [320, 50], 'div-gpt-ad-1626942200946-0').addService(googletag.pubads());
          slot3 = googletag.defineSlot('/16222413/SCO-L2', [728, 90], 'div-gpt-ad-1626951971479-0').addService(googletag.pubads());
          slot4 = googletag.defineSlot('/16222413/SCO-L2-MOBILE', [320, 50], 'div-gpt-ad-1626952078095-0').addService(googletag.pubads());
          slot5 = googletag.defineSlot('/16222413/SCO-R1', [300, 250], 'div-gpt-ad-1626942351037-0').addService(googletag.pubads());
          slot6 = googletag.defineSlot('/16222413/SCO-R2', [300, 250], 'div-gpt-ad-1626942431201-0').addService(googletag.pubads());
          googletag.pubads().enableSingleRequest();
          googletag.pubads().collapseEmptyDivs();
          googletag.enableServices();
          googletag.pubads().refresh([slot1, slot2, slot3, slot4, slot5, slot6]);
        });
      `;
      document.body.appendChild(script);
      this.setState({ adsTagLoaded: true });
    } else {
      const script = document.createElement('script');
      script.id = 'gptRefresh';
      script.text = `
        window.googletag = window.googletag || {cmd: []};
        googletag.cmd.push(function() {
          googletag.pubads().refresh([slot1, slot2, slot3, slot4]);
        });
      `;
      document.body.appendChild(script);
      this.setState({ adsTagLoaded: true });
    }
  };

  afterPostsRequest = () => {
    this.setState({ postsLoaded: true });
  };

  afterUsersRequest = () => {
    this.setState({ usersLoaded: true });
  };

  handleSelectTargetActivity = (key) => {
    if (['market-maps'].includes(key)) {
      this.props.history.push(`/${key}`);
    } else {
      this.props.history.push(`/partner/${key}`);
    }
  };

  handleProfileCardClick = (item) => {
    const identifier = item.slug || item.id;
    this.props.history.push(`/user/${identifier}`);
  };

  handlePostAuthorClick = (item) => {
    const identifier = item.slug || item.id;
    this.props.history.push(`/user/${identifier}`);
  };

  renderPostCategories = (goalIds) => {
    return this.state.goals.length > 0 ? (
      goalIds.map((id, index) => {
        const { key } = this.state.goals.find((i) => i.id === id);
        return (
          <Link key={id} to={`/organization-profiles?goal=${key}`}>
            <span>
              {index ? ', ' : ''}
              {this.state.goals.find((i) => i.id === id).name}
            </span>
          </Link>
        );
      })
    ) : (
      <span />
    );
  };

  renderTrendingPost = (item) => {
    return (
      <div className="trendingPost" key={item.id}>
        <div className="postCategory">
          Posted on {this.renderPostCategories(item.goal_ids)}
        </div>
        <div className="authorMeta">
          <Avatar
            imageUrl={item.author.image_url}
            username={item.author.name}
            small
            onClick={() => this.handlePostAuthorClick(item.author)}
          />
          <div className="authorNameTime">
            <div
              className="authorName"
              onClick={() => this.handlePostAuthorClick(item.author)}
            >
              {item.author.name}
              {/* {item.author_expert ? <div className="subscribed" /> : null} */}
            </div>
            <div className="postDate">
              {dayjs.unix(item.created_at).fromNow()}
            </div>
          </div>
        </div>
        {item.author_title ? (
          <div className="postComment">{item.author_title}</div>
        ) : null}
        <div
          className="postDetails"
          onClick={() =>
            item.link && window.open(httpToUrl(item.link), '_blank')
          }
        >
          {item.image_url ? (
            <picture>
              <img src={item.image_url} alt="Post" />
            </picture>
          ) : null}
          {item.title ? <div className="postTitle">{item.title}</div> : null}
          {item.content ? (
            <div className="postSubtitle">{item.content}</div>
          ) : null}
          {item.link ? <div className="postLink">{item.link}</div> : null}
        </div>
        <div className="postMeta">
          <div className="postLikesCount">
            {item.likes_count} Like{item.likes_count > 1 ? 's' : ''}
          </div>
          <div className="postCommentsCount">{item.comments_count}</div>
        </div>
      </div>
    );
  };

  renderActiveUsers = () => {
    const { users } = this.state;
    return (
      <div className="activeUsersContainer">
        {/* TODO: show business profiles as well */}
        {users.map((item) => (
          <ProfileCard
            key={item.id}
            type="individuals"
            {...item}
            onClick={() => this.handleProfileCardClick(item)}
          />
        ))}
      </div>
    );
  };

  render = () => {
    const { posts, goals, objectives } = this.state;

    return (
      <div className="browseView">
        <HeaderMeta />
        <section className="sdgGoals">
          <div className="content-header">Browse</div>
          <p>Browse Profiles, Content and News based on UN SDGs</p>
          <div className="all-sdg-div">
            <Link
              to="/organization-profiles?goal=all-sdgs"
              className="button button-dark-gray"
            >
              See All SDGs
              <ChevronRightSVG />
            </Link>
          </div>
          <div className="sdgGoalsContainer">
            {goals.map((item) =>
              item.key == 'all-sdgs' ||
              item.key == 'other_sustainability_solutions' ? (
                ''
              ) : item.key == 'other-sustainability-solutions' ? (
                ''
              ) : (
                <Link
                  key={item.id}
                  to={`/organization-profiles?goal=${item.key}`}
                >
                  <GoalCard item={item} showFollowers />
                </Link>
              )
            )}
          </div>
          <div className="other-stain-div">
            <Link
              to="/organization-profiles?goal=other-sustainability-solutions"
              className="button button-dark-gray"
            >
              Other Sustainability Solutions
              <ChevronRightSVG />
            </Link>
          </div>
        </section>

        {this.state.adsTagLoaded ? (
          <div
            className={classnames('banner', {
              mobileBanner: this.props.isMobile,
            })}
          >
            {this.props.isMobile ? (
              <div
                id="div-gpt-ad-1626942200946-0"
                style={{ minWidth: '320px', minHeight: '50px' }}
              >
                <script
                  dangerouslySetInnerHTML={{
                    __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626942200946-0'); });`,
                  }}
                />
              </div>
            ) : (
              <div
                id="div-gpt-ad-1626942035534-0"
                style={{ minWidth: '728px', minHeight: '90px' }}
              >
                <script
                  dangerouslySetInnerHTML={{
                    __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626942035534-0'); });`,
                  }}
                />
              </div>
            )}
          </div>
        ) : null}

        <section className="targetActivities">
          <div className="content-header">How can we support you?</div>
          <p>Get started</p>
          <div className="targetActivitiesContainer">
            {objectives.map((item) => (
              <TargetActivityCard
                key={item.id}
                item={item}
                onClick={() => this.handleSelectTargetActivity(item.key)}
                link
              />
            ))}
          </div>
        </section>

        {this.state.adsTagLoaded ? (
          <div
            className={classnames('banner', {
              mobileBanner: this.props.isMobile,
            })}
          >
            {this.props.isMobile ? (
              <div
                id="div-gpt-ad-1626942200946-0"
                style={{ minWidth: '320px', minHeight: '50px' }}
              >
                <script
                  dangerouslySetInnerHTML={{
                    __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626942200946-0'); });`,
                  }}
                />
              </div>
            ) : (
              <div
                id="div-gpt-ad-1626942035534-0"
                style={{ minWidth: '728px', minHeight: '90px' }}
              >
                <script
                  dangerouslySetInnerHTML={{
                    __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626942035534-0'); });`,
                  }}
                />
              </div>
            )}
          </div>
        ) : null}

        {/*<section className="trendingPosts">*/}
        {/*  <div className="content-header">Trending Posts</div>*/}
        {/*  <p>What people are talking about</p>*/}
        {/*  <div className="trendingPostsContainer">*/}
        {/*    <Carousel*/}
        {/*      itemsToShow={1}*/}
        {/*      itemPadding={[5, 10]}*/}
        {/*      outerSpacing={60}*/}
        {/*      ref={(ref) => (this.carousel = ref)}*/}
        {/*      breakPoints={breakPoints}*/}
        {/*      onResize={(currentBreakPoint) => console.log(currentBreakPoint)}*/}
        {/*    >*/}
        {/*      {this.state.postsLoaded*/}
        {/*        ? posts.map((item) => this.renderTrendingPost(item))*/}
        {/*        : [...Array(3)].map((e, i) => (*/}
        {/*            <div key={i} className="postContentPlaceholder">*/}
        {/*              <ContentPlaceholder type="post" />*/}
        {/*            </div>*/}
        {/*          ))}*/}
        {/*    </Carousel>*/}
        {/*  </div>*/}
        {/*  <div className="button-div">*/}
        {/*    <a*/}
        {/*      className="btn-prev-slider"*/}
        {/*      onClick={() => this.carousel.slidePrev()}*/}
        {/*    >*/}
        {/*      {' '}*/}
        {/*      {`< Prev`}*/}
        {/*    </a>*/}
        {/*    <a*/}
        {/*      className="btn-next-slider"*/}
        {/*      onClick={() => this.carousel.slideNext()}*/}
        {/*    >{`Next >`}</a>*/}
        {/*  </div>*/}
        {/*</section>*/}

        {this.state.adsTagLoaded ? (
          <div
            className={classnames('banner', 'bottomBanner', {
              mobileBanner: this.props.isMobile,
            })}
          >
            {this.props.isMobile ? (
              <div
                id="div-gpt-ad-1626952078095-0"
                style={{ minWidth: '320px', minHeight: '50px' }}
              >
                <script
                  dangerouslySetInnerHTML={{
                    __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626952078095-0'); });`,
                  }}
                />
              </div>
            ) : (
              <div
                id="div-gpt-ad-1626951971479-0"
                style={{ minWidth: '728px', minHeight: '90px' }}
              >
                <script
                  dangerouslySetInnerHTML={{
                    __html: `googletag.cmd.push(function() { googletag.display('div-gpt-ad-1626951971479-0'); });`,
                  }}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(BrowseView));
