import React from 'react';
import classnames from 'classnames';

import Storage from '../helpers/Storage';

import '../sass/components/JotForm.scss';

class JotForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webpSupported: true,
    };
  }

  render = () => {
    return (
      <div
        className={classnames('aboutView  ', {
          webp: this.state.webpSupported,
        })}
      >
    
        <iframe
      id="JotFormIFrame-213393170325046"
      title="Liveability Challenge Form"
      onLoad={window.parent.scrollTo(0,0)}
      allowTransparency={true}
      allowFullScreen={true}
      height={100}
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/213393170325046"
      frameBorder="0"
      style={{ width: '100%',
      maxWidth: '100%',
      height: '2600px',
      border:'none',
      }}
    >
    </iframe>
    <div className='tlcFooterStyle'>
       You can also continue filling this form on a later date by saving it as a draft and inputting your email address.
    </div>
          </div>
    );
  };
}

export default JotForm;
