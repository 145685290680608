import { ErrorMessage, Field } from 'formik';
import { useState } from 'react';
import '../../sass/components/input/ExpertWorkExperienceInputSection.scss';

const ExpertBusinessExperienceInputSection = ({
  onDelete,
  value,
  index,
  name,
}) => {
  const [isExpanded, setExpanded] = useState(true);
  ///
  const onToggleExpand = () => {
    setExpanded(!isExpanded);
  };
  ///
  return (
    <div className="expert-detail-input-form">
      <div className="input-group">
        <label className="input-label">Business Expertise</label>
        <Field
          className="text-input"
          name={`${name}.business_expertise`}
          as="select"
        >
          <option value="">Select Business Expertise</option>
          <option value="Business Technology">Business Expertise</option>
          <option value="Product Development">Product Development</option>
        </Field>
        <ErrorMessage
          className="error-message"
          name={`${name}.business_expertise`}
          component="div"
        />
        <br />
        {!isExpanded ? (
          ''
        ) : (
          <div className="expanded-section">
            <label className="input-label">Companies or Organizations</label>
            <Field className="text-input" name={`${name}.company`} />
            <ErrorMessage
              component={'div'}
              name={`${name}.company`}
              className="error-message"
            />
            <br />
            <label className="input-label">Countries or Regions</label>
            <Field className="text-input" name={`${name}.countries_regions`} />
            <ErrorMessage
              component={'div'}
              name={`${name}.countries_regions`}
              className="error-message"
            />
            <br />
            <label className="input-label">Details</label>
            <Field
              className="text-input"
              name={`${name}.details`}
              as="textarea"
              placeholder="More details on this business expertise (E.g. Highly experienced in implementing scalable sales framework and introducing new revenue ideas.)"
              rows="5"
            />
            <ErrorMessage
              component={'div'}
              name={`${name}.details`}
              className="error-message"
            />
            <br />
          </div>
        )}
        <span className="expand-toggle-btn" onClick={() => onToggleExpand()}>
          {isExpanded ? `Shrink Box` : `Expand Box`}
        </span>
      </div>

      <button type="button" onClick={() => onDelete()} className="delete-btn">
        <svg
          width="26"
          height="29"
          viewBox="0 0 26 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99972 10.7669C8.26494 10.7669 8.51929 10.8652 8.70683 11.0403C8.89436 11.2153 8.99972 11.4527 8.99972 11.7002V22.9002C8.99972 23.1478 8.89436 23.3852 8.70683 23.5602C8.51929 23.7352 8.26494 23.8336 7.99972 23.8336C7.7345 23.8336 7.48015 23.7352 7.29261 23.5602C7.10508 23.3852 6.99972 23.1478 6.99972 22.9002V11.7002C6.99972 11.4527 7.10508 11.2153 7.29261 11.0403C7.48015 10.8652 7.7345 10.7669 7.99972 10.7669ZM12.9997 10.7669C13.2649 10.7669 13.5193 10.8652 13.7068 11.0403C13.8944 11.2153 13.9997 11.4527 13.9997 11.7002V22.9002C13.9997 23.1478 13.8944 23.3852 13.7068 23.5602C13.5193 23.7352 13.2649 23.8336 12.9997 23.8336C12.7345 23.8336 12.4801 23.7352 12.2926 23.5602C12.1051 23.3852 11.9997 23.1478 11.9997 22.9002V11.7002C11.9997 11.4527 12.1051 11.2153 12.2926 11.0403C12.4801 10.8652 12.7345 10.7669 12.9997 10.7669ZM18.9997 11.7002C18.9997 11.4527 18.8944 11.2153 18.7068 11.0403C18.5193 10.8652 18.2649 10.7669 17.9997 10.7669C17.7345 10.7669 17.4801 10.8652 17.2926 11.0403C17.1051 11.2153 16.9997 11.4527 16.9997 11.7002V22.9002C16.9997 23.1478 17.1051 23.3852 17.2926 23.5602C17.4801 23.7352 17.7345 23.8336 17.9997 23.8336C18.2649 23.8336 18.5193 23.7352 18.7068 23.5602C18.8944 23.3852 18.9997 23.1478 18.9997 22.9002V11.7002Z"
            fill="currentColor"
            fillOpacity="0.8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 6.1C26 6.59507 25.7893 7.06987 25.4142 7.41993C25.0391 7.77 24.5304 7.96667 24 7.96667H23V24.7667C23 25.7568 22.5786 26.7064 21.8284 27.4065C21.0783 28.1067 20.0609 28.5 19 28.5H7C5.93913 28.5 4.92172 28.1067 4.17157 27.4065C3.42143 26.7064 3 25.7568 3 24.7667V7.96667H2C1.46957 7.96667 0.960859 7.77 0.585786 7.41993C0.210714 7.06987 0 6.59507 0 6.1V4.23333C0 3.73826 0.210714 3.26347 0.585786 2.9134C0.960859 2.56333 1.46957 2.36667 2 2.36667H9C9 1.8716 9.21071 1.3968 9.58579 1.04673C9.96086 0.696666 10.4696 0.5 11 0.5H15C15.5304 0.5 16.0391 0.696666 16.4142 1.04673C16.7893 1.3968 17 1.8716 17 2.36667H24C24.5304 2.36667 25.0391 2.56333 25.4142 2.9134C25.7893 3.26347 26 3.73826 26 4.23333V6.1ZM5.236 7.96667L5 8.0768V24.7667C5 25.2617 5.21071 25.7365 5.58579 26.0866C5.96086 26.4367 6.46957 26.6333 7 26.6333H19C19.5304 26.6333 20.0391 26.4367 20.4142 26.0866C20.7893 25.7365 21 25.2617 21 24.7667V8.0768L20.764 7.96667H5.236ZM2 6.1V4.23333H24V6.1H2Z"
            fill="currentColor"
            fillOpacity="0.8"
          />
        </svg>
      </button>
    </div>
  );
};

export default ExpertBusinessExperienceInputSection;
