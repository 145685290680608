import React from 'react';
import classnames from 'classnames';

import '../../sass/components/input/SearchInput.scss';

export default class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  };

  handleClear = (e) => {
    this.refs.form.value = '';
  };

  render = () => {
    const value = this.props.value || [];
    return (
      <div className="flex-row">
        <div className="flex-column">{this.props.label || 'Search'}</div>
        <div
          className={classnames(
            'searchInput flex-column-2',
            {
              bordered: this.props.bordered,
              shadowed: this.props.shadowed,
              transparent: this.props.transparent,
            },
            this.props.className
          )}
        >
          <input
            type="text"
            id="search-text-clear"
            autoComplete="new-password"
            placeholder={this.props.placeholder || 'Search'}
            value={value}
            ref="form"
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  };
}
