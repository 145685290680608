import { isEmpty } from 'ramda';

import { remoteFileToDataUrl } from './file_helper';

export const keySeparator = ':::';
export const modelSeparator = '|';
export const othersModelSeparator = '::';

export default (companies) => {
  const formattedCompanies = {};
  companies
    .filter(
      (value) =>
        !(value.value_chain_id === null || value.value_chain_type_id === null)
    )
    .sort((a, b) => a.value_chain_id - b.value_chain_id)
    .map((value) => {
      const {
        value_chain_name,
        value_chain_id,
        value_chain_type_name,
        value_chain_type_id,
      } = value;
      const chainText = `${value_chain_name}${keySeparator}${value_chain_id}`;
      const chainTypeText = `${value_chain_type_name}${keySeparator}${value_chain_type_id}`;
      if (!formattedCompanies[chainText]) {
        formattedCompanies[chainText] = {};
      }

      if (!formattedCompanies[chainText][chainTypeText]) {
        formattedCompanies[chainText][chainTypeText] = [];
      }

      formattedCompanies[chainText][chainTypeText].push({
        ...value,
        isLogoBase64: false,
      });
    });

  Object.keys(formattedCompanies).forEach((valueChain) => {
    Object.keys(formattedCompanies[valueChain]).forEach(
      (valueChainCategory) => {
        formattedCompanies[valueChain][valueChainCategory].sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          if (aName < bName) return -1;
          if (aName > bName) return 1;
          return 0;
        });
      }
    );
  });

  return formattedCompanies;
};

export const loadCompaniesBase64Logos = async ({ companies, setter }) => {
  if (!isEmpty(companies)) {
    const newCompanies = { ...companies };

    for (const position in companies) {
      for (const category in companies[position]) {
        for (let i = 0; i < companies[position][category].length; i++) {
          const company = companies[position][category][i];
          if (company.logo) {
            const base64String = await remoteFileToDataUrl(company.logo);
            if (base64String) {
              newCompanies[position][category][i].logo = base64String;
            }
          }
        }
      }
    }

    setter(newCompanies);
  }
};
