import React from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory, Redirect } from 'react-router-dom';
import update from 'immutability-helper';

import FeedHeader from '../feed/FeedHeader';
import Post from '../feed/Post';
import Sidebar from '../feed/Sidebar';
import PostForm from '../feed/PostForm';
import PostFormPopup from '../common/PostFormPopup';
import HeaderMeta from '../common/HeaderMeta';

import ContentPlaceholder from '../common/ContentPlaceholder';

import { triggerEvent } from '../../helpers/global.js';
import { sendRequest } from '../../helpers/RequestDispatcher.js';
import '../../sass/components/SearchesView.scss';
import { feed } from '../../data/feed_data';

import '../../sass/components/feed/FeedView.scss';
import * as qs from 'query-string';
import ProfileCard from '../common/ProfileCard';
import SelectButton from '../common/SelectButton';
import SelectInput from '../input/SelectInput';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

const PROFILES_PER_LOAD = 20;
const POSTS_PER_LOAD = 2;
const PROFILE_TYPES = [
  {
    id: 'organizations',
    key: 'organizations',
    name: 'Organisations',
  },
  {
    id: 'individuals',
    key: 'individuals',
    name: 'Individuals',
  },
  {
    id: 'content',
    key: 'content',
    name: 'Content',
  },
];
const PROFILE_SUB_TYPES = [
  {
    id: 'Investor',
    key: 'Investor',
    name: 'Investor',
  },
  {
    id: 'Startup',
    key: 'Startup',
    name: 'Startup',
  },

  {
    id: 'Corporate',
    key: 'Corporate',
    name: 'Corporate',
  },
  {
    id: 'NGO',
    key: 'NGO',
    name: 'NGO',
  },
  {
    id: 'Academia',
    key: 'Academia',
    name: 'Academia',
  },
  {
    id: 'Government',
    key: 'Government',
    name: 'Government',
  },
  {
    id: 'Accelerator',
    key: 'Accelerator',
    name: 'Accelerator',
  },
  {
    id: 'Consultant',
    key: 'Consultant',
    name: 'Consultant',
  },
  {
    id: 'Others',
    key: 'Others',
    name: 'Others',
  },
];
const INIT_DATA = [
  { url: 'common_data/countries', object: 'countries' },
  {
    url: 'common_data/objectives',
    object: 'objectives',
  },
  {
    url: 'common_data/subcategories',
    object: 'subcategories',
  },
];

class OrganisationSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feed: {},
      goals: [],
      selectedGoal: {},
      posts: [],
      profiles: [],
      postsLoadNumber: 1,
      profilesLoadNumber: 1,
      noMorePosts: false,
      noMoreProfiles: false,
      loaded: false,
      search: this.defaultData(),
      filters: this.defaultData(),
      data: {
        authorTitle: '',
        postId: null,
        file: null,
        content: '',
        title: '',
        link: '',
      },
    };
  }

  componentDidMount = () => {
    INIT_DATA.forEach((el) =>
      this.getData(el.url, el.object, el.data, el.noLoad, el.callback)
    );
    sendRequest({
      method: 'common_data/goals',
      type: 'GET',
      success: (data) => {
        this.setState({ goals: data });
      },
      error: (data) => {},
    });

    this.fetchData();
  };
  getData = (url, object, data = null, noLoad = false, callback = null) => {
    sendRequest({
      method: url,
      type: 'GET',
      data: data,
      noLoad: noLoad,
      success: (data) => {
        this.setState({ [object]: data || [] });
        if (callback) {
          callback(this, data);
        }
      },
      error: (data) => {},
    });
  };

  defaultData = () => {
    const parsed = qs.parse(location.search);

    return {
      country: parsed.country || null,
      goal: parsed.goal || null,
      objective: parsed.objective || null,
      subcategory: parsed.subcategory || null,
      profile_type: parsed.profile_type || null,
      order: parsed.order || 'name',
      profile: 'organizations',
    };
  };

  handleDataChange = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } });
  };

  handleCreatedPost = (data) => {
    const { key } = this.props.match.params;
    const goalId = this.state.goals.find((i) => i.key === key)?.id;
    if (data && data.goal_ids.includes(Number(goalId))) {
      this.setState((prevState) => ({
        posts: [data, ...this.state.posts],
      }));
    }
  };

  handlePostUpdate = (data) => {
    const postIds = this.state.posts.map((post) => post.id);
    if (data) {
      this.setState({
        posts: update(this.state.posts, {
          [postIds.indexOf(data.id)]: { $set: data },
        }),
      });
    }
  };

  handleEditClick = (post) => {
    this.setState({
      showPopupForm: true,
      data: {
        authorTitle: post.author_title,
        file: post.image_url,
        postId: post.id,
        goalIds: post.goal_ids,
        content: post.content,
        title: post.title,
        link: post.link,
      },
    });
  };

  handleDeleteClick = (post) => {
    triggerEvent('showConfirmation', [
      {
        title: 'Are you sure you want to remove this post?',
        overlayBlock: true,
        callback: (e) => this.removePost(e, post.id),
      },
    ]);
  };

  postFormPopupCallback = (data) => {
    const postIds = this.state.posts.map((post) => post.id);
    if (data && postIds.includes(data.id)) {
      this.handlePostUpdate(data);
    } else if (data) {
      this.handleCreatedPost(data);
    }
    this.setState({
      showPopupForm: false,
    });
  };

  removePost = (e, id) => {
    if (!e) return;
    let method = `posts/${id}`;
    let author_id = this.props.user?.id;
    if (this.props.businessProfile) {
      author_id = this.props.businessProfile.id;
      method = `business_profiles_managing/${author_id}/posts/${id}`;
    }
    sendRequest({
      type: 'DELETE',
      method: method,
      success: (data) => {
        triggerEvent('showSnackbar', [
          { text: 'Post deleted successfully', type: 'success' },
        ]);
        this.setState({
          posts: this.state.posts.filter((p) => p.id !== id),
        });
      },
      error: (data) => {},
    });
  };

  renderPosts = () => {
    return (
      <>
        {this.state.posts &&
          this.state.posts.map((post) => (
            <Post
              key={post.id}
              post={post}
              user={this.props.user}
              author={post.author}
              likeCount={post.likes_count}
              publishDateTime={post.created_at}
              article={post.author_title}
              isLiked={post.liked}
              onEditClick={() => this.handleEditClick(post)}
              onDeleteClick={() => this.handleDeleteClick(post)}
            />
          ))}
        {!!this.state.posts && this.state.noMorePosts ? null : (
          <div className="loadMore" onClick={this.loadMorePosts}>
            <p>Load more posts...</p>
          </div>
        )}
      </>
    );
  };

  loadMorePosts = () => {
    this.fetchData(false, true);
  };

  loadMoreProfiles = () => {
    this.fetchData(true);
  };

  handleProfileCardClick = (item) => {
    const path = 'business';
    this.props.history.push(`/${path}/${item.id}`);
  };

  renderCards = () => {
    const account = this.props.businessProfile
      ? this.props.businessProfile
      : this.props.user;
    let items = this.state.profiles;
    return (
      <section className="activeUsers">
        {this.renderFilters()}
        <div className="sectionHeader">
          <div className="headerGroup">
            <div className="header">
              Profiles related to "{`${this.state.selectedGoal.name}`}"
            </div>
          </div>
        </div>
        <div className="activeUsersContainer">
          {items.map((item) => (
            <ProfileCard
              key={`${item.type}-${item.id}`}
              {...item}
              type={'organizations'}
              onClick={() => this.handleProfileCardClick(item)}
            />
          ))}
        </div>
        {account?.subscribed ? (
          this.state.noMoreProfiles ? null : (
            <div className="loadMore" onClick={this.loadMoreProfiles}>
              <p>Load more organisations...</p>
            </div>
          )
        ) : !account ? null : (
          this.renderPaywall()
        )}
      </section>
    );
  };

  renderTimeline = () => (
    <div className="feed">
      <div className="feedTimeline">
        {this.state.loaded && !!this.state.selectedGoal
          ? !!this.state.posts &&
            this.state.posts.length > 0 &&
            this.renderPosts()
          : [...Array(2)].map((element, index) => (
              <div key={index} className="postContentPlaceholder">
                <ContentPlaceholder type="post" />
              </div>
            ))}
      </div>
      {!!this.state.posts && this.state.posts.length > 0 && <Sidebar />}
    </div>
  );

  fetchData = (load_more_profiles = false, load_more_posts = false) => {
    // return organisations and feeds
    let extra_data = {};
    if (load_more_profiles)
      extra_data['offset_profile'] =
        this.state.profilesLoadNumber * PROFILES_PER_LOAD;
    if (load_more_posts)
      extra_data['offset_post'] = this.state.postsLoadNumber * POSTS_PER_LOAD;

    sendRequest({
      type: 'GET',
      method: '/searches/business_profiles',
      data: {
        goal_key: this.state.search.goal,
        country_id: this.state.search.country,
        objective_key: this.state.search.objective,
        subcategory_id: this.state.search.subcategory,
        profile_type: this.state.search.profile_type,
        order: this.state.search.order,
        ...extra_data,
      },
      success: (res) => {
        if (!!res && !!res.success) {
          this.state.subcategories = res?.data?.subcategory;
          if (load_more_profiles) {
            if (res.data?.profiles.length === 0) {
              triggerEvent('showSnackbar', [
                { text: 'Sorry, no more Profiles.' },
              ]);
              this.setState({ noMoreProfiles: true });
            } else {
              this.setState({
                profiles: [...this.state.profiles, ...res.data?.profiles],
                profilesLoadNumber: this.state.profilesLoadNumber + 1,
              });
            }
          } else if (load_more_posts) {
            if (res.data?.posts.length === 0) {
              triggerEvent('showSnackbar', [{ text: 'Sorry, no more Posts.' }]);
              this.setState({ noMorePosts: true });
            } else {
              this.setState({
                posts: [...this.state.posts, ...res.data?.posts],
                postsLoadNumber: this.state.postsLoadNumber + 1,
              });
            }
          } else {
            this.setState({
              posts: res.data?.posts,
              profiles: res.data?.profiles,
              selectedGoal: res.data?.goal,
              data: { ...this.data, goal_ids: [this.state.selectedGoal?.id] },
              postsLoadNumber: 1,
              profilesLoadNumber: 1,
              loaded: true,
            });
          }
        }
      },
      error: (data) => {},
    });
  };

  setQueryParams = () => {
    let queries = [];

    Object.keys(this.state.search).forEach((searchKey) => {
      if (!!this.state.search[searchKey] && searchKey != 'profile') {
        queries.push(`${searchKey}=${this.state.search[searchKey]}`);
      }
    });

    if (!!queries) {
      const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?${queries.join('&')}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  };

  renderInput = (properties) => {
    let value = this.state.search[properties.key];
    let search = this.state.search;

    const commonProps = {
      ...properties,
      value,
      onChange: (val) => {
        if (properties.key === 'profile') {
          if (val === 'organizations') {
            return true;
          } else if (val === 'individuals') {
            return this.props.history.push(
              `/individual-profiles?goal=${this.state.selectedGoal.key}`
            );
          } else if (val === 'content') {
            return this.props.history.push(
              `/content?goal=${this.state.selectedGoal.key}`
            );
          }
        } else {
          this.setState(
            {
              noMorePosts: false,
              noMoreProfiles: false,
              postsLoadNumber: 1,
              profilesLoadNumber: 1,
              search: {
                ...search,
                [properties.key]: val,
              },
            },
            () => {
              this.setQueryParams();
              this.fetchData();
            }
          );
        }
      },
    };
    return (
      <div className="inputBlock" key={properties.key}>
        {properties.title ? <label>{properties.title}</label> : null}
        {properties.type === 'select' ? <SelectInput {...commonProps} /> : null}
      </div>
    );
  };
  renderGoalsWithKeyIds = () => {
    const goals = [];
    if (!this.state || !this.state.goals) {
      return [];
    }
    this.state.goals.forEach((goal) => {
      goals.push({
        id: goal.key,
        name: goal.name,
      });
    });
    return goals;
  };
  renderObjectivesWithKeyIds = () => {
    const objectives = [];
    if (!this.state || !this.state.objectives) {
      return [];
    }
    this.state.objectives.forEach((objective) => {
      objectives.push({ id: objective.key, name: objective.title });
    });
    return objectives;
  };
  renderSubcategoriesWithKeyIds = () => {
    const subcategories = [];
    if (!this.state || !this.state.subcategories) {
      return [];
    }
    this.state.subcategories.forEach((subcategory) => {
      subcategories.push({ id: subcategory.id, name: subcategory.name });
    });
    return subcategories;
  };
  renderFilters = () => {
    const { countries, objectives, subcategories } = this.state;
    return (
      <>
        <div className="sectionFilters">
          {
            <div className="sortFilters">
              {this.renderInput({
                key: 'profile',
                type: 'select',
                title: 'Type',
                options: PROFILE_TYPES,
                clearable: false,
              })}
              {this.renderInput({
                key: 'country',
                type: 'select',
                title: 'Location',
                options: countries,
                placeholder: 'Select location',
                clearable: true,
              })}
              {this.renderInput({
                key: 'order',
                type: 'select',
                autoComplete: 'new-password',
                title: 'Sort by',
                options: [
                  { id: 'trending', name: 'Most Active' },
                  { id: 'name', name: 'By Name' },
                  { id: 'desc_order', name: 'Newest to community' },
                ],
                clearable: false,
              })}
              {this.renderInput({
                key: 'profile_type',
                type: 'select',
                title: 'Profile Type',
                options: PROFILE_SUB_TYPES,
                placeholder: 'Profile Type',
                clearable: true,
              })}
            </div>
          }
        </div>
        <div className="sectionFilters">
          {
            <div className="sortFilters">
              {this.renderInput({
                key: 'goal',
                type: 'select',
                title: 'Theme',
                options: this.renderGoalsWithKeyIds(),
                clearable: false,
              })}

              {this.renderInput({
                key: 'objective',
                type: 'select',
                title: 'Objective',
                options: this.renderObjectivesWithKeyIds(),
                placeholder: 'Select Objective',
                clearable: true,
              })}
              {this.renderInput({
                key: 'subcategory',
                type: 'select',
                title: 'Solutions',
                options: this.renderSubcategoriesWithKeyIds(),
                placeholder: 'Select Solutions',
                clearable: true,
              })}
            </div>
          }
        </div>
      </>
    );
  };

  isFollowingPost = () => {
    if (!this.props.user || !this.state.selectedGoal) {
      return false;
    }
    return this.props.user.goal_ids.includes(this.state.selectedGoal.id);
  };

  render = () => {
    return (
      <>
        <HeaderMeta />
        <FeedHeader
          isFollowing={this.isFollowingPost()}
          icon={feed.icon}
          feed={this.state.selectedGoal.name || ''}
          count={this.state.selectedGoal.followers_count || 0}
          feedKey={this.state.selectedGoal?.key}
          user={this.props.user}
        />
        <div className="searchesView">
          {this.state.loaded && !!this.state.selectedGoal ? (
            this.renderCards()
          ) : (
            <div className="userPlaceholderContainer">
              {[...Array(4)].map((e, i) => (
                <div key={i} className="userPlaceholderItem">
                  <ContentPlaceholder type="user" />
                </div>
              ))}
            </div>
          )}

          {this.props.user ? (
            <PostFormPopup
              authorTitle={this.state.data.authorTitle}
              goalIds={[this.state.selectedGoal?.id]}
              show={this.state.showPopupForm}
              file={this.state.data.file}
              callback={this.postFormPopupCallback}
              postId={this.state.data.postId}
              content={this.state.data.content}
              title={this.state.data.title}
              link={this.state.data.link}
            />
          ) : null}
        </div>
        {/* <div className="feedView">{this.renderTimeline()}</div> */}
      </>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(OrganisationSearch));
