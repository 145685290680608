import { ReactComponent as Goal1SVG } from '../../images/goals/goal-1.svg';
import { ReactComponent as Goal2SVG } from '../../images/goals/goal-2.svg';
import { ReactComponent as Goal3SVG } from '../../images/goals/goal-3.svg';
import { ReactComponent as Goal4SVG } from '../../images/goals/goal-4.svg';
import { ReactComponent as Goal5SVG } from '../../images/goals/goal-5.svg';
import { ReactComponent as Goal6SVG } from '../../images/goals/goal-6.svg';
import { ReactComponent as Goal7SVG } from '../../images/goals/goal-7.svg';
import { ReactComponent as Goal8SVG } from '../../images/goals/goal-8.svg';
import { ReactComponent as Goal9SVG } from '../../images/goals/goal-9.svg';
import { ReactComponent as Goal10SVG } from '../../images/goals/goal-10.svg';
import { ReactComponent as Goal11SVG } from '../../images/goals/goal-11.svg';
import { ReactComponent as Goal12SVG } from '../../images/goals/goal-12.svg';
import { ReactComponent as Goal13SVG } from '../../images/goals/goal-13.svg';
import { ReactComponent as Goal14SVG } from '../../images/goals/goal-14.svg';
import { ReactComponent as Goal15SVG } from '../../images/goals/goal-15.svg';
import { ReactComponent as Goal16SVG } from '../../images/goals/goal-16.svg';
import { ReactComponent as Goal17SVG } from '../../images/goals/goal-17.svg';

export default {
  goal_1: Goal1SVG,
  goal_2: Goal2SVG,
  goal_3: Goal3SVG,
  goal_4: Goal4SVG,
  goal_5: Goal5SVG,
  goal_6: Goal6SVG,
  goal_7: Goal7SVG,
  goal_8: Goal8SVG,
  goal_9: Goal9SVG,
  goal_10: Goal10SVG,
  goal_11: Goal11SVG,
  goal_12: Goal12SVG,
  goal_13: Goal13SVG,
  goal_14: Goal14SVG,
  goal_15: Goal15SVG,
  goal_16: Goal16SVG,
  goal_17: Goal17SVG,
};
