import React from 'react';
import { connect } from 'react-redux';

import Avatar from '../common/Avatar';

import '../../sass/components/feed/PostForm.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

class PostForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {
        file: props.file,
      },
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.data.file !== this.state.data.file) {
      this.props.onDataChange('file', this.state.data.file);
    }
    if (prevProps.file !== this.props.file) {
      this.setState({data: { file: this.props.file }});
    }
  }

  componentDidMount = () => {
  }

  attachFile = () => {
    const { data } = this.state;
    if (this.state.data.file) {
      this.setState({ data: {...data, 'file': null} })
      this.fileInput.value = '';
    }
    this.fileInput.click();
  }

  startPost = () => {
    if (this.props.onStart) {
      this.props.onStart();
    }
  }

  render = () => {
    let author = this.props.user;
    if (this.props.businessProfile) {
      author = this.props.businessProfile;
    }
    const { data } = this.state;
    const imageUrl = author
      ? author.image_url ? author.image_url : author.image
      : null;

    return (
      <div className='postForm'>
        <div className='authorContainer'>
          <Avatar
            imageUrl={imageUrl}
            username={author?.name}
            className='commentAvatar'
            small
          />
          <div
            className='startPost'
            onClick={this.startPost}
          >
            <p>Start a post</p>
          </div>
        </div>
        <div className='dataBlock'>
          <div className='dataItem'>
            <div
              className='uploadContainer'
              onClick={this.attachFile}
            >
              <input
                type='file'
                onChange={e => this.setState({ data: {...data, 'file': e.target.files[0]} })}
                ref={input => this.fileInput = input}
              />
              <span
                className='postAddCommentImage'
              />
              {this.state.data.file
                ? <div className='fileInfo'>
                    {typeof data.file === 'string' ? data.file : data.file.name}
                  </div>
                : <span
                    className='uploadPhoto'
                  >
                    Upload Photo
                  </span>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStoreToProps)(PostForm);
