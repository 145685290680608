import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CardListContainer = styled.div`
  padding: 12px;
  background: #f5f5f5;
  border-radius: 3px;
  margin-top: 15px;

  @media all and (min-width: 768px) {
    padding: 20px 24px;
    margin-top: 33px;
  }
`;

const CardListTitle = styled.span`
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #666666;
  margin-bottom: 20px;

  @media all and (min-width: 768px) {
    font-size: 24px;
  }
`;

const CardListWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;

  @media all and (min-width: 500px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media all and (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media all and (min-width: 920px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  height: 170px;
  width: 144.5px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 3px;

  @media all and (min-width: 400px) {
    height: 185px;
    width: 157.25px;
  }

  @media all and (min-width: 460px) {
    height: 200px;
    width: 170px;
  }

  @media all and (min-width: 500px) {
    height: 170px;
    width: 144.5px;
  }

  @media all and (min-width: 540px) {
    height: 185px;
    width: 157.25px;
  }

  @media all and (min-width: 620px) {
    height: 200px;
    width: 170px;
  }

  @media all and (min-width: 768px) {
    height: 185px;
    width: 157.25px;
  }

  @media all and (min-width: 820px) {
    height: 200px;
    width: 170px;
  }

  @media all and (min-width: 920px) {
    height: 185px;
    width: 157.25px;
  }

  @media all and (min-width: 1024px) {
    height: 200px;
    width: 170px;
  }
`;

const CardImageContainer = styled.div`
  width: 90%;
  height: 50px;
  justify-self: flex-start;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @media all and (min-width: 768px) {
    margin-top: 20px;
    height: 65px;
  }

  &.rounded {
    width: 50px;
    border-radius: 50%;

    @media all and (min-width: 768px) {
      width: 65px;
      height: 65px;
    }
  }
`;

const CardTextContainer = styled.div`
  display: block;
  flex-grow: 1;
`;

const CardTitle = styled.span`
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  text-transform: capitalize;
  color: #666666;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;

  @media all and (min-width: 768px) {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const CardSubtitle = styled.span`
  display: block;
  font-size: 10px;
  line-height: 18px;
  text-transform: capitalize;
  color: #999999;
  text-align: center;

  @media all and (min-width: 768px) {
    font-size: 12px;
  }
`;

const CardLink = styled(Link)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #999999;
  justify-self: flex-end;
  padding-bottom: 10px;

  @media all and (min-width: 768px) {
    padding-bottom: 20px;
  }
`;

const CardAnchor = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #999999;
  justify-self: flex-end;
  padding-bottom: 10px;

  @media all and (min-width: 768px) {
    padding-bottom: 20px;
  }
`;

const CardAdd = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 170px;
  width: 144.5px;
  background: #f26e26;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  cursor: pointer;

  @media all and (min-width: 400px) {
    height: 185px;
    width: 157.25px;
  }

  @media all and (min-width: 460px) {
    height: 200px;
    width: 170px;
  }

  @media all and (min-width: 500px) {
    height: 170px;
    width: 144.5px;
  }

  @media all and (min-width: 540px) {
    height: 185px;
    width: 157.25px;
  }

  @media all and (min-width: 620px) {
    height: 200px;
    width: 170px;
  }

  @media all and (min-width: 768px) {
    height: 185px;
    width: 157.25px;
  }

  @media all and (min-width: 820px) {
    height: 200px;
    width: 170px;
  }

  @media all and (min-width: 920px) {
    height: 185px;
    width: 157.25px;
  }

  @media all and (min-width: 1024px) {
    height: 200px;
    width: 170px;
  }

  .plus-icon {
    width: 45px;
    height: 45px;
    background-image: url(/images/market_map/plus-white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 24px;
  }

  .add-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
`;

function CardList({
  title,
  list,
  add = false,
  addText = 'Add',
  addHandler = null,
}) {
  const handleOnAdd = () => {
    if (addHandler) {
      addHandler();
    }
  };
  return (
    <CardListContainer>
      {title && <CardListTitle>{title}</CardListTitle>}
      <CardListWrapper>
        {list &&
          list.length > 0 &&
          list.map((card, index) => (
            <Card key={card.id ?? index}>
              <CardImageContainer
                style={{
                  ...(card.imageSrc && {
                    backgroundImage: `url(${card.imageSrc})`,
                  }),
                }}
                className={`${card.imgRounded ? 'rounded' : ''}`}
              />
              <CardTextContainer>
                {card.title && <CardTitle>{card.title}</CardTitle>}
                {card.subtitle && <CardSubtitle>{card.subtitle}</CardSubtitle>}
              </CardTextContainer>
              {card.link && (
                <>
                  {card.linkTo && card.linkTo === 'remote' && (
                    <CardAnchor
                      href={card.link}
                      {...(card.linkNewTab && {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      })}
                    >
                      {card.linkText ?? 'Link'}
                    </CardAnchor>
                  )}
                  {(!card.linkTo ||
                    (card.linkTo && card.linkTo !== 'remote')) && (
                    <CardLink
                      to={card.link}
                      {...(card.linkNewTab && {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      })}
                    >
                      {card.linkText ?? 'Link'}
                    </CardLink>
                  )}
                </>
              )}
            </Card>
          ))}
        {add && (
          <CardAdd onClick={() => handleOnAdd()}>
            <div className="plus-icon"></div>
            <div className="add-text">{addText}</div>
          </CardAdd>
        )}
      </CardListWrapper>
    </CardListContainer>
  );
}

export default CardList;
