import React from 'react';
import { Helmet } from 'react-helmet';

import AuthPopup from './auth/AuthPopup';

import '../sass/components/LoginView.scss';

class LoginView extends React.Component {
  render = () => {
    return (
      <div className="loginView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/logo.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/logo.png" />
        </Helmet>
        <AuthPopup />
      </div>
    );
  };
}

export default LoginView;
