import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';

import { sendRequest } from '../../helpers/RequestDispatcher.js';

import '../../sass/components/input/SelectInput.scss';

class SelectInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      options: [],
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.options !== this.props.options) {
      this.setState({options: this.props.options});
    }
  }

  handleChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  componentDidMount = () => {
    if (this.props.options && !this.props.request) {
      this.setState({options: this.props.options});
    }
    if (this.props.request && !this.props.async) {
      sendRequest({
        type: 'GET',
        method: this.props.request,
        success: (data) => {
          this.setState({options: data});
        },
        error: (error) => {
        }
      });
    }
  }

  loadOptions = (inputValue, callback) => {
    if (this.props.request) {
      sendRequest({
        type: 'GET',
        method: this.props.request,
        data: {
          name: inputValue
        },
        success: (data) => {
          this.setState({options: data});
          callback(
            data.map(i => ({ value: i.id, label: i.name }))
          );
        },
        error: (error) => {
          callback([]);
        }
      });
    } else {
      callback([]);
    }
  };

  render = () => {
    const isMulti = this.props.type == "multi-select";
    const withImage = this.props.withImage;
    const error = this.props.error || null;
    const options = this.state.options.map(i => ({
      value: i.id,
      label: withImage
        ? <div className='withImage'>
            <img
              src={i.image_url}
              alt='Label'
            />
            <div>{i.name}</div>
          </div>
        : i.name
    }));
    const value = isMulti
      ? (this.props.value || []).map(v => options.find(i => i.value == v)) // eslint-disable-line eqeqeq
      : options.find(i => i.value == this.props.value); // eslint-disable-line eqeqeq
    const selectProps = {
      value: value || null,
      onChange: e => {
        if (isMulti) {
          this.handleChange(e ? e.map(i => i.value) : []);
        } else {
          this.handleChange(e ? e.value : null);
        }
      },
      onFocus: () => this.setState({focus: true}),
      onBlur: () => this.setState({focus: false}),
      isMulti: isMulti,
      isDisabled: this.props.disabled,
      className: 'reactSelect',
      classNamePrefix: 'reactSelect',
      isClearable: this.props.clearable,
      placeholder: this.props.placeholder || 'Select...',
      noOptionsMessage: inputValue => 'No options',
    }
    return (
      <div
        className={classnames({
          'selectInput': true,
          'readOnly': this.props.disabled,
          'expandTop': this.props.expandTop,
        })}
      >
        {this.props.async
          ? <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={this.loadOptions}
            {...selectProps}
          />
          : <Select
            options={options}
            {...selectProps}
          />
        }
        {error && <div className='inputError'>{error}</div>}
      </div>
    )
  }
}

export default SelectInput;
