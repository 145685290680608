import React, { useEffect, useState } from 'react';
import { Header, Form, Divider } from 'semantic-ui-react';
import { useFormik, FormikProvider, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { omit } from 'ramda';

import { triggerEvent } from '../../../helpers/global';
import { loadOptions } from '../../../helpers/market_map';
import ImageUpload from './ImageUpload';

export const RemoteSubmit = ({ submit, setSubmit }) => {
  const { submitForm, isValid } = useFormikContext();

  useEffect(() => {
    if (submit) {
      submitForm();
    }
  }, [submitForm, submit]);

  useEffect(() => {
    if (submit && !isValid) {
      setSubmit(false);
    }
  }, [setSubmit, isValid, submit]);

  return null;
};

function CompanyForm({
  formValues = null,
  onSubmitHandler,
  renderRemoteSubmit = null,
}) {
  const [optionsCompanyType, setOptionsCompanyType] = useState([]);
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [optionsValueChain, setOptionsValueChain] = useState([]);
  const [optionsValueChainType, setOptionsValueChainType] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const [initialValues, setInitialValues] = useState({
    name: '',
    url: '',
    location: '',
    company_type_id: null,
    company_type_name: null,
    goal_id: null,
    goal_name: null,
    market_map_id: null,
    market_map_name: null,
    value_chain_id: null,
    value_chain_name: null,
    value_chain_type_id: null,
    value_chain_type_name: null,
    description: '',
    funding_round: '',
    funding_amount: '',
    market_models: [],
    other_market_model: '',
    business_models: [],
    other_business_model: '',
    logo_base64: '',
    logo_filename: '',
    logo_url: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('Name is required'),
      other_market_model: Yup.string().when('market_models', {
        is: (market_models) => market_models.includes('others'),
        then: Yup.string().required('Other Market Model is required'),
      }),
      other_business_model: Yup.string().when('business_models', {
        is: (business_models) => business_models.includes('others'),
        then: Yup.string().required('Other Business Model is required'),
      }),
    }),
    onSubmit: (values) => onSubmitHandler(values),
  });

  const handleOnChangeCheckbox = (e, data) => {
    const { checked, name, value } = data;
    const currentValues = [...formik.values[name]];
    if (checked) {
      formik.setFieldValue(name, [...currentValues, value]);
    } else {
      currentValues.splice(currentValues.indexOf(value), 1);
      formik.setFieldValue(name, currentValues);
    }
  };

  const isCheckboxChecked = (name, value) =>
    formik.values[name].includes(value);

  const handleImageUploadSetter = ({
    base64String,
    filename,
    imageUrlUpload,
  }) => {
    formik.setFieldValue('logo_base64', base64String);
    formik.setFieldValue('logo_filename', filename);
    formik.setFieldValue('logo_url', imageUrlUpload);
  };

  useEffect(() => {
    loadOptions({
      route: 'company-types',
      setter: setOptionsCompanyType,
      isLocalStorage: true,
    });
    loadOptions({
      route: 'goals',
      setter: setOptionsGoal,
      isLocalStorage: true,
    });
    loadOptions({
      route: 'market-maps',
      setter: setOptionsMarketMap,
      isLocalStorage: true,
    });
    loadOptions({
      route: 'value-chains',
      setter: setOptionsValueChain,
      isLocalStorage: true,
    });
    loadOptions({
      route: 'value-chain-types',
      setter: setOptionsValueChainType,
      isLocalStorage: true,
    });
  }, []);

  useEffect(() => {
    if (formValues) {
      setInitialValues({
        ...omit(['logo'], formValues),
      });
      if (formValues.logo) {
        setLogoUrl(formValues.logo);
      }
    }
  }, [formValues]);

  useEffect(() => {
    const { isValid, isSubmitting } = formik;
    if (!isValid && isSubmitting) {
      triggerEvent('showSnackbar', [
        { text: 'Please enter required fields', type: 'error' },
      ]);
    }
  }, [formik.isValid, formik.isSubmitting]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Header as="h4">Company Information</Header>
        <Form.Group widths="equal">
          <Form.Input
            name="name"
            label="Company Name"
            placeholder="Enter Company Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            autoComplete="off"
            {...(formik.touched.name &&
              formik.errors.name && { error: formik.errors.name })}
          />
          <Form.Input
            name="url"
            label="Company URL"
            placeholder="Enter Company URL"
            value={formik.values.url}
            onChange={formik.handleChange}
            autoComplete="off"
            {...(formik.touched.url &&
              formik.errors.url && { error: formik.errors.url })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="location"
            label="HQ Location"
            placeholder="Enter HQ Location"
            value={formik.values.location}
            onChange={formik.handleChange}
            autoComplete="off"
          />
          <Field
            component={Form.Select}
            name="company_type_id"
            label="Company Type"
            placeholder="Select Company Type"
            options={optionsCompanyType}
            clearable
            search
            value={formik.values.company_type_id}
            onChange={(e, { value, options }) => {
              const company_type_id = !value ? null : +value;
              formik.setFieldValue('company_type_id', company_type_id);
              formik.setFieldValue(
                'company_type_name',
                company_type_id
                  ? options.find((option) => +option.value === company_type_id)
                      .text
                  : null
              );
            }}
            selectOnBlur={false}
            {...(formik.touched.company_type_id &&
              formik.errors.company_type_id && {
                error: formik.errors.company_type_id,
              })}
          />
        </Form.Group>
        <Divider />
        <Header as="h4">Market Map details</Header>
        <Form.Group widths="equal">
          <Field
            component={Form.Select}
            name="goal_id"
            label="SDG"
            placeholder="Select SDG"
            options={optionsGoal}
            clearable
            search
            value={formik.values.goal_id}
            onChange={(e, { value, options }) => {
              const goal_id = !value ? null : +value;
              formik.setFieldValue('goal_id', goal_id);
              formik.setFieldValue(
                'goal_name',
                goal_id
                  ? options.find((option) => +option.value === goal_id).text
                  : null
              );
              formik.setFieldValue('market_map_id', null);
              formik.setFieldValue('market_map_name', null);
              formik.setFieldValue('value_chain_type_id', null);
              formik.setFieldValue('value_chain_type_name', null);
              loadOptions({
                route: 'market-maps',
                setter: setOptionsMarketMap,
                additionalParams: { goal_id },
              });
              loadOptions({
                route: 'value-chain-types',
                setter: setOptionsValueChainType,
                additionalParams: {
                  goal_id: value,
                },
              });
            }}
            selectOnBlur={false}
            {...(formik.touched.goal_id &&
              formik.errors.goal_id && { error: formik.errors.goal_id })}
          />
          <Field
            component={Form.Select}
            name="market_map_id"
            label="Market Map"
            placeholder="Select Market Map"
            options={optionsMarketMap}
            clearable
            search
            value={formik.values.market_map_id}
            onChange={(e, { value, options }) => {
              const market_map_id = !value ? null : +value;
              formik.setFieldValue('market_map_id', market_map_id);
              formik.setFieldValue(
                'market_map_name',
                market_map_id
                  ? options.find((option) => +option.value === market_map_id)
                      .text
                  : null
              );
              formik.setFieldValue('value_chain_type_id', null);
              formik.setFieldValue('value_chain_type_name', null);
              loadOptions({
                route: 'value-chain-types',
                setter: setOptionsValueChainType,
                additionalParams: {
                  goal_id: formik.values.goal_id,
                  market_map_id,
                },
              });
            }}
            selectOnBlur={false}
            {...(formik.touched.market_map_id &&
              formik.errors.market_map_id && {
                error: formik.errors.market_map_id,
              })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            component={Form.Select}
            name="value_chain_id"
            label="Chain Position"
            placeholder="Select Chain Position"
            options={optionsValueChain}
            clearable
            search
            value={formik.values.value_chain_id}
            onChange={(e, { value, options }) => {
              const value_chain_id = !value ? null : +value;
              formik.setFieldValue('value_chain_id', value_chain_id);
              formik.setFieldValue(
                'value_chain_name',
                value_chain_id
                  ? options.find((option) => +option.value === value_chain_id)
                      .text
                  : null
              );
              formik.setFieldValue('value_chain_type_id', null);
              formik.setFieldValue('value_chain_type_name', null);
              loadOptions({
                route: 'value-chain-types',
                setter: setOptionsValueChainType,
                additionalParams: {
                  goal_id: formik.values.goal_id,
                  market_map_id: formik.values.market_map_id,
                  value_chain_id,
                },
              });
            }}
            selectOnBlur={false}
            {...(formik.touched.value_chain_id &&
              formik.errors.value_chain_id && {
                error: formik.errors.value_chain_id,
              })}
          />
          <Field
            component={Form.Select}
            name="value_chain_type_id"
            label="Value Chain Category"
            placeholder="Select Value Chain Category"
            options={optionsValueChainType}
            clearable
            search
            value={formik.values.value_chain_type_id}
            onChange={(e, { value, options }) => {
              const value_chain_type_id = !value ? null : +value;
              formik.setFieldValue('value_chain_type_id', value_chain_type_id);
              formik.setFieldValue(
                'value_chain_type_name',
                value_chain_type_id
                  ? options.find(
                      (option) => +option.value === value_chain_type_id
                    ).text
                  : null
              );
            }}
            selectOnBlur={false}
            {...(formik.touched.value_chain_type_id &&
              formik.errors.value_chain_type_id && {
                error: formik.errors.value_chain_type_id,
              })}
          />
        </Form.Group>
        <Form.Group>
          <Form.TextArea
            name="description"
            label="Description"
            placeholder="Enter Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            autoComplete="off"
            rows={7}
            width={16}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            name="funding_round"
            label="Funding Round"
            placeholder="Enter Funding Round"
            value={formik.values.funding_round}
            onChange={formik.handleChange}
            autoComplete="off"
          />
          <Form.Input
            name="funding_amount"
            label="Funding Amount"
            placeholder="Enter Funding Amount"
            value={formik.values.funding_amount}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group grouped>
          <label>Market Model</label>
          <small> (you may select more than one option)</small>
          <Form.Checkbox
            name="market_models"
            label="B2B"
            value="b2b"
            checked={isCheckboxChecked('market_models', 'b2b')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Checkbox
            name="market_models"
            label="B2B2C"
            value="b2b2c"
            checked={isCheckboxChecked('market_models', 'b2b2c')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Checkbox
            name="market_models"
            label="B2C"
            value="b2c"
            checked={isCheckboxChecked('market_models', 'b2c')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Checkbox
            name="market_models"
            label="B2G"
            value="b2g"
            checked={isCheckboxChecked('market_models', 'b2g')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Checkbox
            name="market_models"
            label="Others"
            value="others"
            checked={isCheckboxChecked('market_models', 'others')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Input
            name="other_market_model"
            placeholder="Enter other market model"
            value={formik.values.other_market_model}
            onChange={formik.handleChange}
            autoComplete="off"
            disabled={!formik.values.market_models.includes('others')}
            width={6}
            {...(formik.touched.other_market_model &&
              formik.errors.other_market_model && {
                error: formik.errors.other_market_model,
              })}
          />
        </Form.Group>
        <Form.Group grouped>
          <label>Business Model</label>
          <small> (you may select more than one option)</small>
          <Form.Checkbox
            name="business_models"
            label="Services"
            value="services"
            checked={isCheckboxChecked('business_models', 'services')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Checkbox
            name="business_models"
            label="Hardware"
            value="hardware"
            checked={isCheckboxChecked('business_models', 'hardware')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Checkbox
            name="business_models"
            label="Software"
            value="software"
            checked={isCheckboxChecked('business_models', 'software')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Checkbox
            name="business_models"
            label="Business model or financial innovation"
            value="business_financial"
            checked={isCheckboxChecked('business_models', 'business_financial')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Checkbox
            name="business_models"
            label="Others"
            value="others"
            checked={isCheckboxChecked('business_models', 'others')}
            onChange={handleOnChangeCheckbox}
          />
          <Form.Input
            name="other_business_model"
            placeholder="Enter other business model"
            value={formik.values.other_business_model}
            onChange={formik.handleChange}
            autoComplete="off"
            disabled={!formik.values.business_models.includes('others')}
            width={6}
            {...(formik.touched.other_business_model &&
              formik.errors.other_business_model && {
                error: formik.errors.other_business_model,
              })}
          />
        </Form.Group>
        <label style={{ fontWeight: 'bold' }}>Logo</label>
        <ImageUpload
          imageUrl={logoUrl || formik.values.logo_base64}
          handlerImageAccepted={handleImageUploadSetter}
          toUploadImageUrl={formik.values.logo_url}
        />
        {!renderRemoteSubmit && (
          <Form.Button positive type="submit">
            Save
          </Form.Button>
        )}
        {renderRemoteSubmit && renderRemoteSubmit()}
      </Form>
    </FormikProvider>
  );
}

export default CompanyForm;
