import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Amplify from '@aws-amplify/core';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Storage from './helpers/Storage.js';
import { initCache } from './helpers/RequestDispatcher.js';
import ScrollToTop from './components/common/ScrollToTop';
import App from './App';

import 'semantic-ui-css/semantic.min.css';
import './sass/semantic.override.scss';
import './sass/fonts.scss';
import './sass/base.scss';
import './sass/animations.scss';

require('./css/material-icons.css');
require('./css/react-select.css');
require('react-datetime/css/react-datetime.css');

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    region: process.env.REACT_APP_AWS_REGION,
  },
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});

const init = async () => {
  dayjs.extend(relativeTime);
  try {
    await Promise.all([
      initCache(),
      Storage.loadData('user'),
      Storage.loadData('businessProfile'),
    ]);
  } catch (ex) {}
};

init().then(() => {
  ReactDOM.render(
    <Provider store={Storage.getStore()}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('content')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
