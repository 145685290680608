export const IconSet = {
  Check: 'check.svg',
  Comment: 'comment.svg',
  CommentLarge: 'comment-large.svg',
  Facebook: 'facebook.svg',
  Image: 'image.svg',
  Instagram: 'instagram.svg',
  Like: 'like.svg',
  LinkedIn: 'linkedin.svg',
  Location: 'location.svg',
  Options: 'options.svg',
  Photo: 'photo.svg',
  Play: 'play.svg',
  Search: 'search.svg',
  Twitter: 'twitter.svg',
  Web: 'web.svg',
  EcoBusinessDark: 'eco_business_dark.png',

  Categories: {
    EnergyDark: 'categories/energy_dark.png',
  },

  MarketMap: {
    Share: 'market_map/share.svg',
  },
};
