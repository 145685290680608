import React from 'react';
import ContentLoader from 'react-content-loader';

import '../../sass/components/common/ContentPlaceholder.scss';

class ContentPlaceholder extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  renderPostContentLoader = () => {
    return (
      <div className='postPlaceholderItem'>
        <ContentLoader
          speed={2}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          viewBox='0 0 320 170'
          {...this.props}
        >
          <rect x="48" y="11" rx="3" ry="3" width="70" height="7" /> 
          <rect x="48" y="25" rx="3" ry="3" width="42" height="4" /> 
          <circle cx="20" cy="20" r="15" /> 
          <rect x="360" y="17" rx="3" ry="3" width="20" height="4" /> 
          <rect x="5" y="46" rx="3" ry="3" width="271" height="4" /> 
          <rect x="5" y="55" rx="3" ry="3" width="220" height="4" /> 
          <rect x="4" y="66" rx="3" ry="3" width="306" height="85" /> 
          <rect x="5" y="158" rx="3" ry="3" width="30" height="4" /> 
          <rect x="280" y="158" rx="3" ry="3" width="30" height="4" />
        </ContentLoader>
      </div>
    )
  }

  renderPostTeaserContentLoader = () => {
    return (
      <div className='postPlaceholderItem'>
        <ContentLoader
          speed={2}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          viewBox='0 0 270 210'
          {...this.props}
        >
          <rect x="48" y="12" rx="3" ry="3" width="70" height="4" /> 
          <rect x="48" y="24" rx="3" ry="3" width="42" height="4" /> 
          <circle cx="20" cy="20" r="15" /> 
          <rect x="360" y="17" rx="3" ry="3" width="20" height="4" /> 
          <rect x="5" y="47" rx="3" ry="3" width="220" height="5" /> 
          <rect x="5" y="60" rx="3" ry="3" width="180" height="5" /> 
          <rect x="5" y="73" rx="3" ry="3" width="205" height="5" /> 
          <rect x="5" y="86" rx="3" ry="3" width="160" height="5" /> 
          <rect x="5" y="103" rx="3" ry="3" width="220" height="85" /> 
          <rect x="5" y="200" rx="3" ry="3" width="40" height="5" /> 
          <rect x="184" y="200" rx="3" ry="3" width="40" height="5" />
        </ContentLoader>
      </div>
    )
  }

  renderUserContentLoader = () => {
    return (
      <div>
        <ContentLoader
          speed={2}
          viewBox="0 0 225 370"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...this.props}
        >
          <rect x="0" y="3" rx="0" ry="0" width="2" height="300" /> 
          <rect x="214" y="5" rx="0" ry="0" width="2" height="300" /> 
          <rect x="0" y="3" rx="0" ry="0" width="216" height="2" /> 
          <rect x="0" y="303" rx="0" ry="0" width="216" height="2" /> 
          <rect x="63" y="33" rx="0" ry="0" width="90" height="6" /> 
          <rect x="27" y="170" rx="0" ry="0" width="160" height="9" /> 
          <rect x="62" y="214" rx="0" ry="0" width="92" height="7" /> 
          <rect x="44" y="231" rx="0" ry="0" width="125" height="7" /> 
          <circle cx="107" cy="99" r="45" /> 
          <rect x="377" y="222" rx="0" ry="0" width="63" height="63" /> 
          <rect x="63" y="275" rx="0" ry="0" width="86" height="7" />
        </ContentLoader>
      </div>
    )
  }

  renderProfileHeaderContentLoader = () => {
    return (
      <div className='profileHeaderPlaceholderItem'>
        <ContentLoader
          speed={2}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          viewBox="0 0 400 160"
          {...this.props}
        >
          <rect x="195" y="45" rx="8" ry="8" width="200" height="28" /> 
          <circle cx="80" cy="80" r="80" /> 
          <rect x="195" y="104" rx="5" ry="5" width="140" height="15" />
        </ContentLoader>
      </div>
    )
  }

  renderProfileAboutContentLoader = () => {
    return (
      <div className='profileAboutPlaceholderItem'>
        <ContentLoader
          speed={2}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          viewBox="0 0 620 60"
          {...this.props}
        >
          <rect x="0" y="0" rx="5" ry="5" width="600" height="12" /> 
          <rect x="0" y="30" rx="5" ry="5" width="500" height="12" />
        </ContentLoader>
      </div>
    )
  }

  renderProfileContactsContentLoader = () => {
    return (
      <div className='profileContactsPlaceholderItem'>
        <ContentLoader
          speed={2}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          viewBox="0 0 140 12"
          {...this.props}
        >
          <rect x="0" y="2" rx="5" ry="5" width="140" height="10" />
        </ContentLoader>
      </div>
    )
  }

  renderProfileLookingToContentLoader = () => {
    return (
      <div className='profileLookingToPlaceholderItem'>
        <ContentLoader
          speed={2}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          viewBox="0 0 266 40"
          {...this.props}
        >
          <rect x="0" y="0" rx="20" ry="20" width="266" height="40" />
        </ContentLoader>
      </div>
    )
  }

  renderProfileGoalsContentLoader = () => {
    const rows = 2;
    const columns = 4;
    const coverWidth = 160;
    const coverHeight = 190;
    const padding = 2;
    const coverWidthWithPadding = coverWidth + padding;
    const coverHeightWithPadding = coverHeight + padding;
    return (
      <div className='profileGoalsPlaceholderItem'>
        <ContentLoader
          speed={2}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          viewBox="0 0 640 380"
          {...this.props}
        >
          {Array(columns * rows).fill(1).map((g, i) => {
            let vy = Math.floor(i / columns) * coverHeightWithPadding;
            let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding);
            return (
              <rect
                key={i}
                x={vx}
                y={vy}
                rx="1"
                ry="1"
                width={coverWidth}
                height={coverHeight}
              />
            )
          })}
        </ContentLoader>
      </div>
    )
  }

  renderCommentContentLoader = () => {
    return (
      <div className='commentPlaceholderItem'>
        <ContentLoader
          speed={2}
          viewBox="0 0 320 60"
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
          {...this.props}
        >
          <rect x="39" y="4" rx="3" ry="3" width="71" height="6" /> 
          <rect x="39" y="20" rx="3" ry="3" width="220" height="3" /> 
          <circle cx="13" cy="13" r="13" /> 
          <rect x="340" y="8" rx="3" ry="3" width="71" height="4" /> 
          <rect x="39" y="28" rx="3" ry="3" width="190" height="3" /> 
          <rect x="39" y="36" rx="3" ry="3" width="200" height="3" /> 
          <rect x="39" y="44" rx="3" ry="3" width="175" height="3" />
        </ContentLoader>
      </div>
    )
  }

  renderNewsContentLoader = () => {
    return (
      <div className='newsPlaceholderItem'>
        <ContentLoader
          speed={2}
          viewBox="0 0 300 120"
          backgroundColor='#EEEEEE'
          foregroundColor='#FFFFFF'
          {...this.props}
        >
          <rect x="340" y="8" rx="3" ry="3" width="71" height="4" /> 
          <rect x="0" y="0" rx="3" ry="3" width="130" height="100" /> 
          <rect x="145" y="0" rx="3" ry="3" width="110" height="9" /> 
          <rect x="145" y="28" rx="3" ry="3" width="155" height="6" /> 
          <rect x="145" y="42" rx="3" ry="3" width="120" height="6" /> 
          <rect x="145" y="56" rx="3" ry="3" width="140" height="6" /> 
          <rect x="145" y="70" rx="3" ry="3" width="110" height="6" />
        </ContentLoader>
      </div>
    )
  }

  renderEventsContentLoader = () => {
    return (
      <div className='eventPlaceholderItem'>
        <ContentLoader
          speed={2}
          viewBox="0 0 190 70"
          backgroundColor='#EEEEEE'
          foregroundColor='#FFFFFF'
          {...this.props}
        >
          <rect x="340" y="8" rx="3" ry="3" width="71" height="4" /> 
          <rect x="0" y="0" rx="3" ry="3" width="55" height="60" /> 
          <rect x="70" y="0" rx="3" ry="3" width="120" height="9" /> 
          <rect x="70" y="21" rx="3" ry="3" width="92" height="4" /> 
          <rect x="70" y="30" rx="3" ry="3" width="68" height="4" /> 
          <rect x="70" y="39" rx="3" ry="3" width="92" height="4" /> 
          <rect x="70" y="48" rx="3" ry="3" width="46" height="4" />
        </ContentLoader>
      </div>
    )
  }

  render = () => {
    const { type } = this.props;
    return (
      <>
        {type === 'user' && this.renderUserContentLoader()}
        {type === 'profileHeader' && this.renderProfileHeaderContentLoader()}
        {type === 'profileAbout' && this.renderProfileAboutContentLoader()}
        {type === 'profileContact' && this.renderProfileContactsContentLoader()}
        {type === 'profileGoals' && this.renderProfileGoalsContentLoader()}
        {type === 'profileLookingTo' && this.renderProfileLookingToContentLoader()}
        {type === 'post' && this.renderPostContentLoader()}
        {type === 'postTeaser' && this.renderPostTeaserContentLoader()}
        {type === 'comment' && this.renderCommentContentLoader()}
        {type === 'news' && this.renderNewsContentLoader()}
        {type === 'events' && this.renderEventsContentLoader()}
      </>
    )
  }
}

export default ContentPlaceholder;
