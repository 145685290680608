import React, { useState, useEffect } from 'react';
import { Modal, Button, Popup, Input } from 'semantic-ui-react';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Papa from 'papaparse';

import { downloadBlob } from '../../../helpers/file_helper';

const ShareExportModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #666666;
  padding: 15px 0 18px 0;
  border-bottom: 1px solid #eeeeee;
`;
const ShareExportModal = styled(Modal)`
  width: 400px !important;

  @media all and (min-width: 768px) {
    width: 500px !important;
  }
`;
const ShareExportModalContent = styled(Modal.Content)`
  padding: 0 !important;
`;
const ExportContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0 15px 0;
  border-bottom: 1px solid #eeeeee;

  @media all and (min-width: 768px) {
    padding: 18px 0 30px 0;
  }
`;
const ExportButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;

  .export-img {
    width: 45px;
    height: 45px;
    margin-bottom: 3px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &.export-jpg {
      background-image: url(/images/market_map/export-jpg.svg);
    }

    &.export-pdf {
      background-image: url(/images/market_map/export-pdf.svg);
    }

    &.export-csv {
      background-image: url(/images/market_map/export-csv.svg);
    }
  }

  .export-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #666666;
  }

  &:hover {
    .export-img {
      &.export-jpg {
        background-image: url(/images/market_map/export-jpg-active.svg);
      }

      &.export-pdf {
        background-image: url(/images/market_map/export-pdf-active.svg);
      }

      &.export-csv {
        background-image: url(/images/market_map/export-csv-active.svg);
      }
    }
  }
`;
const ShareWrapper = styled.div`
  padding: 23px 20px 32px 20px;

  @media all and (min-width: 768px) {
    padding: 23px 68px 32px 68px;
  }
`;
const ShareContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media all and (min-width: 768px) {
    margin-bottom: 32px;
  }
`;
const ShareSocialButton = styled.a`
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;

  &.share-facebook {
    background-image: url(/images/market_map/share-facebook.svg);
  }

  &.share-linkedin {
    background-image: url(/images/market_map/share-linkedin.svg);
  }

  &.share-twitter {
    background-image: url(/images/market_map/share-twitter.svg);
  }

  &.share-whatsapp {
    background-image: url(/images/market_map/share-whatsapp.svg);
  }

  &.share-telegram {
    background-image: url(/images/market_map/share-telegram.svg);
  }
`;
const ShareInput = styled(Input)`
  background: #f5f5f5;
  border: 1px solid #cccccc;
  border-radius: 3px;

  input {
    border: none !important;
    background: #f5f5f5 !important;
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px !important;
    color: #666666 !important;
  }

  .ui.button {
    background: transparent;
    text-transform: uppercase;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #f26e26;
    padding-left: 0;
  }
`;

function ShareExportModalComponent({
  open = false,
  handleClose,
  hasExportJpg = false,
  hasExportPdf = false,
  hasExportCsv = false,
  csvData = null,
  getParentRef = () => null, //https://stackoverflow.com/a/54941019
}) {
  const [shareLink, setShareLink] = useState('');
  const [openCopyLinkPopup, setOpenCopyLinkPopup] = useState(false);

  const handleExportJpg = () => {
    if (!hasExportJpg || !getParentRef) {
      return;
    }

    const processExport = async () => {
      const printRef = getParentRef();
      const element = printRef.current;

      // Hide add buttons
      element.querySelectorAll('.add-text').forEach((node) => {
        node.parentNode.style.display = 'none';
      });

      const canvas = await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
        logging: false,
      });
      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        link.href = data;
        link.download = `export-${new Date().getTime()}.jpg`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }

      // Show add buttons
      element.querySelectorAll('.add-text').forEach((node) => {
        node.parentNode.removeAttribute('style');
      });
    };
    processExport();
  };

  const handleExportPdf = () => {
    if (!hasExportPdf || !getParentRef) {
      return;
    }

    const processExport = async () => {
      const printRef = getParentRef();
      const element = printRef.current;

      // Hide add buttons
      element.querySelectorAll('.add-text').forEach((node) => {
        node.parentNode.style.display = 'none';
      });

      const canvas = await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
        logging: false,
      });
      const data = canvas.toDataURL('image/png');

      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const pageWidth = pdfWidth;
      const pageHeight =
        (imgProperties.height * pageWidth) / imgProperties.width;
      let positionHeight = 0;
      let heightLeft = pageHeight;

      pdf.addImage(data, 'PNG', 0, positionHeight, pageWidth, pageHeight);
      heightLeft -= pdfHeight;
      positionHeight -= pdfHeight;

      while (heightLeft > 0) {
        pdf.addPage();
        pdf.addImage(data, 'PNG', 0, positionHeight, pageWidth, pageHeight);
        heightLeft -= pdfHeight;
        positionHeight -= pdfHeight;
      }

      pdf.save(`export-${new Date().getTime()}.pdf`);

      // Show add buttons
      element.querySelectorAll('.add-text').forEach((node) => {
        node.parentNode.removeAttribute('style');
      });
    };
    processExport();
  };

  const handleExportCsv = () => {
    if (!hasExportCsv || !csvData) {
      return;
    }

    const _csvData = Papa.unparse(csvData);
    const blob = new Blob([_csvData], { type: 'text/csv' });
    downloadBlob(blob, `export-${new Date().getTime()}.csv`);
  };

  const handleShareLinkCopy = () => {
    navigator.clipboard.writeText(shareLink);
    setOpenCopyLinkPopup(true);
    setTimeout(() => {
      setOpenCopyLinkPopup(false);
    }, 1000);
  };

  useEffect(() => {
    setShareLink(`${window.location.host}${window.location.pathname}`);
  }, []);

  return (
    <ShareExportModal open={open} closeIcon onClose={() => handleClose()}>
      <ShareExportModalHeader>
        <object
          type="image/svg+xml"
          height="14"
          width="13"
          data="/images/market_map/share-dark.svg"
          style={{ marginRight: 10 }}
        ></object>
        Share/Export
      </ShareExportModalHeader>
      <ShareExportModalContent>
        <ExportContainer>
          {hasExportJpg && (
            <ExportButton>
              <div
                className="export-img export-jpg"
                onClick={() => handleExportJpg()}
              ></div>
              Export as JPG
            </ExportButton>
          )}
          {hasExportPdf && (
            <ExportButton>
              <div
                className="export-img export-pdf"
                onClick={() => handleExportPdf()}
              ></div>
              Export as PDF
            </ExportButton>
          )}
          {hasExportCsv && (
            <ExportButton>
              <div
                className="export-img export-csv"
                onClick={() => handleExportCsv()}
              ></div>
              Export as CSV
            </ExportButton>
          )}
        </ExportContainer>
        <ShareWrapper>
          <ShareContainer>
            <ShareSocialButton
              className="share-facebook"
              href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
              target="_blank"
              rel="noopener noreferrer"
            />
            <ShareSocialButton
              className="share-linkedin"
              href={`https://www.linkedin.com/shareArticle?url=${shareLink}`}
              target="_blank"
              rel="noopener noreferrer"
            />
            <ShareSocialButton
              className="share-twitter"
              href={`https://twitter.com/intent/tweet?text=${shareLink}`}
              target="_blank"
              rel="noopener noreferrer"
            />
            <ShareSocialButton
              className="share-whatsapp"
              href={`https://api.whatsapp.com/send?text=${shareLink}`}
              target="_blank"
              rel="noopener noreferrer"
            />
            <ShareSocialButton
              className="share-telegram"
              href={`https://t.me/share/url?url=${shareLink}`}
              target="_blank"
              rel="noopener noreferrer"
            />
          </ShareContainer>
          <ShareInput
            defaultValue={shareLink}
            fluid
            action={
              <Popup
                trigger={
                  <Button
                    content="Copy"
                    onClick={() => handleShareLinkCopy()}
                  />
                }
                content="Copied"
                on="click"
                open={openCopyLinkPopup}
                position="right center"
                size="mini"
                inverted
              />
            }
            input={<input readOnly />}
          />
        </ShareWrapper>
      </ShareExportModalContent>
    </ShareExportModal>
  );
}

export default ShareExportModalComponent;
