import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { isEmpty } from 'ramda';

import TabsView from '../components/common/TabsView';
import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';
import '../sass/components/ProfileView.scss';
import Avatar from './common/Avatar';
import ContentPlaceholder from './common/ContentPlaceholder';
import GoalCard from './common/GoalCard';
import Milestone from './common/Milestone';
import Tooltip from './common/Tooltip';
import Post from './feed/Post';

const TABS = [
  {
    id: 1,
    title: 'About',
    key: 'about',
  },
  // {
  //   id: 2,
  //   title: 'Posts',
  //   key: 'posts',
  // },
  {
    id: 3,
    title: 'Experience',
    key: 'experience',
  },
];

const INIT_DATA = [
  { url: 'common_data/goals', object: 'goals' },
  { url: 'common_data/objectives', object: 'objectives' },
  { url: 'common_data/categories', object: 'categories' },
  { url: 'common_data/subcategories', object: 'subcategories' },
];

const ITEMS_PER_LOAD = 5;

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
});

class ProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      user_status: {},
      activeTab: TABS[0].key,
      goals: [],
      objectives: [],
      experience: {},
      categories: [],
      subcategories: [],
      solutions: [],
      posts: [],
      loadNumber: 0,
      noMore: false,
      isOrganization: false,
      profileLoaded: false,
      postsLoaded: false,
      isOwnProfile: false,
    };
  }

  componentDidMount = () => {
    this.initData();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.initData();
    }

    const { data } = this.state;
    const { data: prev_data } = prevState;
    if (isEmpty(prev_data) && !isEmpty(data) && data.id) {
      this.getUserStatus();
      this.getUserExperience();
    }
  }

  initData = () => {
    INIT_DATA.forEach((item) =>
      this.getData(item.url, item.object, item.data, item.callback)
    );
    if (this.props.match.path === '/profile') {
      this.setState({ data: this.props.user });
      this.afterProfileLoad();
    } else if (this.props.match.path.includes('user')) {
      this.getData(
        `users/${this.props.match.params.identifier}`,
        'data',
        null,
        () => {
          this.afterProfileLoad();
        }
      );
    } else {
      this.getData(
        `business_profiles/${this.props.match.params.identifier}`,
        'data',
        null,
        () => this.setState({ profileLoaded: true })
      );
      this.getData(`solutions`, 'solutions', {
        profile_id: this.props.match.params.identifier,
      });
      this.setState({ isOrganization: true });
    }
  };

  getUserExperience = () => {
    let userId;
    if (this.props.match.params.identifier) {
      userId = this.state.data.id;
    } else {
      const userJSON = localStorage.getItem('thesdg-client:user_attributes');
      const user = JSON.parse(userJSON);
      userId = user['custom:railsAppId'];
    }
    sendRequest({
      method: `user/experience/${userId}`,
      type: 'GET',
      node: true,
      success: (data) => {
        if (data) {
          this.state.experience = data;
        }
      },
      error: (data) => {},
    });
  };

  getUserStatus = () => {
    let userId;
    if (this.props.match.params.identifier) {
      userId = this.state.data.id;
    } else {
      const userJSON = localStorage.getItem('thesdg-client:user_attributes');
      const user = JSON.parse(userJSON);
      userId = user['custom:railsAppId'];
    }
    sendRequest({
      method: `user/${userId}`,
      type: 'GET',
      node: true,
      success: (data) => {
        if (data) {
          this.setState({ user_status: data });
        }
      },
      error: (data) => {},
    });
  };

  getPostsRequestData = () => {
    if (this.props.user && this.props.match.path === '/profile') {
      return { user_id: this.props.user.id };
    } else if (this.props.match.path.includes('user')) {
      return { user_id: this.state.data.id };
    } else {
      return { business_profile_id: this.props.match.params.identifier };
    }
  };

  getData = (url, object, data = null, callback = null) => {
    if (data === 'getPostsRequestData') data = this.getPostsRequestData();
    sendRequest({
      method: url,
      data,
      type: 'GET',
      success: (data) => {
        this.setState({ [object]: data });
        if (callback) {
          callback(this, data);
        }
      },
      error: (data) => {},
    });
  };

  afterProfileLoad = () => {
    this.setState({
      profileLoaded: true,
      isOwnProfile: this.checkProfileOwner(),
    });
    this.getData(`posts`, 'posts', 'getPostsRequestData', (ctx, data) =>
      this.afterPostRequest(data)
    );
  };

  afterPostRequest = (data) => {
    if (data.length < ITEMS_PER_LOAD) {
      this.setState({
        noMore: true,
        postsLoaded: true,
      });
    } else {
      this.setState((prevState) => ({
        loadNumber: prevState.loadNumber + 1,
        noMore: data.length < ITEMS_PER_LOAD,
        postsLoaded: true,
      }));
    }
  };

  paramRole = () => {
    if (this.props.match.path.includes('user')) {
      return 'individual';
    } else {
      return 'organization';
    }
  };

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  checkProfileOwner = () => {
    if (this.props.user && this.props.match.path === '/profile') {
      return true;
    } else if (this.props.match.path.includes('user')) {
      return this.props.user && this.state.data.id === this.props.user.id;
    } else {
      return this.state.data.user_id === this.props.user.id;
    }
  };

  handleSocialLinkClick = (url) => {
    const link = url.indexOf('http') === 0 ? url : `https://${url}`;
    window.open(link, '_blank');
  };

  handleEditProfileClick = () => {
    const userId = this.props.user.slug || this.props.user.id;
    if (this.props.match.path === '/profile') {
      this.props.history.push(`/user/${userId}/edit`);
    } else if (this.props.match.path.includes('user')) {
      this.props.history.push(`/user/${userId}/edit`);
    } else {
      this.props.history.push(`/business/${this.state.data.id}/edit`);
    }
  };

  loadMore = () => {
    const data = this.getPostsRequestData();
    data.offset = this.state.loadNumber * ITEMS_PER_LOAD;
    data.limit = ITEMS_PER_LOAD;
    sendRequest({
      type: 'GET',
      method: 'posts',
      data,
      success: (data) => {
        if (data.length === 0) {
          triggerEvent('showSnackbar', [{ text: 'Sorry, no more posts.' }]);
          this.setState({
            noMore: true,
          });
        } else {
          this.setState((prevState) => ({
            posts: prevState.posts.concat(data),
            loadNumber: prevState.loadNumber + 1,
            noMore: data.length < ITEMS_PER_LOAD,
          }));
        }
      },
      error: (data) => {},
    });
  };

  ownProfile = () => {
    const { businessProfile, user } = this.props;
    return businessProfile ? businessProfile : user;
  };

  connectToChat = () => {
    if (!this.props.user) {
      triggerEvent('showSnackbar', [
        { text: 'Please, sign up or log in first.' },
      ]);
      return;
    }
    const profile_data = this.state.data;
    const partnerType = profile_data.chat_id
      ? 'business_profile_id'
      : 'user_id';
    const data = {
      partner: {
        [partnerType]: profile_data.id,
      },
    };
    if (this.props.businessProfile) {
      data['business_profile_id'] = this.props.businessProfile.id;
    }

    sendRequest({
      type: 'POST',
      method: 'chats',
      data,
      success: (data) => {
        this.props.history.push(`/chats/${data.id}`);
      },
      error: (data) => {},
    });
  };

  claimRemoveProfile = (key) => {
    this.props.history.push({
      pathname: '/claim-profiles',
      state: { businessProfile: key, company_url: window.location.href },
    });
  };
  renderGoal = (item) => {
    return (
      <div
        key={item.key}
        className="goalCell"
        style={{
          borderColor: item.color,
        }}
      >
        <div className="goalCellContent">
          <label>{item.title}</label>
          <div className={`goalImage ${item.key}`} />
        </div>
      </div>
    );
  };

  renderSolution = (item) => {
    const { categories, subcategories } = this.state;
    return (
      <div key={item.id} className="solutionItem">
        <div className="solutionTags">
          <span>
            {
              categories.find((category) => category.id === item.category_id)
                ?.name
            }
          </span>
          <span className="spacer">&bull;</span>
          <span>
            {
              subcategories.find(
                (subcategory) => subcategory.id === item.subcategory_id
              )?.name
            }
          </span>
        </div>
        <div className="solutionName">{item.name}</div>
        <div className="solutionDescription">{item.description}</div>
      </div>
    );
  };

  renderAbout = () => {
    const { data, isOrganization, solutions, profileLoaded } = this.state;
    const description = data.bio ?? data.description ?? '';
    return (
      <>
        {profileLoaded ? (
          <div
            className="about"
            dangerouslySetInnerHTML={{
              __html: `<p>${description}</p>`,
            }}
          />
        ) : (
          <ContentPlaceholder type="profileAbout" />
        )}
        <div className="interestedTitle title">Interested UN SDGs</div>
        {profileLoaded ? (
          <div className="goalsTable">
            {data && data.goal_ids
              ? data.goal_ids.map((id) => {
                  const item = this.state.goals.find((i) => i.id === id);
                  if (!!item)
                    return (
                      <Link
                        key={item.key}
                        to={`/organization-profiles?goal=${item.key}`}
                      >
                        <GoalCard active item={item} />
                      </Link>
                    );
                })
              : null}
          </div>
        ) : (
          <ContentPlaceholder type="profileGoals" />
        )}
        {profileLoaded && isOrganization && solutions.length > 0 ? (
          <div className="solutionsContainer">
            <div className="solutionsTitle title">Solutions provided</div>
            {solutions.map(this.renderSolution)}
          </div>
        ) : null}
      </>
    );
  };

  renderPosts = () => {
    return (
      <div className="postsTimeline">
        {this.state.posts &&
          this.state.posts.map((post) => (
            <Post
              post={post}
              user={this.props.user}
              author={post.author}
              likeCount={post.likes_count}
              publishDateTime={post.created_at}
              article={post.author_title}
              isLiked={post.liked}
              key={post.id}
            />
          ))}
        {this.state.noMore ? null : (
          <div className="loadMore" onClick={this.loadMore}>
            <p>Load more posts</p>
          </div>
        )}
      </div>
    );
  };

  renderContacts = () => {
    const profile = this.state.data;
    return (
      <div className="contacts">
        {profile.website ? (
          <div
            className="web"
            onClick={() => this.handleSocialLinkClick(profile.website)}
          >
            <p>Visit website</p>
          </div>
        ) : null}
        {profile.twitter_url ? (
          <div
            className="twitter"
            onClick={() =>
              this.handleSocialLinkClick(
                `https://twitter.com/${profile.twitter_url}`
              )
            }
          >
            <p>{profile.twitter_url}</p>
          </div>
        ) : null}
        {profile.linkedin_url ? (
          <div
            className="linkedin"
            onClick={() => this.handleSocialLinkClick(profile.linkedin_url)}
          >
            <p>Visit LinkedIn</p>
          </div>
        ) : null}
        {profile.facebook_url ? (
          <div
            className="facebook"
            onClick={() =>
              this.handleSocialLinkClick(
                `https://facebook.com/${profile.facebook_url}`
              )
            }
          >
            <p>{profile.facebook_url}</p>
          </div>
        ) : null}
        {profile.instagram_url ? (
          <div
            className="instagram"
            onClick={() =>
              this.handleSocialLinkClick(
                `https://instagram.com/${profile.instagram_url}`
              )
            }
          >
            <p>{profile.instagram_url}</p>
          </div>
        ) : null}
      </div>
    );
  };

  renderContactsPlaceholder = () => {
    return (
      <div className="contacts">
        <div className="web">
          <ContentPlaceholder type="profileContact" />
        </div>
        <div className="twitter">
          <ContentPlaceholder type="profileContact" />
        </div>
        <div className="linkedin">
          <ContentPlaceholder type="profileContact" />
        </div>
        <div className="facebook">
          <ContentPlaceholder type="profileContact" />
        </div>
        <div className="instagram">
          <ContentPlaceholder type="profileContact" />
        </div>
      </div>
    );
  };

  renderLookingToGoals = () => {
    const { data } = this.state;
    const objectives = this.props.businessProfile
      ? this.state.objectives.filter(
          (objective) =>
            ['any', 'organisation'].indexOf(objective.objective_type) > -1
        )
      : this.state.objectives.filter(
          (objective) =>
            ['any', 'individual'].indexOf(objective.objective_type) > -1
        );

    return data && data.objective_ids
      ? data.objective_ids.map((id) => {
          const item = objectives.find((i) => i.id === id);
          return (
            <>
              {item ? (
                <div className="connectItem" key={item.id}>
                  {item.title}
                </div>
              ) : null}
            </>
          );
        })
      : null;
  };

  renderLookingToProfileType = () => {
    const { data } = this.state;
    return data && data.profile_type ? (
      <div className="connect">
        <div className="blockTitle title">Profile Type</div>
        <div className="connectItem">{data.profile_type} </div>
      </div>
    ) : null;
  };

  renderConnect = () => {
    const { isOwnProfile, profileLoaded } = this.state;
    return (
      <div className="connect">
        <div className="blockTitle title">Looking to...</div>
        {profileLoaded
          ? this.renderLookingToGoals()
          : [...Array(3)].map((e, i) => (
              <ContentPlaceholder key={i} type="profileLookingTo" />
            ))}
        {isOwnProfile ? null : (
          <>
            <button
              className="connectButton"
              onClick={() => this.connectToChat()}
            >
              Connect
            </button>
          </>
        )}
      </div>
    );
  };

  renderClaimRemoveLink = () => {
    const { userProfile, userprofile } = this.state.data;
    return this.props.location.pathname.includes('business') &&
      !this.state?.data?.user_id ? (
      <div className="claim_remove_link">
        <div className="link_div">
          <a
            href=""
            className="claim_remove_btn"
            key={userProfile}
            onClick={() => this.claimRemoveProfile(this.state?.data)}
          >
            I'd like to claim / remove profile
          </a>
        </div>
      </div>
    ) : null;
  };

  renderLynxButtons = () => {
    const { user_status } = this.state;

    if (user_status.expert_status === 'approved' && user_status.lynk_url) {
      return (
        <div className="lynk-container">
          <a
            className="lynk-button"
            href={user_status.lynk_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="lynk-button-icon"></div>
            <div className="lynk-button-text">Request for Consultation</div>
          </a>
        </div>
      );
    }

    return <></>;
  };

  renderProfile = () => {
    const profile = this.state.data;
    const activeTab = this.state.activeTab;
    const { isOwnProfile, profileLoaded, isProfileType, isOrganization } =
      this.state;
    return (
      <>
        {profile.cover_photo ? (
          <div
            className="profileBanner"
            style={{
              backgroundImage: `url(${profile.cover_photo})`,
            }}
          />
        ) : null}
        <div className="container">
          {profileLoaded ? (
            <div className="profileHeader">
              <Avatar
                imageUrl={profile.image}
                username={profile.name}
                className={
                  ('profileImage',
                  {
                    ownProfile: isOwnProfile,
                  })
                }
                big
              />
              <div className="profileNameLocation">
                <div className="profileName title">
                  <span className="profileNameHeader">
                    {profile.name}
                    {this.state.user_status &&
                    this.state.user_status.expert_status === 'approved' ? (
                      <span className="profile_status">
                        <ProfileStatusIcon isEnabled={true} shouldShow={true} />
                        <p className="profile_status_label">{''}</p>
                      </span>
                    ) : this.state.user_status &&
                      this.state.user_status.expert_status === 'pending' ? (
                      <Tooltip
                        // direction={'right'}
                        content={
                          <>
                            <p>
                              Your Expert status is currently
                              <br />
                              being reviewed by TheSDGCo and LYNK
                            </p>
                          </>
                        }
                      >
                        <span className="profile_status">
                          <ProfileStatusIcon
                            isEnabled={false}
                            shouldShow={true}
                          />
                          <p className="profile_status_label">{'Pending'}</p>
                        </span>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </span>
                  {/* {profile.subscribed ? <div className="subscribed" /> : null} */}
                </div>
                {!this.state.isOrganization && profile.description ? (
                  <div className="profileSubtitle">{profile.description}</div>
                ) : null}
                {profile.country_name ? (
                  <div className="profileLocation">
                    {profile.country_name}
                    {profile.city ? `, ${profile.city}` : null}
                  </div>
                ) : null}
              </div>
              {isOwnProfile ? (
                <div
                  className="editProfileBtn"
                  onClick={this.handleEditProfileClick}
                >
                  Edit Profile
                </div>
              ) : null}
              {this.props.user && (
                <>
                  {(isOrganization &&
                    profile.manager_ids.includes(this.props.user.id)) ||
                  profile.user_id == this.props.user.id ? (
                    <div
                      className="editProfileBtn"
                      onClick={this.handleEditProfileClick}
                    >
                      Edit Profile
                    </div>
                  ) : null}
                </>
              )}
            </div>
          ) : (
            <ContentPlaceholder type="profileHeader" />
          )}
          <TabsView
            tabs={TABS.filter((tab) => {
              if (tab.key !== 'experience') {
                return true;
              } else {
                return (
                  this.state.user_status &&
                  ['pending', 'approved'].includes(
                    this.state.user_status.expert_status
                  )
                );
              }
            })}
            onTabChange={this.changeTab}
            tab={activeTab}
            leftAligned
          />
          <div className="flex">
            <div className="left"></div>
            <div className="right">{this.renderClaimRemoveLink()}</div>
          </div>
          <div className="flex">
            <div className="left">
              {activeTab === 'posts' ? (
                profileLoaded ? (
                  this.renderPosts()
                ) : (
                  [...Array(2)].map((element, index) => (
                    <div key={index}>
                      <ContentPlaceholder type="post" />
                    </div>
                  ))
                )
              ) : activeTab === 'experience' ? (
                <>
                  <div className="experience-label">Work Experience</div>
                  <div className="timeline-container">
                    {this.state.experience.workExperience.map((e, i) => (
                      <Milestone
                        key={`${e.id}-${i}`}
                        type="work"
                        role={e.role}
                        company={e.company}
                        start_date={e.start_date}
                        end_date={e.end_date}
                        details={e.details}
                        isLast={
                          i === this.state.experience.workExperience.length - 1
                        }
                      />
                    ))}
                  </div>
                  <div className="experience-label">Business Experience</div>
                  <div className="timeline-container">
                    {this.state.experience.businessExperience.map((e, i) => (
                      <Milestone
                        key={`${e.id}-${i}`}
                        type="business"
                        business_expertise={e.business_expertise}
                        company={e.company}
                        countries_regions={e.countries_regions}
                        details={e.details}
                        isLast={
                          i ===
                          this.state.experience.businessExperience.length - 1
                        }
                      />
                    ))}
                  </div>
                  <div className="tags-container">
                    {this.state.experience.topicTags &&
                      this.state.experience.topicTags.tags
                        .split(',')
                        .map((tag) => <div className="tag">{tag}</div>)}
                  </div>
                </>
              ) : (
                this.renderAbout()
              )}
            </div>
            <div className="right">
              {this.renderLynxButtons()}
              {profileLoaded
                ? this.renderContacts()
                : this.renderContactsPlaceholder()}
              {this.renderConnect()}
              <br></br>
              {this.renderLookingToProfileType()}
            </div>
          </div>
        </div>
      </>
    );
  };

  render = () => {
    return (
      <div className="profileView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/thesdg.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/thesdg.png" />
        </Helmet>
        {this.renderProfile()}
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(ProfileView));
const ProfileStatusIcon = ({ shouldShow = false, isEnabled = false }) => {
  return (
    shouldShow && (
      <svg
        width="26"
        height="26"
        color={isEnabled ? '#F26E26' : ''}
        viewBox="0 0 27 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.64893 23.7329C6.91554 23.5493 6.81399 23.2735 7.22022 22.7175C7.90935 21.7739 8.60066 20.8317 9.29488 19.8917C9.61768 19.4548 9.96805 19.4453 10.3017 19.8736C11.5502 21.477 12.7928 23.0847 14.0347 24.6932C14.1254 24.8108 14.2625 24.9458 14.1711 25.1048C14.0746 25.2717 13.9005 25.1977 13.7518 25.1629C11.6938 24.6809 9.63654 24.1989 7.64893 23.7329Z"
          fill="currentColor"
        />
        <path
          d="M8.49687 18.5787C9.09461 18.795 9.20342 19.1122 8.83346 19.621C8.1465 20.5668 7.45736 21.5096 6.76533 22.4518C6.39827 22.9512 6.07982 22.941 5.76644 22.4177C4.68558 20.6147 2.50429 16.9651 2.49485 16.944C2.4332 16.809 2.29392 16.6718 2.41796 16.523C2.53838 16.3793 2.7045 16.4628 2.84523 16.5129C3.26814 16.6617 7.14399 18.0887 8.49687 18.5787Z"
          fill="currentColor"
        />
        <path
          d="M17.5122 18.4546C17.6616 18.0256 17.4563 17.7861 16.9942 17.8543C16.2935 17.9588 11.3948 18.7631 10.9567 18.8516C10.607 18.9227 10.5127 19.1862 10.6905 19.4954C10.7376 19.5767 14.3131 24.22 14.649 24.6316C14.9276 24.9727 15.1989 24.9306 15.3635 24.5278C15.4397 24.3441 16.8869 20.2525 17.5122 18.4546Z"
          fill="currentColor"
        />
        <path
          d="M23.9835 11.8407C22.0118 11.117 20.0373 10.3999 18.0671 9.67255C17.7943 9.57166 17.5984 9.53682 17.4156 9.76837C17.2931 9.92297 17.3612 10.1632 17.3837 10.3157C17.6645 12.2109 17.9524 14.1046 18.2397 15.9991C18.3369 16.6422 18.593 16.7525 19.1051 16.362C19.7087 15.9011 23.0803 13.349 24.1554 12.5237C24.4681 12.2827 24.3825 11.9873 23.9835 11.8407Z"
          fill="currentColor"
        />
        <path
          d="M16.9744 16.8611C16.9098 16.7936 16.8315 16.737 16.7553 16.6811C15.068 15.4464 10.281 11.9456 9.57304 11.4317C9.44029 11.3352 9.29303 11.2103 9.12546 11.3127C8.95789 11.415 9.00069 11.6045 9.02463 11.7663C9.0696 12.0813 9.72973 16.6208 9.87481 17.6029C9.9517 18.1219 10.2332 18.4115 10.6894 18.3469C11.4794 18.2351 16.5689 17.4026 16.854 17.33C17.1369 17.2581 17.1768 17.0723 16.9744 16.8611Z"
          fill="currentColor"
        />
        <path
          d="M15.9989 8.1944C15.7857 7.8968 13.1931 4.55498 12.1209 3.17441C11.8003 2.7614 11.5029 2.79987 11.3288 3.28546C11.006 4.18552 9.59796 8.18641 9.24686 9.18736C9.05318 9.73973 9.24541 9.97781 9.83082 9.87837C10.9697 9.68529 12.1064 9.4806 13.2446 9.28027C14.1049 9.22002 14.9486 9.04074 15.7929 8.8825C16.1433 8.81718 16.2216 8.50433 15.9989 8.1944Z"
          fill="currentColor"
        />
        <path
          d="M9.33255 17.7248C9.36737 17.4018 9.26871 17.0926 9.22374 16.779C8.98072 15.0718 8.72828 13.3661 8.47439 11.6611C8.40258 11.1805 8.12474 11.018 7.778 11.2735C7.00907 11.8403 6.25827 12.4319 5.49949 13.014C4.526 13.7435 3.54815 14.4665 2.58263 15.2076C2.27071 15.4471 2.32148 15.7033 2.68129 15.8848C2.75455 15.9218 2.83145 15.9537 2.90906 15.9821C3.67582 16.2593 4.4433 16.5337 5.20933 16.811C6.38667 17.2371 7.56183 17.6697 8.74207 18.087C9.14829 18.23 9.28975 18.1284 9.33255 17.7248Z"
          fill="currentColor"
        />
        <path
          d="M25.3606 10.4917C25.2917 10.7283 25.3062 10.9011 25.1009 10.8967C24.9783 10.8946 24.8172 10.7567 24.7353 10.6209C23.7552 8.99356 22.7796 7.36403 21.8054 5.73304C21.7531 5.64594 21.6595 5.5298 21.7604 5.44705C21.8511 5.37302 21.9577 5.46738 22.0426 5.52907C23.1089 6.30719 24.1796 7.07949 25.238 7.86849C25.6413 8.169 25.7929 8.58491 25.674 9.08865C25.5637 9.56046 25.4629 10.033 25.3606 10.4917Z"
          fill="currentColor"
        />
        <path
          d="M1.71222 16.8419C1.85222 16.8993 1.92331 17.011 1.98932 17.1221C2.96064 18.7436 3.93124 20.3652 4.89748 21.9897C4.95334 22.0833 5.07303 22.2052 4.96059 22.3054C4.84598 22.407 4.74369 22.2626 4.6581 22.2002C3.58812 21.4235 2.51887 20.6454 1.4576 19.8571C1.10795 19.5973 0.946188 19.2205 1.01873 18.7974C1.11448 18.2392 1.26464 17.6912 1.39667 17.1395C1.43584 16.9733 1.5033 16.8201 1.71222 16.8419Z"
          fill="currentColor"
        />
        <path
          d="M12.9768 2.03628C12.8767 1.99926 12.6772 1.99346 12.6417 1.79312C12.6061 1.59206 12.7795 1.50931 12.9188 1.42366C13.3214 1.17687 13.7261 0.932986 14.1324 0.692003C14.6264 0.399485 15.1059 0.45102 15.5585 0.779831C16.5806 1.52238 17.5976 2.27146 18.6161 3.01908C18.6829 3.06772 18.7547 3.11562 18.804 3.1795C18.8787 3.27604 18.8671 3.36169 18.7213 3.36532C18.6517 3.36749 18.5806 3.35225 18.5117 3.33628C16.6829 2.9073 14.8563 2.4776 12.9768 2.03628Z"
          fill="currentColor"
        />
        <path
          d="M16.662 25.8549C16.5133 25.8121 16.345 25.7765 16.3276 25.5885C16.3117 25.4223 16.4553 25.3454 16.575 25.2728C17.2924 24.8395 20.5553 22.8891 21.47 22.3483C21.5643 22.2924 21.6848 22.1705 21.7849 22.2772C21.8937 22.3933 21.7435 22.487 21.6804 22.5748C20.9405 23.5983 20.1948 24.6181 19.457 25.643C19.1001 26.1395 18.6402 26.3304 18.0403 26.1801C17.6392 26.0807 16.7186 25.8716 16.662 25.8549Z"
          fill="currentColor"
        />
        <path
          d="M10.3889 2.21391C10.3338 2.34383 10.2395 2.41424 10.1372 2.47594C8.4985 3.45947 6.85908 4.44372 5.21821 5.42435C5.13334 5.47516 5.0209 5.56879 4.93312 5.46427C4.8439 5.35902 4.96359 5.26902 5.0209 5.18917C5.80434 4.11056 6.5856 3.03049 7.38065 1.96131C7.6476 1.60274 8.03351 1.44668 8.47384 1.5287C9.00701 1.62742 9.53366 1.76025 10.061 1.888C10.2322 1.92937 10.4128 1.98599 10.3889 2.21391Z"
          fill="currentColor"
        />
        <path
          d="M26.6694 15.6154C26.607 15.847 26.5018 16.0052 26.3901 16.1576C25.6705 17.1397 24.9487 18.1211 24.2277 19.1024C24.2066 19.1307 24.1878 19.1619 24.1631 19.1866C24.092 19.2599 24.0362 19.4058 23.9223 19.355C23.7837 19.2933 23.8635 19.1452 23.8867 19.04C23.9738 18.6357 24.0724 18.2336 24.1675 17.8307C24.504 16.4037 24.8414 14.9767 25.1779 13.5497C25.2157 13.3885 25.2331 13.1998 25.4268 13.1548C25.6328 13.1069 25.7133 13.2891 25.7974 13.4255C26.0571 13.8487 26.3096 14.2762 26.562 14.7038C26.7412 15.0064 26.7651 15.328 26.6694 15.6154Z"
          fill="currentColor"
        />
        <path
          d="M13.5526 25.6758C13.7594 25.7259 14.035 25.7847 14.0698 25.9581C14.117 26.2499 12.9244 26.8437 12.5813 27.0527C12.1286 27.3285 11.6666 27.3126 11.2487 27.0164C10.1889 26.2652 8.02283 24.6654 7.98729 24.6342C7.92563 24.5805 7.84003 24.5246 7.87848 24.4331C7.9191 24.3359 8.02719 24.37 8.09973 24.3845C8.48129 24.4643 12.088 25.3245 13.5526 25.6758Z"
          fill="currentColor"
        />
        <path
          d="M7.54796 9.95428C7.37967 9.80693 7.18888 9.68572 7.00753 9.55289C6.14938 8.9243 5.29485 8.29135 4.43161 7.67075C3.9659 7.33613 3.67138 7.45807 3.53863 8.01698C3.48133 8.25869 2.31705 13.2438 2.04937 14.358C2.01746 14.4901 2.00512 14.6317 2.14223 14.705C2.27715 14.7769 2.39177 14.6796 2.4955 14.6019C3.35293 13.9588 6.75582 11.3893 7.58641 10.7295C7.8461 10.5241 7.80911 10.1829 7.54796 9.95428Z"
          fill="currentColor"
        />
        <path
          d="M19.2257 4.04095C18.6062 3.89723 13.7409 2.77724 12.964 2.57328C12.8291 2.53771 12.6651 2.48473 12.5657 2.61756C12.4482 2.77507 12.5853 2.9108 12.6746 3.02694C13.9295 4.6572 15.1845 6.28746 16.4488 7.90974C16.7557 8.30315 17.1068 8.28719 17.4129 7.88143C17.6537 7.56206 19.0937 5.58411 19.5739 4.91488C19.8865 4.47719 19.7523 4.16362 19.2257 4.04095Z"
          fill="currentColor"
        />
        <path
          d="M10.75 2.92177C10.6383 2.81361 10.5049 2.86878 10.3902 2.93846C9.35581 3.56487 5.63737 5.79178 4.8358 6.26577C4.29754 6.58441 4.28449 6.91032 4.7988 7.28849C5.74038 7.98023 6.68341 8.66979 7.62861 9.35572C8.24231 9.80139 8.47299 9.65404 8.74864 8.92093C8.74864 8.92093 10.4272 4.23701 10.7558 3.28905C10.7979 3.16856 10.8618 3.02919 10.75 2.92177Z"
          fill="currentColor"
        />
        <path
          d="M24.3179 10.9698C24.2664 10.8639 21.4576 6.15889 20.9252 5.28061C20.651 4.82768 20.3275 4.80881 20.0141 5.22835C19.6543 5.71031 18.247 7.62947 17.9002 8.1049C17.5013 8.65219 17.6239 8.96576 18.2731 9.15666C18.3326 9.19077 23.6999 11.1586 24.0459 11.2776C24.1395 11.3103 24.2352 11.3204 24.307 11.2377C24.3781 11.1549 24.3607 11.0577 24.3179 10.9698Z"
          fill="currentColor"
        />
        <path
          d="M23.1667 19.7726C23.3031 19.2231 23.4206 18.6693 23.5468 18.1177C23.5759 18.0364 23.5962 17.9565 23.607 17.8789C23.6673 17.6393 23.7282 17.3991 23.7848 17.1588C24.08 15.9089 24.3724 14.659 24.6669 13.4091C24.6973 13.2784 24.7227 13.1398 24.6016 13.0505C24.4906 12.9685 24.3702 13.0331 24.2773 13.1057C23.4032 13.7836 22.4885 14.4086 21.6455 15.1272C20.8599 15.7093 20.0714 16.2878 19.2909 16.8764C18.8455 17.2118 18.8549 17.5653 19.3119 17.9021C20.3093 18.6359 21.309 19.3661 22.3115 20.0934C22.7337 20.4005 23.0391 20.2887 23.1667 19.7726Z"
          fill="currentColor"
        />
        <path
          d="M21.7261 20.3168C20.7998 19.6446 19.8793 18.9638 18.95 18.296C18.5801 18.0296 18.2159 18.1015 18.0802 18.4884C17.7908 19.3122 16.2486 23.6397 15.965 24.4534C15.9185 24.5855 15.8576 24.7408 15.9954 24.8461C16.1158 24.9383 16.2428 24.849 16.3574 24.7909C16.4205 24.759 20.9122 22.0646 21.6732 21.6124C22.5089 21.1159 22.5176 20.8916 21.7261 20.3168Z"
          fill="currentColor"
        />
        <path
          d="M17.7039 16.2274C17.7126 16.1222 17.1301 12.0726 16.845 10.1549C16.7551 9.55248 16.4656 9.32964 15.8672 9.42037C14.9793 9.55466 10.5579 10.2914 10.0697 10.3698C9.89923 10.3974 9.68959 10.3959 9.63446 10.6093C9.5786 10.8263 9.77011 10.9156 9.90866 11.018C10.8684 11.725 17.2991 16.4198 17.3318 16.4328C17.5392 16.5178 17.6836 16.4764 17.7039 16.2274Z"
          fill="currentColor"
        />
        <path
          d="M0.820582 10.0279C0.758923 10.1121 0.626899 10.0395 0.665345 9.94224C1.10857 8.8041 1.54381 7.67758 1.98994 6.55469C2.07264 6.34565 2.26922 6.25491 2.48539 6.30064C2.73783 6.35436 2.88582 6.53872 2.9424 6.78769C2.98447 6.9706 2.9366 7.14481 2.82924 7.29143C2.16259 8.20528 1.49158 9.1155 0.821308 10.0264L0.820582 10.0279Z"
          fill="currentColor"
        />
        <path
          d="M24.2882 6.31885C24.3158 6.37474 24.2701 6.43499 24.2084 6.4241C24.112 6.40741 22.318 5.0791 21.4961 4.48245C21.3075 4.34599 21.1501 4.18703 21.1414 3.93879C21.1327 3.67022 21.2285 3.44448 21.4867 3.33343C21.6971 3.2427 21.8871 3.32182 22.0242 3.49167C22.7678 4.41132 24.2766 6.29635 24.2882 6.31885Z"
          fill="currentColor"
        />
        <path
          d="M21.0045 24.7516C20.9066 24.8264 20.7811 24.703 20.8551 24.6043C20.8667 24.5883 20.879 24.5738 20.8899 24.5585C21.5232 23.6904 22.1601 22.8252 22.7977 21.96C22.8326 21.9128 22.8703 21.8656 22.9131 21.825C23.1691 21.5847 23.5732 21.5985 23.8031 21.8547C24.0077 22.0819 23.9598 22.3672 23.6544 22.6154C22.8021 23.3086 21.9454 23.996 21.0879 24.6834C21.0597 24.7059 21.0328 24.7298 21.0045 24.7516Z"
          fill="currentColor"
        />
        <path
          d="M17.1735 1.27281C17.1444 1.222 17.193 1.16248 17.2496 1.17772C17.761 1.31636 19.8705 2.16996 20.4748 2.40659C20.9006 2.57353 21.0319 2.80653 20.8781 3.11574C20.7098 3.45399 20.3181 3.56722 19.9706 3.34947C19.8741 3.28922 19.5383 3.05114 19.1313 2.75645C18.3174 2.16633 17.217 1.35047 17.1735 1.27281Z"
          fill="currentColor"
        />
        <path
          d="M2.46512 21.4864C2.40128 21.4008 2.49631 21.289 2.59061 21.3391C2.65953 21.3761 2.71538 21.4378 2.77704 21.4821C3.59965 22.078 4.41574 22.6819 5.23835 23.2771C5.42043 23.4085 5.56188 23.5588 5.57711 23.7888C5.59525 24.0574 5.50602 24.2868 5.24778 24.4015C5.00622 24.5089 4.81036 24.4 4.6573 24.2098C3.94495 23.3272 3.23405 22.4431 2.5246 21.5583C2.50574 21.5343 2.48325 21.5111 2.46512 21.4864Z"
          fill="currentColor"
        />
        <path
          d="M5.92418 2.9819C5.4708 3.76147 4.05698 5.62836 3.88869 5.8403C3.68775 6.09217 3.42805 6.15895 3.13064 6.03338C2.7556 5.87514 2.67145 5.47085 2.98628 5.21172C3.90465 4.45465 4.83824 3.71646 5.76604 2.97101C5.80666 2.93617 5.84728 2.93254 5.92418 2.9819Z"
          fill="currentColor"
        />
        <path
          d="M25.8878 17.7284C25.9465 17.6674 26.0481 17.7284 26.0234 17.8097C25.9705 17.9875 25.1653 20.1055 24.8149 20.9962C24.6495 21.4172 24.4159 21.542 24.1025 21.3881C23.7681 21.2241 23.6528 20.8147 23.8835 20.4794C24.2781 19.9052 24.6981 19.3485 25.1087 18.7859C25.3321 18.4804 25.845 17.7734 25.8878 17.7284Z"
          fill="currentColor"
        />
        <path
          d="M9.50674 26.4C9.59307 26.4624 9.52343 26.5981 9.42115 26.5691C9.36311 26.5524 9.30798 26.5212 9.2514 26.4994C8.22785 26.1038 7.20575 25.7046 6.18148 25.3112C5.92324 25.2125 5.74624 25.068 5.80427 24.761C5.85867 24.4706 6.11837 24.2761 6.42377 24.2914C6.56957 24.2986 6.69942 24.3516 6.81694 24.4365C7.69395 25.076 8.57097 25.7155 9.44508 26.3593C9.46394 26.3724 9.48643 26.3847 9.50674 26.4Z"
          fill="currentColor"
        />
        <path
          d="M0.840925 17.3099C0.810458 17.4456 0.80393 17.638 0.630558 17.6293C0.458637 17.6206 0.469518 17.397 0.446305 17.254C0.296871 16.3322 0.175728 15.3958 0.0371754 14.4718C0.0212165 14.3651 -0.0564021 14.2025 0.0756219 14.151C0.235211 14.0886 0.369412 14.4145 0.381744 14.4348C1.21596 15.7573 1.18549 15.7609 0.840925 17.3099Z"
          fill="currentColor"
        />
        <path
          d="M16.8864 26.4553C16.8856 26.4553 16.9683 26.4705 17.0409 26.5068C17.17 26.5714 17.149 26.6476 17.0343 26.7057C16.9836 26.7318 14.9125 27.0606 13.9347 27.2102C13.8367 27.2254 13.6975 27.2798 13.6445 27.163C13.5734 27.0055 14.5607 26.4821 14.9241 26.2636C15.1599 26.1221 15.408 26.0945 15.672 26.1606C16.0492 26.2542 16.4279 26.3442 16.8864 26.4553Z"
          fill="currentColor"
        />
        <path
          d="M25.9044 10.4327C25.953 10.2999 25.9464 10.1111 26.0676 10.1075C26.2156 10.1032 26.2148 10.2657 26.2323 10.3753C26.3868 11.3545 26.5391 12.3337 26.6864 13.3143C26.7009 13.4123 26.7596 13.5597 26.6486 13.6068C26.5065 13.6671 26.4608 13.5038 26.4042 13.4123C26.1757 13.0399 25.9573 12.6625 25.7317 12.288C25.5954 12.0615 25.5779 11.8212 25.6382 11.5708C25.7296 11.1919 25.9037 10.4356 25.9044 10.4327Z"
          fill="currentColor"
        />
        <path
          d="M9.90628 1.30702C9.80545 1.28017 9.60959 1.23952 9.60161 1.12411C9.59146 0.975307 9.84752 0.970226 9.981 0.948451C10.8791 0.804006 12.8485 0.532538 12.9428 0.523828C13.003 0.518021 13.0662 0.518021 13.093 0.5848C13.1198 0.651578 13.0778 0.703839 13.0284 0.732873C12.5301 1.02539 12.0665 1.37816 11.5247 1.59301C11.3781 1.65108 11.22 1.62422 11.0698 1.58865C10.6904 1.50082 10.343 1.42388 9.90628 1.30702Z"
          fill="currentColor"
        />
        <path
          d="M1.50184 14.3445C1.68464 13.6129 2.57036 9.80723 2.84602 8.63933C2.86488 8.56094 2.92436 8.43754 2.81337 8.38601C2.70674 8.33665 2.64798 8.4448 2.5943 8.51811C2.13802 9.1467 0.682126 11.1936 0.16346 11.9231C0.0365135 12.1016 0.0082228 12.3325 0.0894684 12.5364C0.302738 13.0736 0.769175 14.0709 1.04048 14.4737C1.19136 14.6973 1.43075 14.6298 1.50184 14.3445Z"
          fill="currentColor"
        />
      </svg>
    )
  );
};
