import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import ProfileCard from './common/ProfileCard';
import ContentPlaceholder from './common/ContentPlaceholder';
import HeaderMeta from './common/HeaderMeta';

import { sendRequest } from '../helpers/RequestDispatcher.js';
import Storage from '../helpers/Storage.js';

import '../sass/components/ListOrganizationsView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class ListOrganizationsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded: false,
      removeOrganizationIds: [],
      removeStaffIds: [],
    };
  }

  componentDidMount = () => {
    this.getData();
  };

  getData = () => {
    sendRequest({
      method: 'business_profiles_managing',
      data: {
        user_id: this.props.user.id,
      },
      type: 'GET',
      success: (data) => {
        this.setState({ data, loaded: true });
      },
      error: (data) => {},
    });
  };

  handleAddOrganizationClick = () => {
    this.props.history.push('wizard/organization');
  };

  handleExistingOrganizationClick = () => {
    this.props.history.push('/existing-organization');
  };

  handleProfileCardClick = (id) => {
    this.props.history.push(`business/${id}`);
  };

  handleRemoveOrganization = (item) => {
    const data = [...this.state.data];
    const index = data.map((e) => e.id).indexOf(item.id);
    if (index !== -1) {
      data.splice(index, 1);
      this.setState({ data });
    }
    if (item.user_id === this.props.user.id) {
      this.setState({
        removeOrganizationIds: [...this.state.removeOrganizationIds, item.id],
      });
    } else if (item.manager_ids.includes(this.props.user.id)) {
      this.setState({
        removeStaffIds: [...this.state.removeStaffIds, item.id],
      });
    }
  };

  updateUserBusinessesData = () => {
    Storage.setData('user', {
      ...Storage.getData('user'),
      business_profiles: this.state.data,
    });
  };

  removeOrganization = (e, id) => {
    if (!e) return;
    sendRequest({
      method: `business_profiles_managing/${id}`,
      data: {
        id: id,
      },
      type: 'DELETE',
      success: (data) => {
        this.updateUserBusinessesData();
      },
      error: (data) => {},
    });
  };

  removeFromOrganizationStaff = (e, id) => {
    if (!e) return;
    sendRequest({
      method: `business_profiles_managing/${id}/stop_managing`,
      data: {
        id: id,
      },
      type: 'POST',
      success: (data) => {
        this.updateUserBusinessesData();
      },
      error: (data) => {},
    });
  };

  handleSaveChanges = () => {
    const { removeOrganizationIds, removeStaffIds } = this.state;
    removeOrganizationIds.forEach((id) => {
      this.removeOrganization(true, id);
    });
    removeStaffIds.forEach((id) => {
      this.removeFromOrganizationStaff(true, id);
    });
    if (this.props.onSave) {
      this.props.onSave();
    }
  };

  renderOrganizationsList = () => {
    return (
      <>
        <div
          className={classnames('listOrganizationsContainer', {
            noOrganizations: this.state.data.length === 0,
          })}
        >
          {this.state.data.length > 0
            ? this.state.data.map((item) => (
                <ProfileCard
                  key={item.id}
                  type="organizations"
                  {...item}
                  onClick={() => this.handleProfileCardClick(item.id)}
                  onRemove={() => this.handleRemoveOrganization(item)}
                  removable
                />
              ))
            : null}
          <div
            className="profileCard addNew"
            onClick={this.handleAddOrganizationClick}
          >
            <div className="text">Add or Join Organisation</div>
          </div>
          {/* <div
            className="profileCard addNew"
            onClick={this.handleExistingOrganizationClick}
          >
            <div className="existingText">Existing Organisation</div>
          </div> */}
        </div>
        {this.renderOrganizationControls()}
      </>
    );
  };

  renderOrganizationControls = () => {
    return (
      <div className="organizationsControls">
        {this.state.data.length > 0 ? (
          <button onClick={this.handleSaveChanges}>
          {this.props.wizard ? 'Continue' : 'Save changes'}
        </button>
        ) : null}
      </div>
    );
  };

  render = () => {
    return (
      <div className="listOrganizationsView">
        <HeaderMeta />
        <section>
          <div className="sectionHeader">
            <div className="headerGroup">
              <div className="header">Organisations</div>
              <div className="subheader">
                Organisations that you are currently working at. This will show
                up on your profile.
              </div>
            </div>
          </div>
          {this.state.loaded ? (
            this.renderOrganizationsList()
          ) : (
            <div className="userPlaceholderContainer">
              {[...Array(4)].map((e, i) => (
                <div key={i} className="userPlaceholderItem">
                  <ContentPlaceholder type="user" />
                </div>
              ))}
            </div>
          )}
        </section>
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(ListOrganizationsView));
