import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import ProfileCard from './common/ProfileCard';
import AutocompleteInput from './input/AutocompleteInput';

import { sendRequest } from '../helpers/RequestDispatcher.js';
import { triggerEvent } from '../helpers/global';

import '../sass/components/EditStaffView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class EditStaffView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      users: null,
      search: null,
      staff: [],
    };
    this.timer = null;
  }

  componentDidMount = () => {
    this.getData();
  }

  getData = () => {
    sendRequest({
      method: `business_profiles_managing/${this.props.organizationId}`,
      type: 'GET',
      success: (data) => {
        this.setState({ data });
        if (data.manager_ids.length > 0) this.requestStaff();
      },
      error: (data) => {
      }
    });
  }

  requestStaff = () => {
    sendRequest({
      method: `users`,
      type: 'GET',
      data: {
        user_ids: this.state.data.manager_ids,
      },
      success: (data) => {
        this.setState({ staff: data });
      },
      error: (data) => {
      }
    });
  }

  handleProfileCardClick = (identifier) => {
    const link = window.open(`/user/${identifier}`, '_blank');
    link.focus();
  }

  handleSearch = (value) => {
    clearTimeout(this.timer);
    this.setState({
      search: value,
    });
    if (value) {
      this.timer = setTimeout(this.triggerSearch, WAIT_INTERVAL);
    } else {
      this.setState({ users: null });
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer)
      this.triggerSearch()
    }
  }

  triggerSearch = () => {
    sendRequest({
      method: 'users',
      data: {
        name: this.state.search,
        limit: 5,
      },
      type: 'GET',
      success: (data) => {
        this.setState({ users: data.filter(user => !this.state.staff.map(staff => staff.id).includes(user.id)) });
      },
      error: (data) => {
      }
    });
  }

  handleSelect = (item) => {
    let filteredStaff = this.state.staff.filter(staff => staff.id !== item.id);
    this.setState({ staff: [...filteredStaff, item], users: [...this.state.users.filter(user => user.id !== item.id)] });
  }

  handleRemoveUser = (item) => {
    triggerEvent('showConfirmation', [{
      title: 'Are you sure you want to remove this user from staff?',
      overlayBlock: true,
      callback: (e) => this.removeUser(e, item.id)
    }]);
  }

  removeUser = (e, id) => {
    if (!e) return;
    this.setState({ staff: this.state.staff.filter(i => i.id !== id) });
  }

  handleSaveClick = () => {
    sendRequest({
      method: `business_profiles_managing/${this.props.organizationId}`,
      data: {
        manager_ids: this.state.staff.map(item => item.id),
      },
      type: 'PUT',
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Staff updated!', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    return (
      <div
        className='editStaffView'
      >
        <section>
          <div className='sectionHeader'>
            <div className='headerGroup'>
              <div className='header'>Add a Staff</div>
              <div className='subheader'>Staffs get access to chat and post as the Organisation.</div>
            </div>
          </div>
          <div className='filters'>
            <AutocompleteInput
              placeholder='Search an Individual to add'
              value={this.state.search}
              options={this.state.users}
              onChange={this.handleSearch}
              onSelect={this.handleSelect}
            />
          </div>
          <div className={classnames('listStaffContainer', { 'noStaff': this.state.staff.length === 0 })}>
            { this.state.staff.length > 0
              ? this.state.staff.map(item => <ProfileCard key={item.id} type='individuals' {...item} onClick={() => this.handleProfileCardClick(item.slug || item.id)} onRemove={() => this.handleRemoveUser(item)} removable />)
              : <div className='noStaff'>You don't have any staff yet</div>
            }
          </div>
          <div className='actions'>
            <button className='saveButton' onClick={this.handleSaveClick}>Save Changes</button>
          </div>
        </section>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(EditStaffView));
