import React from 'react';
import classnames from 'classnames';

import '../../sass/components/common/SelectButton.scss';

class SelectButton extends React.Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.item);
    }
  }

  render = () => {
    return (
      <div 
        className={classnames('selectButton', {
          'selected': this.props.selected,
          'small': this.props.small,
        })}
        onClick={this.handleClick}
      >
        {this.props.item.label}
      </div>
    )
  }

}

export default SelectButton;
