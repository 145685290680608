import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/SidebarOverlay.scss';

class SidebarOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: !props.show,
      show: false,
      title: null,
      content: null,
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showSidebarOverlay', this.showSidebarOverlay);
      eventOn('hideSidebarOverlay', this.hideSidebarOverlay);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showSidebarOverlay', this.showSidebarOverlay);
      eventOff('hideSidebarOverlay', this.hideSidebarOverlay);
    }
  }

  showSidebarOverlay = (e) => {
    const { title, content } = e.detail[0];
    this.setState({
      hidden: false,
      show: true,
      title,
      content,
    });
  }

  hideSidebarOverlay = () => {
    this.setState({show: false});
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.hideSidebarOverlay();
    }
  }

  render = () => {
    const { show, title, content } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'sidebarOverlay': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              <div className='closeButton' onClick={() => this.hideSidebarOverlay()}/>
              <div className='popupTitle'>{title}</div>
              {content}
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default SidebarOverlay;
