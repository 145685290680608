import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { triggerEvent, eventOn } from './helpers/global.js';
import { sendRequest } from './helpers/RequestDispatcher.js';
import Storage from './helpers/Storage.js';
import UpdateNotificationsController from './helpers/UpdateNotificationsController';

import LoadIndicator from './components/LoadIndicator';
import ConfirmationPopup from './components/common/ConfirmationPopup';
import ContentPopup from './components/common/ContentPopup';
import Snackbar from './components/common/Snackbar';
import SidebarOverlay from './components/common/SidebarOverlay';

import HeaderView from './components/HeaderView';
import FooterView from './components/FooterView';

import JotForm from './components/JotForm';

import NotFound from './components/NotFound';
import HomeView from './components/HomeView';
import AboutView from './components/AboutView';
import RegisterView from './components/RegisterView';
import RestorePasswordView from './components/RestorePasswordView';
import WizardView from './components/WizardView';
import ProfileView from './components/ProfileView';
import ChangePasswordView from './components/ChangePasswordView';
import FeedView from './components/feed/FeedView';
import PostView from './components/PostView';
import BrowseView from './components/BrowseView';
import DirectoryView from './components/DirectoryView';
import ListOrganizationsView from './components/ListOrganizationsView';
import ClaimProfilesView from './components/ClaimProfileView';
import ExistingOrganizationView from './components/ExistingOrganizationView';
import ChatListView from './components/ChatListView';
import PrivacyView from './components/PrivacyView';
import TermsView from './components/TermsView';
import LoginView from './components/LoginView';
import EventView from './components/EventView';
import DirectoryAirtableView from './components/DirectoryAirtableView';
import DirectoryAirtableAdd from './components/DirectoryAirtableAdd';
import OrganisationSearch from './components/searches/organisations';
import IndividualSearch from './components/searches/individuals';
import ContentSearch from './components/searches/content';
import BookExpertView from './components/BookExpertView';

// Market Map
import MarketMapSdgListView from './components/market_map/MarketMapSdgListView.js';
import MarketMapListView from './components/market_map/MarketMapListView.js';
import MarketMapCompanyListView from './components/market_map/MarketMapCompanyListView.js';
import MarketMapCompanyView from './components/market_map/MarketMapCompanyView.js';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

const UserRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!rest.user) return <Redirect to="/login" />;
      return <Component user={rest.user} {...props} />;
    }}
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !rest.user ? (
        <Component user={rest.user} {...props} />
      ) : (
        <Redirect to="/home" />
      )
    }
  />
);

const AnyRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <Component user={rest.user} {...props} />}
  />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoadIndicator: false,
    };
    this.loadCount = 0;

    eventOn('addLoad', this.addLoad);
    eventOn('removeLoad', this.removeLoad);
    eventOn('unauthorized', this.onUnauthorized);
    eventOn('logout', () => this.onLogout(false));

    eventOn(
      'resize',
      (e) => {
        Storage.setSetup('isMobile', window.innerWidth <= 768);
        this.updateCssVars();
      },
      window
    );
    Storage.setSetup('isMobile', window.innerWidth <= 768);
    this.checkWebpSupport();
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      eventOn(
        'resize',
        (e) => {
          Storage.setSetup('isMobile', window.innerWidth <= 768);
        },
        window
      );

      Storage.setSetup('isMobile', window.innerWidth <= 768);
      Storage.setSetup('webpSupported', true);
    }

    if (this.props.user) {
      sendRequest({
        method: 'me',
        type: 'GET',
        success: (data) => {
          Storage.setData('user', data);
        },
        error: (data) => {},
      });
    }

    this.updateCssVars();
    this.checkWebpSupport();
  };

  checkWebpSupport = () => {
    const supportsWebp = this.supportsWebp();
    if (supportsWebp) {
      Storage.setSetup('webpSupported', true);
      document.documentElement.classList.remove('no-webp');
    } else {
      Storage.setSetup('webpSupported', false);
    }
  };

  supportsWebp = () => {
    const elem = document.createElement('canvas');
    if (!!(elem.getContext && elem.getContext('2d'))) {
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
  };

  updateCssVars = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  addLoad = () => {
    this.loadCount++;
    if (this.state.showLoadIndicator !== this.loadCount > 0) {
      this.setState({ showLoadIndicator: this.loadCount > 0 });
    }
  };

  removeLoad = () => {
    this.loadCount--;
    if (this.state.showLoadIndicator !== this.loadCount > 0) {
      this.setState({ showLoadIndicator: this.loadCount > 0 });
    }
  };

  onLogout = (unauthorized) => {
    if (!unauthorized) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
      });
    }
    Storage.setData('user', null);
    Storage.setData('businessProfile', null);
    localStorage.removeItem('thesdg-client:user_attributes');
  };

  onUnauthorized = () => {
    triggerEvent('showSnackbar', [
      {
        text: 'Your access token has been expired. Please re-login.',
        type: 'error',
      },
    ]);
    this.onLogout(true);
  };

  render = () => {
    const { user, location } = this.props;
    // full screen excluding paths
    const fullScreen = [/\/register/].some(
      (regex) => !regex.test(location.pathname)
    );
    const page =
      location.pathname === '/'
        ? 'home'
        : location.pathname.includes('business') ||
          location.pathname.includes('user') ||
          location.pathname.includes('feed') ||
          (location.pathname.includes('partner') &&
            !location.pathname.includes('partnerships')) ||
          location.pathname.includes('wizard')
        ? 'custom_header'
        : location.pathname.replace('/', '-').substring(1);
    return (
      <div className={`app ${page}-page`}>
        <div
          className={classnames({
            appContent: true,
            customScrollBar: true,
            fullScreen: fullScreen,
          })}
        >
          {location.pathname === '/register' ? null : (
            <HeaderView transparentSearch={fullScreen} />
          )}
          <Switch>
            <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />

            {/*<UserRoute user={user} path='/edit_profile' component={EditProfileView}/>*/}
            <UserRoute
              user={user}
              path="/chats/:id?"
              component={ChatListView}
            />
            <UserRoute
              user={user}
              path="/user/:user_identifier?/edit"
              component={WizardView}
            />
            <UserRoute
              user={user}
              path="/business/:business_id?/edit"
              component={WizardView}
            />
            <UserRoute user={user} path="/profile" component={ProfileView} />
            <UserRoute
              user={user}
              path="/organizations"
              component={ListOrganizationsView}
            />
            <AnyRoute
              user={user}
              path="/user/:identifier?"
              component={ProfileView}
            />
            <AnyRoute
              user={user}
              path="/business/:identifier?"
              component={ProfileView}
            />
            <AnyRoute
              user={user}
              path="/partner/:key?"
              component={DirectoryView}
            />
            <AnyRoute
              user={user}
              path="/wizard/:role?"
              component={WizardView}
            />
            <AnyRoute user={user} path="/feed/:key?" component={FeedView} />
            <AnyRoute
              user={user}
              path="/organization-profiles"
              component={OrganisationSearch}
            />
            <AnyRoute
              user={user}
              path="/individual-profiles"
              component={IndividualSearch}
            />
            <AnyRoute
              user={user}
              path="/claim-profiles"
              component={ClaimProfilesView}
            />
            <AnyRoute
              user={user}
              path="/existing-organization"
              component={ExistingOrganizationView}
            />

            <AnyRoute user={user} path="/content" component={ContentSearch} />
            <AnyRoute user={user} path="/post/:id" component={PostView} />
            <AnyRoute user={user} path="/tlc2022" component={JotForm} />
            <AnyRoute
              user={user}
              path="/change_password"
              component={ChangePasswordView}
            />
            <AnyRoute user={user} path="/about" exact component={AboutView} />
            <AnyRoute user={user} path="/events" exact component={EventView} />
            <AnyRoute
              user={user}
              path="/directory"
              exact
              component={DirectoryAirtableView}
            />
            <AnyRoute
              user={user}
              path="/add-directory"
              exact
              component={DirectoryAirtableAdd}
            />
            <AnyRoute user={user} path="/search" component={DirectoryView} />
            <AnyRoute user={user} path="/browse" exact component={BrowseView} />
            {/*TODO: enable this view by subscribe view*/}
            {/*<AnyRoute
                user={user}
                path="/subscribe"
                component={SubscribeView}
              />*/}
            <AnyRoute user={user} path="/privacy" component={PrivacyView} />
            <AnyRoute user={user} path="/terms" component={TermsView} />
            <AnyRoute
              user={user}
              path="/market-maps/sdg"
              component={MarketMapSdgListView}
            />
            <AnyRoute
              user={user}
              path="/market-maps/companies/:slug"
              component={MarketMapCompanyView}
            />
            <AnyRoute
              user={user}
              path="/market-maps/companies"
              component={MarketMapCompanyListView}
            />
            <AnyRoute
              user={user}
              path="/market-maps/:goal_slug/:market_map_slug"
              component={MarketMapCompanyListView}
            />
            <AnyRoute
              user={user}
              path="/market-maps/:slug"
              component={MarketMapListView}
            />
            <AnyRoute
              user={user}
              path="/market-maps"
              component={MarketMapListView}
            />
            <LoginRoute user={user} path="/register" component={RegisterView} />
            <LoginRoute
              user={user}
              path="/reset_password/:token"
              component={RestorePasswordView}
            />
            <LoginRoute user={user} path="/login" component={LoginView} />
            <AnyRoute
              user={user}
              path="/book-expert"
              component={BookExpertView}
              exact
            />
            <AnyRoute user={user} path="/" component={HomeView} exact />
            <AnyRoute user={user} path="/home" component={HomeView} exact />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
          {fullScreen && <FooterView />}
        </div>

        <LoadIndicator show={this.state.showLoadIndicator} />
        <UpdateNotificationsController />
        <ConfirmationPopup global />
        <ContentPopup global />
        <SidebarOverlay global />
        <Snackbar />
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(App));
