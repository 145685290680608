import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { sendRequest } from '../helpers/RequestDispatcher.js';
import Storage from '../helpers/Storage.js';
import HeaderMeta from './common/HeaderMeta.js';
import TargetActivityCard from './common/TargetActivityCard';
import { ReactComponent as ChevronRightSVG } from './../images/icons/chevron-right.svg';
import GoalSvgList from './common/GoalSvgList';
import '../sass/components/HomeView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  webpSupported: store.setup.webpSupported,
  setup: store.setup,
});

class HomeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goals: [],
      objectives: [],
      webpSupported: true,
      counters: {
        connections: 1000,
        profiles: 450,
        countries: 60,
      },
    };
  }

  componentDidMount = () => {
    this.getCommonData();
    this.getCommonDataObjectives();
    this.getCounters();
    if (typeof window !== 'undefined') {
      this.getSetting();
    }
  };

  getCounters = () => {
    sendRequest({
      method: 'common_data/counters',
      type: 'GET',
      success: (data) => {
        this.setState((prevState) => ({
          counters: {
            connections:
              data.connections < 1000
                ? 1000 + data.connections
                : data.connections,
            profiles: data.profiles < 450 ? 450 + data.profiles : data.profiles,
            countries:
              data.countries < 60 ? 60 + data.countries : data.countries,
          },
        }));
      },
      error: (data) => {},
    });
  };

  getCommonData = () => {
    sendRequest({
      method: 'common_data/goals',
      type: 'GET',
      success: (data) => {
        this.setState({ goals: data });
      },
      error: (data) => {},
    });
  };

  getCommonDataObjectives = () => {
    sendRequest({
      method: 'common_data/objectives',
      type: 'GET',
      success: (data) => {
        this.setState({ objectives: data });
      },
      error: (data) => {},
    });
  };

  getSetting = () => {
    this.setState({ webpSupported: Storage.getSetup('webpSupported') });
  };

  renderWelcomeBlock = () => {
    const user = this.props.user;

    return (
      <div className="section-welcome">
        <div className="section-content">
          <div className="content-header-lg">
            Transforming Innovation for Sustainability
          </div>
          <div className="left-content">
            <div className="eco-business text-caption">
              <span>An</span>
              <div className="eco-image"></div>
              <span>Initiative</span>
            </div>
            <p>
              The SDG Co is an ecosystem of start-ups, entrepreneurs, technology
              providers and investors that support sustainability innovations to
              reach markets and achieve scale.
            </p>
            <Link to={user ? '/profile' : '/register'} className="button">
              Join the Ecosystem
            </Link>
          </div>
        </div>
      </div>
    );
  };

  renderGoalsBlock = () => {
    const goals = this.state.goals;

    return (
      <div className="section-goals">
        <div className="section-content">
          <div className="section-content__header">
            <div className="left-content">
              <div className="content-header">
                Connecting organisations that are working on UN Sustainable
                Development Goals
              </div>
              <p>
                We are the world's 1st sustainability innovation marketplace. We
                help start-ups, entrepreneurs, technology providers and
                investors working on the UN Sustainable Development Goals to
                efficiently and effectively connect with one other.
              </p>
            </div>
            <div className="right-content" />
          </div>
          <Link
            to="/organization-profiles?goal=all-sdgs"
            className="button button-dark-gray"
          >
            See All SDGs
            <ChevronRightSVG />
          </Link>
          <div className="section-content__goals">
            {goals
              .filter((goal) => goal.id <= 17)
              .map((goal) => {
                const SvgComponent = GoalSvgList[`goal_${goal.id}`];
                return (
                  <Link
                    to={`/organization-profiles?goal=${goal.key}`}
                    className="goal-box"
                    style={{ backgroundColor: goal.color }}
                  >
                    <div className="goal-box__id">{goal.id}</div>
                    <div className="goal-box__svg-container">
                      <SvgComponent />
                    </div>
                    <div className="goal-box__name">{goal.name}</div>
                  </Link>
                );
              })}
          </div>
          <Link
            to="/organization-profiles?goal=other-sustainability-solutions"
            className="button button-dark-gray"
          >
            Other Sustainability Solutions
            <ChevronRightSVG />
          </Link>
        </div>
      </div>
    );
  };

  renderImpactBlock = () => {
    const objectives = this.state.objectives;

    return (
      <div className="section-impacts">
        <div className="section-content">
          <div className="left-content">
            <div className="content-header">What you can do on The SDG Co</div>
            <p>Check out what people use our platform for</p>
          </div>
          <div className="right-content">
            {objectives.map((item) => (
              <Link
                key={item.id}
                to={
                  ['market-maps'].includes(item.key)
                    ? '/market-maps'
                    : `/partner/${item.key}`
                }
              >
                <TargetActivityCard item={item} link />
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  };

  renderPartnersBlock = () => (
    <div className="section-partners">
      <div className="section-content">
        <div className="content-header">Founding Partners</div>
        <div className="partner-list">
          <a
            href="https://www.theliveabilitychallenge.org/"
            target="_blank"
            rel="noopener noreferrer"
            className="livability_challenge"
          />
          <a
            href="https://www.greengrowthknowledge.org/initiatives/global-opportunities-sdgs-go4sdgs"
            target="_blank"
            rel="noopener noreferrer"
            className="go4sdgs"
          />
          <a
            href="https://www.ixsa.earth/"
            target="_blank"
            rel="noopener noreferrer"
            className="ixsa"
          />
        </div>
        <div className="partner-list">
          <a
            href="https://www.ebimpact.org/"
            target="_blank"
            rel="noopener noreferrer"
            className="eb_impact"
          />
          <a
            href="https://fundedhere.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="fundedhere"
          />
          <a
            href="https://tembusupartners.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="tembusu"
          />
          <a
            href="https://www.climateactionsg.org/"
            target="_blank"
            rel="noopener noreferrer"
            className="sg_alliance"
          />
        </div>
      </div>
    </div>
  );

  // renderManifestBlock = () => {
  //   const flyerItems = [
  //     'Intentionality validated by measurable and transparent accountability',
  //     'There is no one size fits all approach',
  //     'Diversity and inclusion extends beyond people',
  //     'Symbiotic network effects are exponential',
  //     'Mental wellness is the backbone for sustained impact',
  //     'Doing good must be rewarded better than doing bad.',
  //   ];
  //   return (
  //     <div className="manifestBlock">
  //       <div className="manifestBackground" />
  //       <div className="manifestContent">
  //         <div className="manifestIcon" />
  //         <div className="manifestTitle">
  //           Innovation <span>x</span> Sustainability
  //         </div>
  //         <div className="manifestSubtitle">Manifesto</div>
  //         <div className="manifestFlyer">
  //           <div
  //             className={classnames('flyerBackground', {
  //               webp: this.state.webpSupported,
  //             })}
  //           />
  //           <div className="flyerContent">
  //             <p>
  //               IxSA is a group of practitioners working in the intersection of
  //               innovation and sustainability who have come together to forge a
  //               manifesto.
  //             </p>
  //             <p>
  //               We are transforming the way we innovate for sustainability by
  //               focusing on six core principles that have pronounced
  //               consequences in achieving impact:
  //             </p>
  //             {flyerItems.map((item, i) => (
  //               <div key={i} className="flyerItem">
  //                 <span>{i + 1}</span>
  //                 <p>{item}</p>
  //               </div>
  //             ))}
  //             <p
  //               className="findOutMore"
  //               onClick={() => window.open('https://www.ixsa.earth/', '_blank')}
  //             >
  //               Find out more here.
  //             </p>
  //             <div className="flyerIcon" />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  render = () => {
    return (
      <>
        <HeaderMeta />
        {this.renderWelcomeBlock()}
        {this.renderGoalsBlock()}
        {this.renderImpactBlock()}
        {this.renderPartnersBlock()}
      </>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(HomeView));
