import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import CheckboxInput from '../components/input/CheckboxInput';
import { Helmet } from 'react-helmet';
import TextInput from '../components/input/TextInput';
import SelectInput from './input/SelectInput';
import { sendRequest } from '../helpers/RequestDispatcher.js';
import Avatar from './common/Avatar';
import { triggerEvent } from '../helpers/global.js';
import ProfileCard from './common/ProfileCard';
import GoalCard from './common/GoalCard';
import ReCAPTCHA from 'react-google-recaptcha';

import '../sass/components/ExistingOrganizationView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  isMobile: store.setup.isMobile,
});

const INIT_DATA = [
  {
    url: 'common_data/organizations',
    object: 'organizations',
    callback: (ctx, data) => ctx.afterUsersRequest(data),
  },
  { url: 'common_data/organizations', object: 'organizations' },
];

class ExistingOrganizationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: {},
      users: [],
      posts: [],
      organizations: [],
      objectives: [],
      usersLoaded: false,
      postsLoaded: false,
      adsTagLoaded: false,
      disabledsubmit: true,
      organization_id: '',
      errors: {},
    };
  }

  componentDidMount = () => {
    sendRequest({
      method: 'common_data/organizations',
      type: 'GET',
      success: (data) => {
        this.setState({ organizations: data.common_data });
      },
      error: (data) => {},
    });
  };

  saveClaimProfile = (key) => {
    const organization_id = this.state.organization_id;
    const user_id = this.props.user.id;
    triggerEvent('addLoad');
    sendRequest({
      method: 'business_profiles/existing_profiles',
      type: 'POST',
      data: {
        id: organization_id,
        user_id: user_id,
      },
      noLoad: true,
      success: (data) => {
        triggerEvent('removeLoad');
        triggerEvent('showSnackbar', [
          {
            text: 'You have Successfully follow this Profile',
            type: 'success',
          },
        ]);
        if (this.props.wizard) {
          if (this.props.onSave) {
            this.props.onSave();
          }
        } else {
          this.props.history.push(`/organizations`);
        }
      },
      error: (data) => {
        triggerEvent('removeLoad');
      },
    });
  };

  renderOrganizationsWithKeyIds = () => {
    const organizations = [];
    if (!this.state || !this.state.organizations) {
      return [];
    }
    this.state.organizations?.forEach((organization) => {
      organizations.push({
        id: organization.id,
        key: organization.id,
        name: organization.name,
      });
    });
    return organizations;
  };

  renderExitingOrganization = () => {
    return (
      <div className="registerForm">
        <div className="formContent">
          <div className="formTitle"></div>
          <div className="wish_div">
            <SelectInput
              options={this.renderOrganizationsWithKeyIds()}
              value={this.state.organization_id}
              key="organization_id"
              onChange={(value) =>
                this.setState({
                  organization_id: value,
                })
              }
              placeholder="Select Organisation"
            />
          </div>
          <button
            className="submitClaimBtn"
            onClick={() => this.saveClaimProfile(this.state.organization_id)}
          >
            {this.props.wizard ? 'Continue' : 'Submit'}
          </button>
        </div>
      </div>
    );
  };

  render = () => {
    const { posts, goals, businessProfile } = this.state;

    const business = this.props.history.location.state?.businessProfile;
    return (
      <div className="browseView">
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/thesdg.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/thesdg.png" />
        </Helmet>
        <section className="sdgGoals">
          <div className="profileClaim">
            <div className="authorNameTime">
              <div className="authorName">Choose Existing Organisation</div>
            </div>
          </div>
          <div className="">{this.renderExitingOrganization()}</div>
        </section>
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(ExistingOrganizationView));
