export const apiUrl = (apiMethod) => {
  return process.env.REACT_APP_SERVER_URL + '/api/v1/' + apiMethod;
};

export const authUrl = (apiMethod) => {
  return process.env.REACT_APP_SERVER_URL + '/api/v1/' + apiMethod;
};

export const nodeV1ApiUrl = (apiMethod) => {
  return process.env.REACT_APP_NODE_SERVER_URL + '/api/v1/' + apiMethod;
};

export const WSUrl = () => {
  return (
    process.env.REACT_APP_SERVER_URL.replace(/^http/, 'ws') +
    '/cable' +
    '?token=' +
    localStorage.getItem('thesdg-client:token')
  );
};

export const getUrlParameter = (sParam, location) => {
  location = location || window.location.search;
  var sPageURL = decodeURIComponent(location.substring(1));
  var sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
  return false;
};

export const triggerEvent = (name, data) => {
  let event;
  if (window.CustomEvent) {
    event = new CustomEvent(name, { detail: data });
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, true, data);
  }
  document.dispatchEvent(event);
};

export const triggerNativeEvent = (elem, name) => {
  var event = document.createEvent('HTMLEvents');
  event.initEvent(name, true, false);
  elem.dispatchEvent(event);
};

export const triggerClick = (elem) => {
  var event = document.createEvent('MouseEvents');
  event.initMouseEvent(
    'click',
    true,
    true,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  );
  elem.dispatchEvent(event);
};

export const eventOn = (name, handler, parent) => {
  parent = parent || document;
  if (handler) {
    parent.addEventListener(name, handler);
  }
};

export const eventOff = (name, handler, parent) => {
  parent = parent || document;
  if (handler) {
    parent.removeEventListener(name, handler);
  }
};

export const isIOS = () => {
  return (
    (!window.MSStream && /iPad|iPhone|iPod/i.test(navigator.userAgent)) ||
    ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const httpToUrl = (url) => {
  return !!url && !/^http(s)?:\/\//i.test(url) ? 'http://' + url : url;
};
