import React from 'react';
import classnames from 'classnames';

import HeaderMeta from './common/HeaderMeta';

import Storage from '../helpers/Storage';

import '../sass/components/AboutView.scss';

class EventView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webpSupported: true,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.setState({ webpSupported: Storage.getSetup('webpSupported') });
    }
  };

  render = () => {
    return (
      <div
        className={classnames('aboutView background', {
          webp: this.state.webpSupported,
        })}
      >
        <HeaderMeta />
        <div className="content">
          <div className="content-header">Events</div>
          <div className="section">
            <p>
              If you'd like to add your event to our ecosystem calendar, please
              submit to this{' '}
              <a href="https://docs.google.com/forms/d/1JsjIr4tKRZgE4EVMcEJiXxyy3MyBbqbmI2UOqdO_Ou8/prefill">
                form
              </a>
              , and our team will publish for free to the community calendar.
            </p>
            <p>
              Should you have any questions about the calendar itself, please
              reach out to <a href="mailto: hello@thesdg.co">hello@thesdg.co</a>
              .
            </p>
          </div>
          <div className="section" style={{ width: '100%', marginBottom: 30 }}>
            <iframe
              src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&&mode=MONTH&showTitle=1&showDate=0&showPrint=0&src=Z3Jncm5zazVocDRjN2xzbmkwZ3FuaWlrZ2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=amVzc2VAa2VlcHNwYWNlLmNvbS5hdQ&color=%23D81B60&color=%23F6BF26"
              width="100%"
              height="500"
              frameBorder="0"
              scrolling="no"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };
}

export default EventView;
