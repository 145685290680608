import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { Modal, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { isEmpty, omit } from 'ramda';

import Icon, { IconSet } from '../../components/common/Icon';
import companiesFormatter, {
  keySeparator,
  modelSeparator,
  othersModelSeparator,
  loadCompaniesBase64Logos,
} from '../../helpers/companiesFormatter';
import { sendRequest } from '../../helpers/RequestDispatcher';
import { triggerEvent } from '../../helpers/global';
import { loadOptions, csvColumns } from '../../helpers/market_map';
import { remoteFileToDataUrl } from '../../helpers/file_helper';
import CardList from './shared/CardList';
import HeaderMeta from '../common/HeaderMeta';
import Information from './shared/Information';
import Breadcrumbs from './shared/Breadcrumbs';
import Dropdown from './shared/Dropdown';
import CompanyForm, { RemoteSubmit } from './shared/CompanyForm';
import ShareExportModal from './shared/ShareExportModal';
import './../../sass/components/market_map/MarketMapCompanyListView.scss';

const SelectedText = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 3px;
  font-size: 14px;
  line-height: 30px;
  color: #333333;
  padding: 10px 20px 10px 10px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media all and (min-width: 768px) {
    width: 200px;
  }

  &:after {
    content: url(/images/market_map/chevron-down-gray.svg);
    position: absolute;
    top: 9px;
    right: 10px;
  }
`;

function MarketMapCompanyListView(props) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [openDropdownGoal, setOpenDropdownGoal] = useState(false);
  const [optionsMarketMap, setOptionsMarketMap] = useState([]);
  const [selectedMarketMap, setSelectedMarketMap] = useState(null);
  const [openDropdownMarketMap, setOpenDropdownMarketMap] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [companiesFormatted, setCompaniesFormatted] = useState({});
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [information, setInformation] = useState(null);
  const [loadingInformation, setLoadingInformation] = useState(false);
  const [valueChainPositions, setValueChainPositions] = useState([]);
  const [selectedValueChainPosition, setSelectedValueChainPosition] =
    useState(null);
  const [valueChainCategories, setValueChainCategories] = useState([]);
  const [selectedValueChainCategory, setSelectedValueChainCategory] =
    useState(null);
  const [openDropdownValueChainCategory, setOpenDropdownValueChainCategory] =
    useState(false);
  const [openDropdownValueChain, setOpenDropdownValueChain] = useState(false);
  const params = useParams();
  const [expertList, setExpertList] = useState([]);
  const [tabIndex, setTabindex] = useState(0);
  const [openAddCompany, setOpenAddCompany] = useState(false);
  const [initialValuesCompany, setInitialValuesCompany] = useState({
    name: '',
    url: '',
    location: '',
    company_type_id: null,
    goal_id: null,
    market_map_id: null,
    value_chain_id: null,
    value_chain_type_id: null,
    description: '',
    funding_round: '',
    funding_amount: '',
    market_models: [],
    other_market_model: '',
    business_models: [],
    other_business_model: '',
    logo_base64: '',
    logo_filename: '',
  });
  const [csvData, setCsvData] = useState([]);
  const [isSubmitCompany, setIsSubmitCompany] = useState(false);
  const [savingAddCompany, setSavingAddCompany] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const printRef = useRef();
  const [loadingBase64Logos, setLoadingBase64Logos] = useState(false);
  const [preparedByLogos, setPreparedByLogos] = useState([]);
  const [loadingBase64ExpertImages, setLoadingBase64ExpertImages] =
    useState(false);
  const [loadingBase64PreparedLogos, setLoadingBase64PreparedLogos] =
    useState(false);
  const customListStyle = `
    padding: 7px;
    right: 0;
    left: unset;
    top: 77px;

    @media all and (min-width: 768px) {
      min-width: 200px;
      left: 0;
      right: unset;
    }

    @media all and (min-width: 1024px) {
      left: unset;
      right: 0;
    }
`;
  const customItemStyle = `
    padding: 10px;
    border-radius: 3px;
  `;

  const loadCompanies = () => {
    setLoadingBase64Logos(false);
    setLoadingCompanies(true);
    sendRequest({
      node: true,
      method: 'companies',
      type: 'GET',
      data: {
        market_map_id: selectedMarketMap.value,
        order: 'id',
        direction: 'asc',
      },
      success: (data) => {
        setCompanies(data.objects);
        const formattedData = companiesFormatter(data.objects);
        setCompaniesFormatted(formattedData);
        setLoadingCompanies(false);

        if (!isEmpty(formattedData)) {
          const positions = Object.keys(formattedData).map((chainPosition) => {
            const arrString = chainPosition.split(keySeparator);
            const text = arrString[0];
            const id = +arrString[1];

            return {
              key: chainPosition,
              text,
              value: id,
            };
          });
          setValueChainPositions(positions);
          setSelectedValueChainPosition(positions[0]);

          const categories = Object.keys(formattedData[positions[0].key]);
          if (!isEmpty(categories)) {
            const newCategories = categories.map((value) => {
              const arrString = value.split(keySeparator);
              const text = arrString[0];
              const id = +arrString[1];

              return {
                key: value,
                text,
                value: id,
              };
            });
            setValueChainCategories(newCategories);
          }
        }
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
        setLoadingCompanies(false);
      },
    });
  };

  const loadInformation = () => {
    const processLoadInformation = async () => {
      setLoadingInformation(true);

      const informationId = await new Promise((resolve) => {
        sendRequest({
          node: true,
          method: `informations/${selectedGoal.value}/${selectedMarketMap.value}`,
          type: 'GET',
          success: (data) => {
            const { id, market_map_experts } = data;
            if (market_map_experts && market_map_experts.length) {
              setExpertList(
                market_map_experts.map((value) => {
                  const { id, name, position, linkedin_url, profile_picture } =
                    value;
                  return {
                    id,
                    ...(profile_picture && { imageSrc: profile_picture }),
                    imgRounded: true,
                    title: name,
                    ...(position && { subtitle: position }),
                    ...(linkedin_url && {
                      link: linkedin_url,
                      linkText: 'View Profile',
                      linkNewTab: true,
                      linkTo: 'remote',
                    }),
                  };
                })
              );
            }
            setInformation(data);
            resolve(id);
          },
          error: (err) => {
            // triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
            setExpertList([]);
            setInformation(null);
            resolve(null);
          },
        });
      });

      if (informationId) {
        await new Promise((resolve) => {
          sendRequest({
            node: true,
            method: 'information-prepared-logos',
            type: 'GET',
            data: {
              information_id: informationId,
            },
            success: (data) => {
              setPreparedByLogos(data.objects);
              resolve(true);
            },
            error: (err) => {
              // triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
              resolve(false);
            },
          });
        });
      }

      setLoadingInformation(false);
    };

    processLoadInformation();
  };

  const handleOnSelectGoal = (goal) => {
    if (!selectedGoal || selectedGoal.value !== goal.value) {
      props.history.push(`/market-maps/${goal.key}`);
    }
    setOpenDropdownGoal(false);
  };

  const handleOnSelectMarketMap = (marketMap) => {
    if (!selectedMarketMap || selectedMarketMap.value !== marketMap.value) {
      props.history.push(`/market-maps/${params.goal_slug}/${marketMap.key}`);
      setSelectedMarketMap(marketMap);
    }
    setOpenDropdownMarketMap(false);
  };

  const handleOnSelectValueChainPosition = (valueChainPosition) => {
    setSelectedValueChainPosition(valueChainPosition);
    setSelectedValueChainCategory(null);
    setOpenDropdownValueChain(false);
  };

  const handleOnSelectValueChainCategory = (valueChainCategory) => {
    setSelectedValueChainCategory(
      valueChainCategory.value === 'All' ? null : valueChainCategory
    );
    setOpenDropdownValueChainCategory(false);
  };

  const handleOnAddCompany = (value_chain_type_id, value_chain_id) => {
    setInitialValuesCompany({
      ...initialValuesCompany,
      goal_id: selectedGoal.value,
      market_map_id: selectedMarketMap.value,
      value_chain_id,
      value_chain_type_id,
    });
    setOpenAddCompany(true);
  };

  const handleCloseModalCompany = () => {
    setOpenAddCompany(false);
    setIsSubmitCompany(false);
  };

  const handleRemoteSubmitFormCompany = () => {
    setIsSubmitCompany(true);
  };

  const handleSubmitAddCompany = (values) => {
    setSavingAddCompany(true);
    const market_models = values.market_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_market_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    const business_models = values.business_models
      .map((value) =>
        value === 'others'
          ? [value, values.other_business_model].join(othersModelSeparator)
          : value
      )
      .join(modelSeparator);
    sendRequest({
      node: true,
      method: 'company-requests',
      type: 'POST',
      data: {
        ...omit(
          [
            'goal_id',
            'other_market_model',
            'other_business_model',
            'value_chain_id',
            'company_type_name',
            'goal_name',
            'market_map_name',
            'value_chain_name',
            'value_chain_type_name',
          ],
          values
        ),
        market_models,
        business_models,
      },
      success: (_) => {
        triggerEvent('showSnackbar', [
          { text: 'Add Company request successful', type: 'success' },
        ]);
        setSavingAddCompany(false);
        setOpenAddCompany(false);
      },
      error: (err) => {
        setSavingAddCompany(false);
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
      },
    });
  };

  const handleOpenShareExport = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareExport = () => {
    setOpenShareModal(false);
  };

  const loadNewCompanyLogos = () => {
    setLoadingBase64Logos(true);
    const process = async () => {
      await loadCompaniesBase64Logos({
        companies: companiesFormatted,
        setter: setCompaniesFormatted,
      });
    };
    process();
  };

  const loadNewExpertImages = () => {
    setLoadingBase64ExpertImages(true);
    const process = async () => {
      const newExpertList = [...expertList];

      for (let i = 0; i < expertList.length; i++) {
        const expert = expertList[i];
        if (expert.imageSrc) {
          const base64String = await remoteFileToDataUrl(expert.imageSrc);
          if (base64String) {
            newExpertList[i].imageSrc = base64String;
          }
        }
      }

      setExpertList(newExpertList);
    };
    process();
  };

  const loadNewPreparedLogos = () => {
    setLoadingBase64PreparedLogos(true);
    const process = async () => {
      const newPreparedByLogos = [...preparedByLogos];

      for (let i = 0; i < preparedByLogos.length; i++) {
        const preparedByLogo = preparedByLogos[i];
        if (preparedByLogo.logo) {
          const base64String = await remoteFileToDataUrl(preparedByLogo.logo);
          if (base64String) {
            newPreparedByLogos[i].logo = base64String;
          }
        }
      }

      setPreparedByLogos(newPreparedByLogos);
    };
    process();
  };

  const getPrintRef = () => printRef;

  useEffect(() => {
    loadOptions({
      route: 'goals',
      setter: setOptionsGoal,
      isLocalStorage: true,
    });
  }, []);

  useEffect(() => {
    if (optionsGoal.length && params.goal_slug) {
      const findOption = optionsGoal.find(
        (value) => value.key === params.goal_slug
      );
      if (findOption) {
        setSelectedGoal(findOption);
        loadOptions({
          route: 'market-maps',
          setter: setOptionsMarketMap,
          additionalParams: {
            goal_id: +findOption.value,
          },
        });
      }
    }
  }, [optionsGoal, params.goal_slug]);

  useEffect(() => {
    if (optionsMarketMap.length && params.market_map_slug) {
      const findOption = optionsMarketMap.find(
        (value) => value.key === params.market_map_slug
      );
      if (findOption) {
        setSelectedMarketMap(findOption);
      }
    }
  }, [optionsMarketMap, params.market_map_slug]);

  useEffect(() => {
    if (selectedGoal && selectedMarketMap) {
      loadCompanies();
      loadInformation();
    }
  }, [selectedGoal, selectedMarketMap]);

  useEffect(() => {
    if (selectedValueChainPosition) {
      const categories = Object.keys(
        companiesFormatted[selectedValueChainPosition.key]
      );
      if (!isEmpty(categories)) {
        const newCategories = categories.map((value) => {
          const arrString = value.split(keySeparator);
          const text = arrString[0];
          const id = +arrString[1];

          return {
            key: value,
            text,
            value: id,
          };
        });
        setValueChainCategories(newCategories);
      }
    }
  }, [selectedValueChainPosition]);

  useEffect(() => {
    let data = [];
    if (companies.length) {
      data = companies.map((company) => {
        const filledRow = {};
        csvColumns.map((column) => {
          filledRow[column.label] = company[column.key] ?? '';
          return column;
        });

        return filledRow;
      });
    } else {
      const emptyRow = {};
      csvColumns.map((column) => {
        emptyRow[column.label] = '';
        return column;
      });
      data.push(emptyRow);
    }

    setCsvData(data);
  }, [companies]);

  useEffect(() => {
    if (!isEmpty(companiesFormatted) && !loadingBase64Logos) {
      loadNewCompanyLogos();
    }
  }, [companiesFormatted, loadingBase64Logos]);

  useEffect(() => {
    if (!isEmpty(expertList) && !loadingBase64ExpertImages) {
      loadNewExpertImages();
    }
  }, [expertList, loadingBase64ExpertImages]);

  useEffect(() => {
    if (!isEmpty(preparedByLogos) && !loadingBase64PreparedLogos) {
      loadNewPreparedLogos();
    }
  }, [preparedByLogos, loadingBase64PreparedLogos]);

  return (
    <>
      <HeaderMeta />
      <div className="marketMapView" ref={printRef}>
        <div className="content-header">
          {selectedMarketMap ? selectedMarketMap.text : ''}
        </div>
        <Breadcrumbs
          items={[
            {
              setOpen: setOpenDropdownGoal,
              text: selectedGoal ? selectedGoal.text : 'Select SDG',
              open: openDropdownGoal,
              options: optionsGoal,
              selectHandler: handleOnSelectGoal,
            },
            {
              setOpen: setOpenDropdownMarketMap,
              text: selectedMarketMap
                ? selectedMarketMap.text
                : 'Select Market Map',
              open: openDropdownMarketMap,
              options: optionsMarketMap,
              selectHandler: handleOnSelectMarketMap,
            },
          ]}
        />
        <section>
          {selectedMarketMap && (
            <div className="sdg-tabbed">
              <div className="sdg-tab-list">
                <div
                  className={`sdg-tab ${tabIndex === 0 ? 'active' : ''}`}
                  onClick={() => setTabindex(0)}
                >
                  Companies
                </div>
                <div
                  className={`sdg-tab ${tabIndex === 1 ? 'active' : ''}`}
                  onClick={() => setTabindex(1)}
                >
                  Information
                </div>
              </div>
              <div className="sdg-tab-share">
                <button onClick={() => handleOpenShareExport()}>
                  <Icon
                    icon={IconSet.MarketMap.Share}
                    className="sdg-share-icon"
                  />
                  Share
                </button>
              </div>
            </div>
          )}
          {tabIndex === 0 && (
            <>
              {!loadingCompanies && isEmpty(companiesFormatted) && (
                <CardList
                  title=""
                  add
                  addText="Add Company"
                  addHandler={() => handleOnAddCompany(null, null)}
                />
              )}
              {!loadingCompanies && !isEmpty(companiesFormatted) && (
                <>
                  {/* <div className="sdg-experts-text">5 Experts</div> */}
                  <div className="sdg-value-chain-container">
                    <div className="sdg-value-chain-list">
                      {valueChainPositions.map((value) => (
                        <button
                          key={value.key}
                          className={`sdg-value-chain-button ${
                            selectedValueChainPosition &&
                            value.value === selectedValueChainPosition.value
                              ? 'active'
                              : ''
                          }`}
                          onClick={() =>
                            handleOnSelectValueChainPosition(value)
                          }
                        >
                          {value.text}
                        </button>
                      ))}
                    </div>
                    {selectedValueChainPosition && (
                      <div className="sdg-value-chain-dropdown-container sdg-value-chain">
                        <div className="sdg-dropdown-wrapper">
                          <label>Filter by Position</label>
                          <div className="sdg-dropdown-select-container">
                            <SelectedText
                              onClick={() => setOpenDropdownValueChain(true)}
                            >
                              {selectedValueChainPosition.text}
                            </SelectedText>
                            <Dropdown
                              open={openDropdownValueChain}
                              setOpen={setOpenDropdownValueChain}
                              options={[...valueChainPositions]}
                              selectHandler={handleOnSelectValueChainPosition}
                              customListStyle={customListStyle}
                              customItemStyle={customItemStyle}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="sdg-value-chain-dropdown-container sdg-value-chain-category">
                      <div className="sdg-dropdown-wrapper">
                        <label>Filter by segments</label>
                        <div className="sdg-dropdown-select-container">
                          <SelectedText
                            onClick={() =>
                              setOpenDropdownValueChainCategory(true)
                            }
                          >
                            {selectedValueChainCategory
                              ? selectedValueChainCategory.text
                              : 'All'}
                          </SelectedText>
                          <Dropdown
                            open={openDropdownValueChainCategory}
                            setOpen={setOpenDropdownValueChainCategory}
                            options={[
                              { key: 'all', text: 'All', value: 'All' },
                              ...valueChainCategories,
                            ]}
                            selectHandler={handleOnSelectValueChainCategory}
                            customListStyle={customListStyle}
                            customItemStyle={customItemStyle}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedValueChainPosition &&
                    Object.keys(
                      companiesFormatted[selectedValueChainPosition.key]
                    )
                      .filter((chainCategory) =>
                        !selectedValueChainCategory
                          ? true
                          : selectedValueChainCategory.key === chainCategory
                      )
                      .map((chainCategory, indexCat) => {
                        const chainCategoryArr =
                          chainCategory.split(keySeparator);
                        const chainCategoryText = chainCategoryArr[0];
                        const chainCategoryid = +chainCategoryArr[1];

                        const list = companiesFormatted[
                          selectedValueChainPosition.key
                        ][chainCategory].map((company) => {
                          const { id, name, logo, slug } = company;
                          // const logoObj = logo ? JSON.parse(logo) : logo;

                          return {
                            id,
                            title: name,
                            // ...(logoObj && { imageSrc: logoObj.Location }),
                            ...(logo && { imageSrc: logo }),
                            link: `/market-maps/companies/${slug}`,
                            linkText: 'View Company',
                            linkNewTab: true,
                            linkTo: 'remote',
                          };
                        });
                        return (
                          <CardList
                            key={indexCat}
                            title={chainCategoryText}
                            list={list}
                            add
                            addText="Add Company"
                            addHandler={() =>
                              handleOnAddCompany(
                                chainCategoryid,
                                selectedValueChainPosition.value
                              )
                            }
                          />
                        );
                      })}
                </>
              )}
            </>
          )}
          {tabIndex === 1 && (
            <>
              {!loadingInformation && !information && <CardList title="" />}
              {!loadingInformation && information && (
                <Information
                  information={information}
                  expertList={expertList}
                  preparedByLogos={preparedByLogos}
                />
              )}
            </>
          )}
        </section>
      </div>
      <Modal
        onClose={() => handleCloseModalCompany()}
        onOpen={() => setOpenAddCompany(true)}
        open={openAddCompany}
        size="large"
        closeOnDimmerClick={false}
        closeIcon
      >
        <Modal.Header size="medium">Add Company</Modal.Header>
        <Modal.Content scrolling>
          <CompanyForm
            formValues={initialValuesCompany}
            onSubmitHandler={handleSubmitAddCompany}
            renderRemoteSubmit={() => (
              <RemoteSubmit
                submit={isSubmitCompany}
                setSubmit={setIsSubmitCompany}
              />
            )}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => handleCloseModalCompany()}
            disabled={savingAddCompany}
          >
            Close
          </Button>
          <Button
            positive
            onClick={() => handleRemoteSubmitFormCompany()}
            disabled={savingAddCompany}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
      <ShareExportModal
        open={openShareModal}
        handleClose={handleCloseShareExport}
        hasExportJpg={true}
        hasExportPdf={true}
        hasExportCsv={true}
        csvData={csvData}
        getParentRef={getPrintRef}
      />
    </>
  );
}

export default withRouter(MarketMapCompanyListView);
