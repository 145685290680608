import React from 'react';
import { Helmet } from 'react-helmet';

function HeaderMeta({
  title = 'The SDG Co',
  description = 'The SDG Co',
  keywords = 'social',
  url = 'https://thesdg.co',
  site_name = 'The SDG Co',
  type = 'website',
  image = 'https://thesdg.co/thesdg.png',
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={site_name} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta name="image" content={image} />
    </Helmet>
  );
}

export default HeaderMeta;
