import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import dayjs from 'dayjs';

import { sendRequest } from '../helpers/RequestDispatcher.js';

import ChatView from './ChatView.js';
import HeaderMeta from './common/HeaderMeta.js';

import '../sass/components/ChatListView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
  businessProfile: store.data.businessProfile,
  isMobile: store.setup.isMobile,
});

class ChatListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.paramId() || null,
      fullChats: [],
      chats: [],
      messagesLimit: props.businessProfile
        ? props.businessProfile.messages_limit
        : props.user.messages_limit,
    };
  }

  paramId = () => {
    return parseInt(this.props.match.params.id);
  };

  ownProfile = () => {
    const { businessProfile, user } = this.props;
    return businessProfile ? businessProfile : user;
  };

  ownProfileType = () => {
    const { businessProfile } = this.props;
    return businessProfile ? 'business_profile' : 'user';
  };

  ownProfileTypeId = () => {
    const { businessProfile } = this.props;
    return businessProfile ? 'business_profile_id' : 'user_id';
  };

  componentDidMount = () => {
    this.requestChats();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.businessProfile !== this.props.businessProfile) {
      const preparedData = this.filterSelfChats(this.state.fullChats);
      this.setState({ chats: preparedData, selected: null });
      // this.requestChats();
      // alternatively we can request chats and remove fullChats var
      // it depends on a chats update logic on the back-end
    }
  };

  requestChats = () => {
    sendRequest({
      type: 'GET',
      method: 'chats',
      success: (data) => {
        const preparedData = this.filterSelfChats(data);
        this.setState({ fullChats: data, chats: preparedData });
      },
      error: (data) => {},
    });
  };

  filterSelfChats = (chats) => {
    const ownProfileType = this.ownProfileType();
    const ownProfile = this.ownProfile();
    return chats.filter((chat) => {
      return (
        chat.chat_participants.filter((participant) => {
          return participant[ownProfileType]?.id !== ownProfile.id;
        }).length > 0
      );
    });
  };

  onMessageAdded = (message) => {
    const index = this.state.chats.findIndex(
      (chat) => chat.id === this.state.selected
    );
    this.setState((prevState) => {
      const messagesLimit =
        prevState.messagesLimit !== null ? prevState.messagesLimit - 1 : null;
      return {
        messagesLimit: messagesLimit,
        chats: update(prevState.chats, {
          [index]: {
            last_message: { $set: message },
          },
        }),
      };
    });
  };

  lastMessageTime = (time) => {
    if (
      time &&
      dayjs.unix(time).format('MM/DD/YYYY') === dayjs().format('MM/DD/YYYY')
    ) {
      return dayjs.unix(time).format('HH:mm');
    } else if (
      time &&
      dayjs.unix(time).format('YYYY') === dayjs().format('YYYY')
    ) {
      return dayjs.unix(time).format('MM/DD');
    } else if (time) {
      return dayjs.unix(time).format('MMMM D, YYYY');
    }
  };

  chatPartner = (chat) => {
    const ownProfileType = this.ownProfileType();
    const ownProfile = this.ownProfile();
    const chatParticipant = chat.chat_participants.find(
      (participant) => participant[ownProfileType]?.id !== ownProfile.id
    );
    if (!chatParticipant) return;
    return chatParticipant.hasOwnProperty('user')
      ? chatParticipant.user
      : chatParticipant.business_profile;
  };

  selectedProfileChats = () => {
    const chats = this.state.chats;
    const ownProfileType = this.ownProfileType();
    const ownProfile = this.ownProfile();
    const selectedChats = chats.filter((chat) => {
      const selectParticipant = (participant) => {
        return participant[ownProfileType]?.id === ownProfile.id;
      };
      return chat.chat_participants.some(selectParticipant);
    });
    return selectedChats;
  };

  renderChatItem = (chat) => {
    const ownProfileTypeId = this.ownProfileTypeId();
    const ownProfile = this.ownProfile();
    const partner = this.chatPartner(chat);
    if (!partner) return;
    const last_message = chat.last_message;
    return (
      <div
        key={chat.id}
        className={classnames({
          chatItem: true,
          selected: this.state.selected === chat.id,
        })}
        onClick={() => {
          this.setState({ selected: chat.id });
          this.props.history.replace(`/chats/${chat.id}`);
        }}
      >
        {partner.image_url ? (
          <div
            className="chatImage"
            style={{ backgroundImage: `url(${partner.image_url})` }}
          >
            {chat.unread_count > 0 ? (
              <div className="unreadCount">{chat.unread_count}</div>
            ) : null}
          </div>
        ) : (
          <div className="chatImage">
            <AiOutlineUser size="60" />
          </div>
        )}
        <div className="chatInfo">
          <div className="messageContainer">
            <div className="chatInfoPartner">
              <div className="chatTitle">{partner.name}</div>
              {/* {partner.subscribed ? <div className="subscribed" /> : null} */}
            </div>
            <div className="lastMessageTime">
              {this.lastMessageTime(chat?.last_message_at)}
            </div>
          </div>
          {last_message && last_message[ownProfileTypeId] !== ownProfile.id ? (
            <div className="chatMessage">
              {last_message.file
                ? last_message.file.name
                : last_message.content}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  renderMessageLimit = () => {
    const messagesLimit = this.state.messagesLimit;
    if (messagesLimit == null) return;

    return (
      <div className="messageLimit">
        <div>{`You can only send ${messagesLimit} / 10 free messages left.`}</div>
        <div
          className="upgradePlan"
          onClick={() => this.props.history.push('/subscribe')}
        >
          Upgrade to get unlimited chat messages
        </div>
      </div>
    );
  };

  render = () => {
    const { chats } = this.state;
    const selectedChats = this.selectedProfileChats();
    const profile = this.ownProfile();
    return (
      <div
        className={classnames({
          chatListView: true,
          chatSelected: !!this.state.selected,
          compact: this.props.isMobile,
        })}
      >
        <HeaderMeta />
        <div className="chatListContinaer">
          <div className="chatListSection">
            <div className="searchBox">
              <span>Inbox</span>
            </div>
            {!profile.subscribed ? this.renderMessageLimit() : null}
            <div
              className={classnames({
                chatList: true,
                empty: chats.length < 1,
              })}
            >
              {selectedChats.length < 1 ? (
                <div className="emptyMessage">
                  You don’t have any contacts yet.
                </div>
              ) : (
                selectedChats.map(this.renderChatItem)
              )}
            </div>
          </div>
          <div className="chatContentSection">
            {this.state.selected ? (
              <ChatView
                embedded
                id={this.state.selected}
                onMessageAdded={this.onMessageAdded}
                messagesLimit={this.state.messagesLimit}
                onClose={() => {
                  this.setState({ selected: null });
                  this.props.history.replace('/chats');
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };
}

export default connect(mapStoreToProps)(withRouter(ChatListView));
