import React from 'react';
import classnames from 'classnames';

import '../../sass/components/common/ProfileCard.scss';

class ProfileCard extends React.Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  handleClick = () => {
    if (this.props.onClick) {
      const type = this.props.type === 'organizations' ? 'business' : 'user'
      this.props.onClick(type, this.props.slug ? this.props.slug : this.props.id);
    }
  }

  handleRemove = (e) => {
    e.stopPropagation();
    if (this.props.onRemove) {
      this.props.onRemove();
    }
  }

  renderType = (value) => {
    value = value == "organizations" ? "organisations" : value
    return value.slice(0, -1);
  }

  render = () => {
    const isOrganization = this.props.type === 'organizations';
    return (
      <div
        className='profileCard'
        onClick={() => this.handleClick()}
      >
        {this.props.removable
          ? <div className='remove' onClick={this.handleRemove} />
          : null}
        <div
          className={classnames({
            'profileType 1': true,
            'organisation': isOrganization
          })}
        >{this.renderType(this.props.type)}</div>
        <div
          className={classnames({
            'profileImage': true,
            'noAvatar': !this.props.image
          })}
        >
          {this.props.image &&
          <picture>
            <img src={this.props.image} alt={`${this.props.name}`} />
          </picture>
          }
        </div>
        <div className='profileName'>
          {this.props.name.length > 37 ? this.props.name.substring(0, 34) + '...' : this.props.name }
          {this.props.is_expert ? <span className='subscribed' /> : null}
        </div>
        {this.props.description
          ? <div className='profileHeadline'>
              { this.props.description.length > 83 ? `${this.props.description.substring(0, 83)}...` : this.props.description }
            </div>
          : null
        }
        {this.props.country_name
          ? <div className='profileLocation'>
              {this.props.country_name}
            </div>
          : null}
      </div>
    )
  }

}

export default ProfileCard;
