import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from 'yup';

import { triggerEvent } from '../../../helpers/global';
import { loadOptions } from '../../../helpers/market_map';

function MarketMapForm({
  formValues = null,
  onSubmitHandler,
  renderRemoteSubmit = null,
}) {
  const [optionsGoal, setOptionsGoal] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: '',
    goal_id: null,
    goal_name: null,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('Name is required'),
      goal_id: Yup.number()
        .required('SDG is required')
        .typeError('SDG is required'),
    }),
    onSubmit: (values) => onSubmitHandler(values),
  });

  useEffect(() => {
    loadOptions({
      route: 'goals',
      setter: setOptionsGoal,
    });
  }, []);

  useEffect(() => {
    if (formValues) {
      setInitialValues(formValues);
    }
  }, [formValues]);

  useEffect(() => {
    const { isValid, isSubmitting } = formik;
    if (!isValid && isSubmitting) {
      triggerEvent('showSnackbar', [
        { text: 'Please enter required fields', type: 'error' },
      ]);
    }
  }, [formik.isValid, formik.isSubmitting]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            name="name"
            label="Market Map Name"
            placeholder="Enter Market Map"
            value={formik.values.name}
            onChange={formik.handleChange}
            autoComplete="off"
            {...(formik.touched.name &&
              formik.errors.name && { error: formik.errors.name })}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Field
            component={Form.Select}
            name="goal_id"
            label="SDG"
            placeholder="Select SDG"
            options={optionsGoal}
            clearable
            search
            value={formik.values.goal_id}
            onChange={(e, { value, options }) => {
              const goal_id = !value ? null : +value;
              formik.setFieldValue('goal_id', goal_id);
              formik.setFieldValue(
                'goal_name',
                goal_id
                  ? options.find((option) => +option.value === goal_id).text
                  : null
              );
            }}
            selectOnBlur={false}
            {...(formik.touched.goal_id &&
              formik.errors.goal_id && { error: formik.errors.goal_id })}
          />
        </Form.Group>
        {!renderRemoteSubmit && (
          <Form.Button positive type="submit">
            Save
          </Form.Button>
        )}
        {renderRemoteSubmit && renderRemoteSubmit()}
      </Form>
    </FormikProvider>
  );
}

export default MarketMapForm;
