import React from 'react';
import classnames from 'classnames';

import { httpToUrl } from '../../helpers/global';

import '../../sass/components/common/PostPreview.scss';

class PostPreview extends React.PureComponent {
  get hostname() {
    const { url } = this.props;

    let hostname;

    if (url && url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    } else if (url) {
      hostname = url.split('/')[0];
    } else {
      return null;
    }

    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];

    return hostname;
  }

  render = () => {
    const { cover, title, description, url, className } = this.props;

    return (
      <a
        className={classnames('postPreview', className, {
          hidden: !title && !description && !this.hostname && !cover
        })}
        href={httpToUrl(url)}
        target='_blank'
      >
        {cover ?
        <img
          className='postPreviewCover'
          src={cover}
          alt={title}
        />
        : null}
        {title || description || this.hostname
          ? <div className='postPreviewContent'>
              <div className='postPreviewTitle'>{title}</div>
              <div className='postPreviewDescription'>
                {description && (description.slice(0, 300) + (description.length > 300 ? '...' : ''))}
              </div>
              <div className='postPreviewUrl'>{this.hostname}</div>
            </div>
          : null
        }
      </a>
    );
  }
}

export default PostPreview;
