import React from 'react';

import HeaderMeta from './common/HeaderMeta';

import '../sass/components/StaticView.scss';

class TermsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div className="staticView">
        <HeaderMeta />
        <h3>The SDG Co Terms of Use</h3>
        <ol type="1">
          <li>
            <span>APPLICATION OF TERMS</span>
            <ol className="subList">
              <li>
                These Terms apply to your use of the Website. By accessing and
                using the Website:
                <ol className="threeLevelList">
                  <li>you agree to these Terms; and</li>
                  <li>
                    where your access and use is on behalf of another person
                    (e.g. a company), you confirm that you are authorised to,
                    and do in fact, agree to these Terms on that person’s behalf
                    and that, by agreeing to these Terms on that person’s
                    behalf, that person is bound by these Terms.
                  </li>
                </ol>
              </li>
              <li>
                If you do not agree to these Terms, you are not authorised to
                access and use the Website, and you must immediately stop doing
                so.
              </li>
            </ol>
          </li>
          <li>
            <span>CHANGES</span>
            <ol className="subList">
              <li>
                We may change these Terms at any time by updating them on the
                Website.  Unless stated otherwise, any change takes effect
                immediately.  You are responsible for ensuring you are familiar
                with the latest Terms.  By continuing to access and use the
                Website, you agree to be bound by the changed Terms.
              </li>
              <li>
                We may change, suspend, discontinue, or restrict access to, the
                Website without notice or liability.
              </li>
              <li>
                These Terms were last updated on{' '}
                <strong className="noted">6 September 2021</strong>
                <strong>
                  [note: date to be added/change as and when we amend the Terms
                  of Use].
                </strong>
              </li>
            </ol>
          </li>
          <li>
            <span>DEFINITIONS</span>
            <p>In these Terms:</p>
            <ol className="lettersList">
              <li>
                <i>including</i> and similar words do not imply any limit;
              </li>
              <li>
                <i>Loss includes</i> loss of profits, savings, revenue or data,
                and any other claim, damage, loss, liability and cost, including
                legal costs on a solicitor and own client basis;
              </li>
              <li>
                <i>Terms</i> means these terms and conditions titled{' '}
                <i>The SDG Co Terms of Use;</i>
              </li>
              <li>
                <i>Underlying System</i> means any network, system, software,
                data, files, images or material that underlies or is connected
                to the Website;
              </li>
              <li>
                <i>User ID</i> means a unique name and/or password allocated to
                you to allow you to access certain parts of the Website;
              </li>
              <li>
                <i>We, us or our</i> means The SDG Co;
              </li>
              <li>
                <i>Website</i> means{' '}
                <a href="https://www.thesdg.co/">https://www.thesdg.co/</a>; and
              </li>
              <li>
                <i>You</i> means you or, if clause 1.1b applies, both you and
                the other person on whose behalf you are acting.
              </li>
            </ol>
          </li>
          <li>
            <span>YOUR OBLIGATIONS</span>
            <ol className="subList">
              <li>
                You must provide true, current and complete information in your
                dealings with us (including when setting up an account), and
                must promptly update that information as required so that the
                information remains true, current and complete.
              </li>
              <li>
                If you are given a User ID, you must keep your User ID secure
                and:
                <ol className="threeLevelList">
                  <li>
                    not permit any other person to use your User ID, including
                    not disclosing or providing it to any other person; and
                  </li>
                  <li>
                    immediately notify us if you become aware of any disclosure
                    or unauthorised use of your User ID, by sending an email to{' '}
                    <strong className="noted">hello@thesdg.co.</strong>
                  </li>
                </ol>
              </li>
              <li>
                You must:
                <ol className="threeLevelList">
                  <li>
                    not submit any information and/or other materials which are
                    or may be offensive, illegal or which may not be lawfully
                    disseminated under the laws of Singapore or any other
                    relevant country;
                  </li>
                  <li>
                    not act in a way, or use or introduce anything (including
                    any virus, worm, Trojan horse, timebomb, keystroke logger,
                    spyware or other similar feature) that in any way
                    compromises, or may compromise, the Website or any
                    Underlying System, or otherwise attempt to damage or
                    interfere with the Website or any Underlying System; and
                  </li>
                  <li>
                    unless with our agreement, access the Website via standard
                    web browsers only and not by any other method. Other methods
                    include scraping, deep-linking, harvesting, data mining, use
                    of a robot or spider, automation, or any similar data
                    gathering, extraction or monitoring method.
                  </li>
                </ol>
              </li>
              <li>
                You must obtain our written permission to establish a link to
                our Website. If you wish to do so, email your request to{' '}
                <strong className="noted">hello@thesdg.co.</strong>
              </li>
              <li>
                You indemnify us against all Loss we suffer or incur as a direct
                or indirect result of your failure to comply with these Terms,
                including any failure of a person who accesses and uses our
                Website by using your User ID.
              </li>
            </ol>
          </li>
          <li>
            <span>PAYMENT</span>
            <ol className="subList">
              <li>
                If you buy any of our paid services (“Premium Services”), you
                agree to pay us the applicable fees and taxes and to additional
                terms specific to the Premium Services. Failure to pay these
                fees will result in the termination of your Premium Services.
                Also, you agree that:
                <ol className="threeLevelList">
                  <li>
                    your purchase may be subject to foreign exchange fees or
                    differences in prices based on location (e.g. exchange
                    rates);
                  </li>
                  <li>
                    we may store and continue billing your payment method (e.g.
                    credit card) even after it has expired, to avoid
                    interruptions in your Premium Services and to use to pay
                    other Premium Services you may buy;
                  </li>
                  <li>
                    If you purchase a subscription, your payment method
                    automatically will be charged at the start of each
                    subscription period for the fees and taxes applicable to
                    that period. To avoid future charges, cancel before the
                    renewal date;
                  </li>
                  <li>
                    except as required by law, payments are non-refundable,
                    including fees and other charges; and
                  </li>
                  <li>
                    we may calculate taxes payable by you based on the billing
                    information that you provide us at the time of purchase.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <span>SHARING</span>
            <ol className="subList">
              <li>
                Our Website allows messaging and sharing of information in many
                ways, such as your profile, articles, group posts, links to news
                articles, job postings and messages. Information and content
                that you share or post may be seen by other members of the
                Website or others (including off the Website).
              </li>
              <li>
                Where we have made settings available, we will honor the choices
                you make about who can see content or information (e.g., message
                content to your addressees or restricting your profile
                visibility from search engines).
              </li>
              <li>
                We are not obligated to publish any information or content on
                our Website and can remove it with or without notice.
              </li>
            </ol>
          </li>
          <li>
            <span>INTELLECTUAL PROPERTY</span>
            <ol className="subList">
              <li>
                We (and our licensors) own all proprietary and intellectual
                property rights in the Website (including all information, data,
                text, graphics, artwork, photographs, logos, icons, sound
                recordings, videos and look and feel), and the Underlying
                Systems.
              </li>
              <li>
                No material from this Website shall be reproduced, republished,
                uploaded, posted, transmitted or otherwise distributed in any
                way without our prior written consent. Graphics and images on
                this web site, including The SDG Co logo and related images, are
                protected by copyright and may not be reproduced or appropriated
                in any manner without our prior written consent.
              </li>
              <li>
                Modification of any of the materials or use of any of the
                materials for any other purpose will be a violation of our
                copyright and other intellectual property rights.
              </li>
              <li>
                The downloading of any Underlying System from the Website by you
                does not in any way transfer title of the Underlying System to
                you. You may not redistribute, sell, de-compile,
                reverse-engineer or disassemble or otherwise deal with the
                Underlying System nor create derivative works from this Website
                or the materials thereon. Any unauthorized use of the Website or
                the materials thereon is strictly prohibited.
              </li>
            </ol>
          </li>
          <li>
            <span>THIRD PARTY CONTENT</span>
            <p>
              Third party content may appear on the web site or may be
              accessible via links from the Website. We shall not be responsible
              and assumes no liability for any infringement, mistakes,
              misstatements of law, defamation, libel, slander, omissions,
              falsehood or profanity in the statements, opinions,
              representations or any other form of content contained in any
              third party content appearing on the Website.
            </p>
          </li>
          <li>
            <span>DISCLAIMERS</span>
            <ol className="subList">
              <li>
                To the extent permitted by law, we and our licensors have no
                liability or responsibility to you or any other person for any
                Loss in connection with:
                <ol>
                  <li>
                    the Website being unavailable (in whole or in part) or
                    performing slowly;
                  </li>
                  <li>
                    any error in, or omission from, any information made
                    available through the Website;
                  </li>
                  <li>
                    any exposure to viruses or other forms of interference which
                    may damage your computer system or expose you to fraud when
                    you access or use the Website. To avoid doubt, you are
                    responsible for ensuring the process by which you access and
                    use the Website protects you from this; and
                  </li>
                  <li>
                    any site linked from the Website.  Any link on the Website
                    to other sites does not imply any endorsement, approval or
                    recommendation of, or responsibility for, those sites or
                    their contents, operations, products or operators.
                  </li>
                </ol>
              </li>
              <li>
                We make no representation or warranty that the Website is
                appropriate or available for use in all countries or that the
                content satisfies the laws of all countries. You are responsible
                for ensuring that your access to and use of the Website is not
                illegal or prohibited, and for your own compliance with
                applicable local laws.
              </li>
            </ol>
          </li>
          <li>
            <span>LIABILITY</span>
            <ol className="subList">
              <li>
                To the maximum extent permitted by law:
                <ol className="threeLevelList">
                  <li>you access and use the Website at your own risk; and</li>
                  <li>
                    we are not liable or responsible to you or any other person
                    for any Loss under or in connection with these Terms, the
                    Website, or your access and use of (or inability to access
                    or use) the Website. This exclusion applies regardless of
                    whether our liability or responsibility arises in contract,
                    tort (including negligence), equity, breach of statutory
                    duty, or otherwise.
                  </li>
                </ol>
              </li>
              <li>
                Except to the extent permitted by law, nothing in these Terms
                has the effect of contracting out of any applicable consumer
                protection law that cannot be excluded. To the extent our
                liability cannot be excluded but can be limited, our liability
                is limited to the lower of (a) the total fees paid or payable by
                you to us for the Premium Services (if any), or (b) SGD500.
              </li>
              <li>
                To the maximum extent permitted by law and only to the extent
                clauses 10.1 and 10.2 of these Terms do not apply, our total
                liability to you under or in connection with these Terms, or in
                connection with the Website, or your access and use of (or
                inability to access or use) the Website, must not exceed SGD500.
              </li>
            </ol>
          </li>
          <li>
            <span>SUSPENSION AND TERMINATION</span>
            <ol className="subList">
              <li>
                Without prejudice to any other right or remedy available to us,
                if we consider that you have breached these Terms or we
                otherwise consider it appropriate, we may immediately, and
                without notice, suspend or terminate your access to the Website
                (or any part of it).
              </li>
              <li>
                On suspension or termination, you must immediately cease using
                the Website and must not attempt to gain further access.
              </li>
            </ol>
          </li>
          <li>
            <span>GENERAL</span>
            <ol className="subList">
              <li>
                If we need to contact you, we may do so by email or by posting a
                notice on the Website. You agree that this satisfies all legal
                requirements in relation to written communications.
              </li>
              <li>
                These Terms, and any dispute relating to these Terms or the
                Website, are governed by and must be interpreted in accordance
                with the law of Singapore. Each party submits to the
                non-exclusive jurisdiction of the Courts of Singapore in
                relation to any dispute connected with these Terms or the
                Website.
              </li>
              <li>
                For us to waive a right under these Terms, the waiver must be in
                writing.
              </li>
              <li>
                Clauses which, by their nature, are intended to survive
                termination of these Terms, including clauses 7.5, 8, 9, 10, and
                12.1, continue in force.
              </li>
              <li>
                If any part or provision of these Terms is or becomes illegal,
                unenforceable, or invalid, that part or provision is deemed to
                be modified to the extent required to remedy the illegality,
                unenforceability or invalidity. If a modification is not
                possible, the part or provision must be treated for all purposes
                as severed from these Terms. The remainder of these Terms will
                be binding on you.
              </li>
              <li>
                These Terms set out everything agreed by the parties relating to
                your use of the Website and supersede and cancel anything
                discussed, exchanged or agreed prior to you agreeing to these
                Terms. The parties have not relied on any representation,
                warranty or agreement relating to the Website that is not
                expressly set out in the Terms, and no such representation,
                warranty or agreement has any effect from the date you agreed to
                these Terms.
              </li>
            </ol>
          </li>
        </ol>
        <h3>The SDG Co “Dos and Don’ts”</h3>
        <ol className="dosAndDonts">
          <li>
            <span>Dos</span>
            <p>You agree that you will:</p>
            <ol className="subList">
              <li>
                Use the Website and its services in a professional and courteous
                manner.
              </li>
              <li>
                Use your real name, designation and organisation (where
                applicable) on your profile.
              </li>
              <li>Provide full and accurate information to us.</li>
              <li>Keep your information updated.</li>
              <li>
                Comply with all applicable regulations and laws, including
                without limitation, privacy laws and intellectual property laws.
              </li>
            </ol>
          </li>
          <li>
            <span>Don’ts</span>
            <p>You agree that you will not:</p>
            <ol className="subList">
              <li>
                Create or use a false identity, misrepresent your identity,
                create a member profile for anyone other than yourself or your
                organisation, or use or attempt to use another person’s account
                on the Website.
              </li>
              <li>
                Develop, support or use software, devices, scripts, robots or
                any other means, including but not limited to crawlers, browser
                plugins and add-ons or any other technology, to scrape the
                Website or otherwise copy profiles and other information from
                the Website.
              </li>
              <li>
                Override any security feature or circumvent any access controls
                or use limits of the Website (such as caps on messages).
              </li>
              <li>
                Copy, use, disclose, process or distribute any information
                obtained from the Website, whether directly or through third
                parties, without the written consent of The SDG Co.
              </li>
              <li>
                Disclose information that you do not have the authority or
                consent to disclose e.g. confidential information of a third
                party.
              </li>
              <li>
                Contravene the intellectual property rights of others, including
                but not limited to copyrights, patents, trademarks and trade
                secrets.
              </li>
              <li>
                Contravene the intellectual property or other proprietary rights
                of The SDG Co, including, without limitation, (i) copying or
                distributing our Website materials or (ii) copying or
                distributing our technology, unless it is released under open
                source licenses; or (iii) using the word "The SDG Co” or our
                logos in any business name, email, or URL.
              </li>
              <li>
                Post anything on any part of the Website that contains software
                viruses or any harmful code.
              </li>
              <li>
                Reverse engineer or otherwise attempt to derive the source code
                for any part of the Website or any related technology that is
                not open source.
              </li>
              <li>
                Imply or expressly state that you are affiliated with The SDG Co
                without our written consent.
              </li>
              <li>
                Use bots or other unauthorised methods to, without limitation,
                access the Website, post your information or send or redirect
                messages.
              </li>
              <li>
                Engage in the operation of simulating the appearance or function
                of the Website.
              </li>
              <li>
                Interfere with the operation of, or place an unreasonable load
                on, the Website, including without limitation, spam, denial of
                service attack, viruses or gaming algorithms.
              </li>
              <li>
                Monitor the Website’s performance or functionality for any
                competitive purpose.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    );
  };
}

export default TermsView;
