import React from 'react';
import classnames from 'classnames';

import { sendRequest } from '../../helpers/RequestDispatcher.js';

import '../../sass/components/input/TagsSelect.scss';

class TagsSelect extends React.Component {
  constructor(props) {
    super();
    this.state = {
      options: [],
    };
  }

  componentDidMount = () => {
    if (this.props.options && !this.props.request) {
      this.setState({options: this.props.options});
    }
    if (this.props.request) {
      sendRequest({
        type: 'GET',
        method: this.props.request,
        success: (data) => {
          this.setState({options: this.reorderOptions(data)});
        },
        error: (error) => {
        }
      });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.options !== this.props.options) {
      this.setState({options: this.reorderOptions(this.props.options)});
    }
    // update the order of options after change value
    if (prevProps.value !== this.props.value) {
      this.setState({options: this.reorderOptions(this.state.options)});
    }
  }

  reorderOptions = (options) => {
    const values = this.props.value;
    return options.sort((a, b) => {
      if (values.includes(a.id) && !values.includes(b.id)) {
        return -1;
      }
      if (!values.includes(a.id) && values.includes(b.id)) {
        return 1;
      }
      return 0;
    })
  }

  handleChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  switchSelected = (id) => {
    const value = this.props.value;
    if (this.props.value.includes(id)) {
      let updatedValues = value.filter(value => value !== id);
      this.handleChange(updatedValues);
    } else {
      value.push(id);
      this.handleChange(value);
    }
  }

  renderTag = (tag, index) => {
    return (
      <div
        className={classnames({
          'selected': this.props.value && this.props.value.includes(tag.id),
          'tag': true
        })}
        key={index}
        onClick={() => this.switchSelected(tag.id)}
      >
        {tag.name}
      </div>
    )
  }

  render = () => {
    return (
      <div
        className={classnames('tagsSelect', {
          'inline': this.props.inline
        })}
      >
        <div className={classnames({'inlineContainer': this.props.inline})}>
          {this.state.options ?
            this.state.options.map((tag, i) => this.renderTag(tag, i))
          : null}
        </div>
      </div>
    )
  }
}

export default TagsSelect;
