import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import Storage from '../helpers/Storage';

import '../sass/components/AboutView.scss';

class DirectoryAirtableAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webpSupported: true,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.setState({ webpSupported: Storage.getSetup('webpSupported') });
    }
  };

  render = () => {
    return (
      <div
        className={classnames('aboutView background', {
          webp: this.state.webpSupported,
        })}
      >
        <Helmet>
          <title>The SDG Co</title>
          <meta name="description" content="The SDG Co" />
          <meta name="keywords" content="social" />
          <meta property="og:url" content="https://thesdg.co" />
          <meta property="og:site_name" content="The SDG Co" />
          <meta property="og:title" content="The SDG Co" />
          <meta property="og:image" content="https://thesdg.co/logo.png" />
          <meta property="og:description" content="The SDG Co" />
          <meta property="og:type" content="website" />
          <meta name="image" content="https://thesdg.co/logo.png" />
        </Helmet>
        <div className="content">
          <div className="content-header">Add To Directory</div>
          <div className="section" style={{ width: '100%' }}>
            <iframe
              className="airtable-embed"
              src="https://airtable.com/embed/shroSmifBBF2vRM0p?backgroundColor=tealLight"
              frameBorder="0"
              onmousewheel=""
              width="100%"
              height="533"
              style={{ background: 'transparent', border: '1px solid #ccc' }}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };
}

export default DirectoryAirtableAdd;
