import React from 'react';
import classnames from 'classnames';
import ContentContainer from '../common/ContentContainer';
import Icon, { IconSet } from '../common/Icon';
import { formatWithSuffix } from '../../helpers/number';

import { sendRequest } from '../../helpers/RequestDispatcher.js';
import { triggerEvent } from '../../helpers/global.js';

import '../../sass/components/feed/FeedHeader.scss';

class FeedHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFollowing: props.isFollowing,
      count: props.count,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isFollowing !== this.props.isFollowing) {
      this.setState({ isFollowing: this.props.isFollowing });
    }
    if (prevProps.count !== this.props.count) {
      this.setState({ count: this.props.count });
    }
  };

  handleClick = () => {
    const { isFollowing } = this.state;
    const { feedKey } = this.props;
    const followersModifier = isFollowing ? -1 : 1;
    let method = isFollowing
      ? `goals/${feedKey}/unfollow`
      : `goals/${feedKey}/follow`;
    sendRequest({
      type: 'POST',
      method: method,
      success: (data) => {
        if (data) {
          this.setState((prevState) => ({
            isFollowing: !isFollowing,
            count: prevState.count + followersModifier,
          }));
        }
      },
      error: (data) => {
        if (data.errors) {
          triggerEvent('showSnackbar', [{ text: data.errors, type: 'error' }]);
        }
      },
    });
  };

  renderFollowButton = () => {
    const { isFollowing } = this.state;

    let name = 'Follow';
    let icon = null;

    if (isFollowing) {
      name = 'Following';
      icon = <Icon icon={IconSet.Check} className="feedHeaderFollows" />;
    }

    return (
      <button
        className={classnames(
          'feedHeaderFollow',
          'round',
          !isFollowing && 'outline'
        )}
        onClick={this.handleClick}
      >
        {icon}
        {name}
      </button>
    );
  };

  render = () => {
    const { icon, feed } = this.props;
    const { count } = this.state;

    return (
      <ContentContainer className="feedHeader">
        <div className="feedHeaderInfo">
          <div className="content-header">
            <Icon icon={icon} className="feedHeaderIcon" />
            {feed}
          </div>
          <div>{formatWithSuffix(count)} Followers</div>
        </div>

        {this.props.user ? this.renderFollowButton() : null}
      </ContentContainer>
    );
  };
}

export default FeedHeader;
