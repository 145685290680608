import React from 'react';
import { IconSet } from '../components/common/Icon';

export const feed = {
  id: '14343f43-cc19-44d5-9373-75687fb752fd',
  icon: IconSet.Categories.EnergyDark,
  name: 'Affordable & Clean Energy',
  followCount: 2200,

  posts: [
    {
      id: 'f200ecf2-caa7-43ce-8902-50f9a0354abd',
      publishDateTime: 1621785099087,
      likeCount: 19,
      authorId: 'e326e1f3-cc68-40b1-8e97-6b68b86ed1bb',

      article: <p>Wrote a piece on how we can get to zero deforestation in the Amazon by 2030. Would love to hear what you think.</p>,
      articleId: '1c8c8863-3e1a-4729-805d-d53b36e351ac',

      comments: [
        {
          id: '77e257c2-8439-47d2-9b1d-152b52711c30',
          userId: '902d742c-cd13-4bd5-b798-0ab27bfcb7f0',
          dateTime: 1621785099187,
          comment: 'This is awesome. Love this piece. Great job!',
        },
        {
          id: '1aae1c11-1c64-4f90-a1d6-244c030f26a3',
          userId: 'e326e1f3-cc68-40b1-8e97-6b68b86ed1bb',
          dateTime: 1621785099287,
          comment: 'I think with a concerted effort, this is totally possible.',
        }
      ]
    },
    {
      id: '0f4ce9bb-277b-4248-b020-4d93181b0b2c',
      publishDateTime: 1621785099187,
      likeCount: 54,
      authorId: 'd4213e25-4f14-4e9f-9007-39eef30139cb',

      article: (
        <p>
          Let’s talk about the global battery shortage and how this will have a huge negative impact on how we
          can shift towards electric power. Any ideas on how to solve this? Looking for suggestions!
        </p>
      ),

      comments: [
        {
          id: 'd0434ba0-d5da-4444-8151-700f8271b54f',
          userId: '902d742c-cd13-4bd5-b798-0ab27bfcb7f0',
          dateTime: 1621785099187,
          comment: 'Lorem ipsum consectuer lorium!',
        },
        {
          id: '1d2134f0-ccf0-458f-b61b-4375a8befdb6',
          userId: 'e326e1f3-cc68-40b1-8e97-6b68b86ed1bb',
          dateTime: 1621785099287,
          comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          utlabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum`,
        },
        {
          id: 'f424d359-986b-481e-a0c4-afdaa21f6a97',
          userId: '902d742c-cd13-4bd5-b798-0ab27bfcb7f0',
          dateTime: 1621785099387,
          comment: 'Lorem ipsum consectuer lorium!',
        },
        {
          id: '37580760-70be-4472-b1ac-cc417c1473fe',
          userId: 'e326e1f3-cc68-40b1-8e97-6b68b86ed1bb',
          dateTime: 1621785099487,
          comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          utlabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum`,
        },
        {
          id: '5fadc04e-b0fb-4874-a64b-566f5eedf0a5',
          userId: '902d742c-cd13-4bd5-b798-0ab27bfcb7f0',
          dateTime: 1621785099587,
          comment: 'Lorem ipsum consectuer lorium!',
        },
        {
          id: '72117074-7f60-49ce-b775-bb60797aee2a',
          userId: 'e326e1f3-cc68-40b1-8e97-6b68b86ed1bb',
          dateTime: 1621785099687,
          comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          utlabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum`,
        }
      ]
    },
  ]
};

export const articles = [
  {
    id: '1c8c8863-3e1a-4729-805d-d53b36e351ac',
    url: 'https://eco-business.com/browse/feed/3tase123asd13rff134',
    cover: '/images/tmp/post_cover.png',
    title: 'Getting to zero deforestation in the Amazon by 2030',
    description: (
      <p>
        Amazon deforestation in Brazil reached a 12-year high in 2020, and over 95 per cent of it is illegal
        lorem ipsume consectuerm forem hilarium dolorium conseture retaor.
      </p>
    )
  }
];
