import React from 'react';
import classNames from 'classnames';

import '../../../sass/components/common/Icon.scss';

class Icon extends React.PureComponent {
  state = {
    source: null,
  };

  componentDidMount = async () => {
    const { icon } = this.props;

    if (icon) {
      const source = await import(`../../../../public/images/icons/${icon}`);

      this.setState({
        source: source.default,
      });
    }
  };

  render = () => {
    const { source } = this.state;
    const { icon, className } = this.props;

    return !source ? null : (
      <img src={source} alt={icon} className={classNames('icon', className)} />
    );
  };
}

export default Icon;
