import React from 'react';
import styled from 'styled-components';

import CardList from './CardList';
import Subheader from './Subheader';

export const InformationText = styled.div`
  /* font-size: 16px;
  line-height: 20px;
  color: #666666;
  margin-top: 12px;
  white-space: pre-wrap;

  @media all and (min-width: 768px) {
    font-size: 20px;
    line-height: 25px;
  }

  @media all and (min-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  } */
  p {
    margin-bottom: 12px;
  }

  pre {
    background: #f1f1f1;
    border-radius: 3px;
    padding: 1px 10px 12px;
    white-space: pre-wrap;
  }
`;

const PreparedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
`;

const PreparedImage = styled.img`
  max-height: 60px;
  margin-bottom: 10px;

  @media all and (min-width: 768px) {
    max-height: 100px;
    margin-bottom: 20px;
  }

  &:not(:last-child) {
    margin-right: 15px;

    @media all and (min-width: 768px) {
      margin-right: 30px;
    }
  }
`;

function Information({ information, expertList, preparedByLogos }) {
  return (
    <>
      {!!preparedByLogos.length && (
        <>
          <Subheader title="Curated By:" />
          <PreparedContainer>
            {preparedByLogos.map((image) => (
              <PreparedImage key={image.id} src={image.logo} />
            ))}
          </PreparedContainer>
        </>
      )}
      {!!expertList.length && (
        <>
          <Subheader title="As Consulted With" />
          <CardList
            title={`${expertList.length} Expert${
              expertList.length > 1 ? 's' : ''
            }`}
            list={expertList}
          />
        </>
      )}
      <Subheader title="Summary" />
      <InformationText
        dangerouslySetInnerHTML={{ __html: information.summary }}
      />
      <Subheader title="Glossary" />
      <InformationText
        dangerouslySetInnerHTML={{ __html: information.glossary }}
      />
    </>
  );
}

export default Information;
