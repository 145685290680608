import React from 'react';
import classnames from 'classnames';
import Icon, { IconSet } from '../common/Icon';

import '../../sass/components/feed/SidebarSection.scss';

const SidebarSection = ({
  withEcoBusiness = false,
  title = null,
  className,
  children
}) => (
  <section className={classnames('sidebarSection', className)}>
    {withEcoBusiness && (
      <Icon
        icon={IconSet.EcoBusinessDark}
        className='sidebarEcoBusiness'
      />
    )}

    {title && <h2 className='sidebarSectionTitle'>{title}</h2>}
    {children}
  </section>
);

export default SidebarSection;
