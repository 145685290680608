import React from 'react';
import { withRouter } from 'react-router-dom';

import { triggerEvent } from '../helpers/global.js';
import { sendRequest } from '../helpers/RequestDispatcher.js';

import TextInput from './input/TextInput.js';
import HeaderMeta from './common/HeaderMeta.js';

import '../sass/components/RestorePasswordView.scss';

class RestorePasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      repeat_password: '',
      errors: {},
    };
  }

  paramToken = () => {
    return this.props.match.params.token;
  };

  submit = () => {
    let errors = {};
    const { password, repeat_password } = this.state;
    if (!password) {
      errors['password'] = 'Password cannot be blank';
    } else if (password !== repeat_password) {
      errors['repeat_password'] = 'Passwords do not match';
    }
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return;
    }
    sendRequest({
      method: 'auth/reset_password',
      type: 'POST',
      data: {
        reset_password_token: this.paramToken(),
        password,
      },
      success: (data) => {
        triggerEvent('showSnackbar', [
          { text: 'Password updated', type: 'success' },
        ]);
        this.props.history.push('/');
      },
      error: (data) => {
        if (data.errors) {
          this.setState({ errors: data.errors });
        }
      },
    });
  };

  render = () => {
    return (
      <div className="restorePasswordView">
        <HeaderMeta />
        <div className="row">
          <div className="col-50">
            <div>
              <span className="inputLabel">New Password</span>
              <TextInput
                type={'password'}
                value={this.state.password}
                onChange={(val) => this.setState({ password: val })}
                error={this.state.errors['password']}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-50">
            <div>
              <span className="inputLabel">Confirm New Password</span>
              <TextInput
                type={'password'}
                value={this.state.repeat_password}
                onChange={(val) => this.setState({ repeat_password: val })}
                error={this.state.errors['repeat_password']}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-50">
            <div className="separationLine" />
          </div>
        </div>
        <div className="row">
          <div className="col-50">
            <button className="actionBtn" onClick={this.submit}>
              Save Changes
            </button>
          </div>
        </div>
      </div>
    );
  };
}

export default withRouter(RestorePasswordView);
