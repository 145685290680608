import { sendRequest } from './RequestDispatcher';
import { triggerEvent } from './global';
import { capitalizeWords } from './string';
import { arrayItemMoveFromTo } from './array';

export const optionsStorageKeyPrefix = 'thesdg-client:market-maps:options:';

export const csvColumns = [
  { key: 'name', label: 'Company Name' },
  { key: 'url', label: 'Company URL' },
  { key: 'location', label: 'HQ Location' },
  { key: 'company_type_name', label: 'Company Type' },
  { key: 'goal_name', label: 'SDG' },
  { key: 'market_map_name', label: 'Market Map Name' },
  { key: 'value_chain_name', label: 'Chain Position' },
  { key: 'value_chain_type_name', label: 'Value Chain Category' },
  { key: 'company_type_name', label: 'Company Type' },
  { key: 'description', label: 'Detailed Description' },
  { key: 'funding_round', label: 'Funding Round' },
  { key: 'funding_amount', label: 'Funding Amount' },
  { key: 'market_models', label: 'Go to Market Model' },
  { key: 'business_models', label: 'Business Model' },
  { key: 'logo', label: 'Upload Logo' },
];

export const loadOptions = ({
  route,
  setter,
  additionalParams = {},
  isLocalStorage = false,
}) => {
  // Check if there's already data in cache
  if (isLocalStorage) {
    const options = localStorage.getItem(`${optionsStorageKeyPrefix}${route}`);
    if (options) {
      setter(JSON.parse(options));
      return;
    }
  }

  let params = '';
  let textField = '';
  let key = 'id';
  switch (route) {
    case 'company-types':
      params = {
        order: 'id',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      break;
    case 'goals':
      params = {
        order: 'id',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      key = 'key';
      break;
    case 'market-maps':
      params = {
        order: 'name',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      key = 'slug';
      break;
    case 'value-chains':
      params = {
        order: 'id',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      break;
    case 'value-chain-types':
      params = {
        order: 'id',
        direction: 'asc',
        ...additionalParams,
      };
      textField = 'name';
      break;
    default:
      break;
  }
  sendRequest({
    node: true,
    noLoad: true,
    method: route,
    type: 'GET',
    data: params,
    success: (data) => {
      const options = data.objects.map((obj) => ({
        key: obj[key],
        text: obj[textField],
        value: +obj.id,
        dat: obj,
      }));

      if (route === 'goals') {
        // Swap position of All SDG with Other Sustainability
        const formattedGoalOptions = arrayItemMoveFromTo(
          options,
          options.findIndex((option) =>
            option.text.toLowerCase().includes('all sdg')
          ),
          options.findIndex((option) =>
            option.text.toLowerCase().includes('other sustainability')
          )
        ).map((option) => ({
          ...option,
          text: capitalizeWords(option.text),
        }));

        if (isLocalStorage) {
          localStorage.setItem(
            `${optionsStorageKeyPrefix}${route}`,
            JSON.stringify(formattedGoalOptions)
          );
        }
        setter(formattedGoalOptions);
      } else if (route === 'market-maps') {
        const formattedMarketMapOptions = options.map((option) => ({
          ...option,
          text: capitalizeWords(option.text),
        }));

        if (isLocalStorage) {
          localStorage.setItem(
            `${optionsStorageKeyPrefix}${route}`,
            JSON.stringify(formattedMarketMapOptions)
          );
        }
        setter(formattedMarketMapOptions);
      } else {
        if (isLocalStorage) {
          localStorage.setItem(
            `${optionsStorageKeyPrefix}${route}`,
            JSON.stringify(options)
          );
        }
        setter(options);
      }
    },
    error: (err) => {
      triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
    },
  });
};
