export const marketModelsConstant = {
  b2b: 'B2B',
  b2b2c: 'B2B2C',
  b2c: 'B2C',
  b2g: 'B2G',
  others: 'Others',
};

export const businessModelsConstant = {
  services: 'Services',
  hardware: 'Hardware',
  software: 'Software',
  business_financial: 'Business model or financial innovation',
  others: 'Others',
};
