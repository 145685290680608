import React, { useEffect, useState, useRef } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import styled from 'styled-components';

import HeaderMeta from '../common/HeaderMeta';
import Icon, { IconSet } from '../common/Icon';
import { InformationText } from './shared/Information';
import ShareExportModal from './shared/ShareExportModal';
import { sendRequest } from '../../helpers/RequestDispatcher';
import { triggerEvent } from '../../helpers/global';
import { remoteFileToDataUrl } from '../../helpers/file_helper';
import {
  marketModelsConstant,
  businessModelsConstant,
} from '../../constants/companyModels';
import './../../sass/components/market_map/MarketMapCompanyView.scss';

const modelSeparator = '|';
const othersModelSeparator = '::';

const CompanyNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media all and (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;

const CompanyImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const CompanyName = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-transform: uppercase;
  color: #333333;

  @media all and (min-width: 768px) {
    font-size: 30px;
    line-height: 45px;
  }

  @media all and (min-width: 1024px) {
    font-size: 42px;
    line-height: 64px;
  }
`;

const ShareInTab = styled.div`
  display: flex;

  @media all and (min-width: 768px) {
    display: none !important;
  }
`;

const ShareInTitleContainer = styled.div`
  display: none !important;
  flex-grow: 1;

  @media all and (min-width: 768px) {
    display: flex !important;
    justify-content: flex-end;
  }
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media all and (min-width: 768px) {
    flex-direction: row;
    margin-top: 40px;
  }
`;

const InformationContainer = styled.div`
  padding-right: 0;
  flex-grow: 0;
  flex-shrink: 0;

  @media all and (min-width: 768px) {
    padding-right: 20px;
    flex-basis: 60%;
  }

  @media all and (min-width: 1024px) {
    padding-right: 40px;
  }
`;

const CustomInformationText = styled.div`
  font-size: 14px;
  color: #666666;
  white-space: pre-wrap;

  @media all and (min-width: 768px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

const CompanyDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;

  @media all and (min-width: 768px) {
    border-left: 1px solid #eeeeee;
  }

  @media all and (min-width: 1024px) {
    align-items: flex-start;
  }
`;

const CompanyDetailsListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 20px;

  @media all and (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 40px;
    padding-left: 20px;
  }

  @media all and (min-width: 1024px) {
    justify-content: space-between;
    padding-left: 40px;
  }
`;

const CompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  margin-bottom: 20px;
  margin-right: 5px;

  @media all and (min-width: 450px) {
    margin-right: 20px;
  }

  @media all and (min-width: 768px) {
    margin-right: 0;
  }

  .details-title {
    font-size: 18px;
    line-height: 28px;
    color: #000000;
  }

  .details-text {
    font-size: 14px;
    line-height: 26px;
    color: #666666;
  }
`;

const CompanyDetailsLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0;

  @media all and (min-width: 768px) {
    padding-left: 20px;
  }

  @media all and (min-width: 1024px) {
    padding-left: 40px;
  }

  a.company-url {
    font-size: 14px;
    font-weight: 600;
    color: #999999;
    text-decoration: none;
    position: relative;
    margin-bottom: 20px;
    padding-left: 26px;

    &:hover {
      text-decoration: underline;
    }

    & > object {
      position: absolute;
      left: 0;
    }
  }

  a.claim {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    text-decoration: underline;
    cursor: pointer;
  }
`;

function MarketMapCompanyView() {
  const params = useParams();
  const [company, setCompany] = useState(null);
  const [loadingCompany, setLoadingCompany] = useState(true);
  const [tabIndex, setTabindex] = useState(0);
  const [openShareModal, setOpenShareModal] = useState(false);
  const printRef = useRef();
  const [loadingBase64CompanyLogo, setLoadingBase64CompanyLogo] =
    useState(false);

  const loadCompany = () => {
    setLoadingCompany(true);
    const { slug } = params;
    sendRequest({
      node: true,
      method: `/companies/${slug}`,
      type: 'GET',
      data: params,
      success: (data) => {
        const formattedCompany = { ...data };

        if (data.market_models) {
          let otherMarketModel = '';
          const marketModelsArr = data.market_models
            .split(modelSeparator)
            .filter((value) => !value.includes('others'));

          if (data.market_models.includes('others')) {
            const othersString = data.market_models
              .split(modelSeparator)
              .find((value) => value.includes('others'));
            const othersArr = othersString.split(othersModelSeparator);
            otherMarketModel = othersArr[1];
          }

          formattedCompany.market_models = [
            ...marketModelsArr
              .filter((value) => marketModelsConstant[value])
              .map((value) => marketModelsConstant[value]),
            ...(otherMarketModel ? [otherMarketModel] : []),
          ];
        }

        if (data.business_models) {
          let otherBusinessModel = '';
          const businessModelsArr = data.business_models
            .split(modelSeparator)
            .filter((value) => !value.includes('others'));
          if (data.business_models.includes('others')) {
            const othersString = data.business_models
              .split(modelSeparator)
              .find((value) => value.includes('others'));
            const othersArr = othersString.split(othersModelSeparator);
            otherBusinessModel = othersArr[1];
          }

          formattedCompany.business_models = [
            ...businessModelsArr
              .filter((value) => businessModelsConstant[value])
              .map((value) => businessModelsConstant[value] ?? value),
            ...(otherBusinessModel ? [otherBusinessModel] : []),
          ];
        }

        setCompany(formattedCompany);
        setLoadingCompany(false);
      },
      error: (err) => {
        triggerEvent('showSnackbar', [{ text: err.message, type: 'error' }]);
        setLoadingCompany(false);
      },
    });
  };

  const getPrintRef = () => printRef;

  const handleOpenShareExport = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareExport = () => {
    setOpenShareModal(false);
  };

  const loadNewCompanyLogo = () => {
    setLoadingBase64CompanyLogo(true);
    const process = async () => {
      const newCompany = { ...company };

      if (newCompany.logo) {
        const base64String = await remoteFileToDataUrl(newCompany.logo);
        if (base64String) {
          newCompany.logo = base64String;
        }
      }

      setCompany(newCompany);
    };
    process();
  };

  useEffect(() => {
    if (params.slug) {
      loadCompany();
    }
  }, []);

  useEffect(() => {
    if (company && !loadingBase64CompanyLogo) {
      loadNewCompanyLogo();
    }
  }, [company, loadingBase64CompanyLogo]);

  return (
    <>
      <HeaderMeta />
      <div className="marketMapView" ref={printRef}>
        <section>
          {!loadingCompany && company && (
            <CompanyNameContainer>
              {company.logo && (
                <CompanyImageContainer
                  style={{ backgroundImage: `url(${company.logo})` }}
                />
              )}
              <CompanyName>{company.name}</CompanyName>
              <ShareInTitleContainer>
                <div className="sdg-tab-share">
                  <button onClick={() => handleOpenShareExport()}>
                    <Icon
                      icon={IconSet.MarketMap.Share}
                      className="sdg-share-icon"
                    />
                    Share
                  </button>
                </div>
              </ShareInTitleContainer>
            </CompanyNameContainer>
          )}
          <div className="sdg-tabbed">
            <div className="sdg-tab-list">
              <div
                className={`sdg-tab ${tabIndex === 0 ? 'active' : ''}`}
                onClick={() => setTabindex(0)}
              >
                About
              </div>
              {/* <div
                className={`sdg-tab ${tabIndex === 1 ? 'active' : ''}`}
                onClick={() => setTabindex(1)}
              >
                Posts
              </div> */}
            </div>
            <ShareInTab className="sdg-tab-share">
              <button onClick={() => handleOpenShareExport()}>
                <Icon
                  icon={IconSet.MarketMap.Share}
                  className="sdg-share-icon"
                />
                Share
              </button>
            </ShareInTab>
          </div>
          {!loadingCompany && !company && (
            <InformationText>Company not found</InformationText>
          )}
          {tabIndex === 0 && (
            <>
              {!loadingCompany && company && (
                <AboutContainer>
                  <InformationContainer>
                    <CustomInformationText>
                      {company.description}
                    </CustomInformationText>
                  </InformationContainer>
                  <CompanyDetailsWrapper>
                    <CompanyDetailsListContainer>
                      <CompanyDetails>
                        <div className="details-title">HQ Location</div>
                        <div className="details-text">
                          {company.location ?? 'N/A'}
                        </div>
                      </CompanyDetails>
                      <CompanyDetails>
                        <div className="details-title">Company Type</div>
                        <div className="details-text">
                          {company.company_type_name ?? 'N/A'}
                        </div>
                      </CompanyDetails>
                      <CompanyDetails>
                        <div className="details-title">Funding Round</div>
                        <div className="details-text">
                          {company.funding_round ?? 'N/A'}
                        </div>
                      </CompanyDetails>
                      <CompanyDetails>
                        <div className="details-title">Funding Amount</div>
                        <div className="details-text">
                          {company.funding_amount ?? 'N/A'}
                        </div>
                      </CompanyDetails>
                      <CompanyDetails>
                        <div className="details-title">Market Model</div>
                        <div className="details-text">
                          {company.market_models &&
                            company.market_models.length > 0 &&
                            company.market_models.join(', ')}
                          {!company.market_models && 'N/A'}
                        </div>
                      </CompanyDetails>
                      <CompanyDetails>
                        <div className="details-title">Business Model</div>
                        <div className="details-text">
                          {company.business_models &&
                            company.business_models.length > 0 &&
                            company.business_models.join(', ')}
                          {!company.business_models && 'N/A'}
                        </div>
                      </CompanyDetails>
                    </CompanyDetailsListContainer>
                    <CompanyDetailsLink>
                      {company.url && (
                        <a
                          href={
                            company.url.includes('http')
                              ? company.url
                              : `//${company.url}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="company-url"
                        >
                          <object
                            type="image/svg+xml"
                            data="/images/market_map/link-globe.svg"
                          ></object>
                          {company.url}
                        </a>
                      )}
                      <a className="claim">
                        I'd like to claim / remove profile
                      </a>
                    </CompanyDetailsLink>
                  </CompanyDetailsWrapper>
                </AboutContainer>
              )}
            </>
          )}
          {tabIndex === 1 && <></>}
        </section>
      </div>
      <ShareExportModal
        open={openShareModal}
        handleClose={handleCloseShareExport}
        hasExportJpg={true}
        hasExportPdf={true}
        getParentRef={getPrintRef}
      />
    </>
  );
}

export default withRouter(MarketMapCompanyView);
