import '../../sass/components/common/CalendarIconButton.scss';
const CalendarIconButton = ({ onClick, disabled = false }) => {
  return (
    <button
      type="button"
      className="btn-calendar"
      onClick={onClick}
      disabled={disabled}
    >
      <svg
        width="22"
        height="24"
        viewBox="0 0 22 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 1.3623C5.5 1.17168 5.42757 0.988863 5.29864 0.854072C5.1697 0.71928 4.99484 0.643555 4.8125 0.643555C4.63016 0.643555 4.4553 0.71928 4.32636 0.854072C4.19743 0.988863 4.125 1.17168 4.125 1.3623V1.3623C4.125 1.75926 3.8032 2.08105 3.40625 2.08105H2.75C2.02065 2.08105 1.32118 2.38396 0.805456 2.92312C0.289731 3.46229 0 4.19356 0 4.95605V4.95605C0 5.74996 0.643591 6.39355 1.4375 6.39355H20.5625C21.3564 6.39355 22 5.74996 22 4.95605V4.95605C22 4.19356 21.7103 3.46229 21.1945 2.92312C20.6788 2.38396 19.9793 2.08105 19.25 2.08105H18.5938C18.1968 2.08105 17.875 1.75926 17.875 1.3623V1.3623C17.875 1.17168 17.8026 0.988863 17.6736 0.854072C17.5447 0.71928 17.3698 0.643555 17.1875 0.643555C17.0052 0.643555 16.8303 0.71928 16.7014 0.854072C16.5724 0.988863 16.5 1.17168 16.5 1.3623V1.3623C16.5 1.75926 16.1782 2.08105 15.7813 2.08105H6.21875C5.8218 2.08105 5.5 1.75926 5.5 1.3623V1.3623ZM22 20.7686V10.8311C22 9.1742 20.6569 7.83105 19 7.83105H3C1.34315 7.83105 0 9.1742 0 10.8311V20.7686C0 21.5311 0.289731 22.2623 0.805456 22.8015C1.32118 23.3407 2.02065 23.6436 2.75 23.6436H19.25C19.9793 23.6436 20.6788 23.3407 21.1945 22.8015C21.7103 22.2623 22 21.5311 22 20.7686ZM17.1875 10.7061H18.5625C18.7448 10.7061 18.9197 10.7818 19.0486 10.9166C19.1776 11.0514 19.25 11.2342 19.25 11.4248V12.8623C19.25 13.0529 19.1776 13.2357 19.0486 13.3705C18.9197 13.5053 18.7448 13.5811 18.5625 13.5811H17.1875C17.0052 13.5811 16.8303 13.5053 16.7014 13.3705C16.5724 13.2357 16.5 13.0529 16.5 12.8623V11.4248C16.5 11.2342 16.5724 11.0514 16.7014 10.9166C16.8303 10.7818 17.0052 10.7061 17.1875 10.7061Z"
          fill="#7E7E7E"
        />
      </svg>
    </button>
  );
};

export default CalendarIconButton;
